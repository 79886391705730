import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { debounce, get } from "lodash";
import TransactionsTable from "./TransactionsTable";
import WalletTransactions from "../Account/WalletTransactions";
import { CalenderIconDark, DownloadIcon, RenderSVG } from "../../assets/icons";
import {
  DEFAULT_VALUES,
  AVAILABLE_PERMISSIONS,
  DEFAULT_LIMIT,
  REGEX
} from "../../constants";
import { selectCurrentUserInfo } from "../Auth";
import { selectUserInfo } from "../Profile";
import { checkIfUserHasPermission } from "../../helper";
import Search from "../../components/atoms/Search";
import { fetchTransactionDetails } from "./index";
import { fetchWalletTransactions, selectWalletInfo } from "../Account/index";
import { useLocation } from "react-router-dom";
const { ZERO, EMPTY_ARRAY, EMPTY_OBJECT, SINGLE_SPACE_STRING, EMPTY_STRING } = DEFAULT_VALUES;
const { CAN_VIEW_WALLET } = AVAILABLE_PERMISSIONS;
const { CONSECUTIVE_WHITESPACE_REGEX } = REGEX;

const Transactions = () => {
  const location = useLocation()
  const currentUserInfo = useSelector(selectCurrentUserInfo);
  const selectedCurrentUserInfo = useSelector(selectUserInfo);
  const isWalletTransactionVisible = !!location?.state?.shouldShowWalletTransactions
  const [isTableVisible, setIsTableVisible] = useState(isWalletTransactionVisible);
  const [searchValue, setSearchValue] = useState("");
  const dispatch = useDispatch();
  const walletInfo = useSelector(selectWalletInfo);
  const { id } = walletInfo;
  const { permissions = EMPTY_ARRAY } = currentUserInfo || EMPTY_OBJECT;
  const { canDownloadTransactions, canFilterTransactions } = permissions;
  const [lastKeySearch, setLastKeySearch] = useState(EMPTY_STRING);
  const {isWalletEnabled ,tenant: { walletId }  } = selectedCurrentUserInfo  

  useEffect(() => window.scrollTo(ZERO, ZERO), EMPTY_ARRAY);

  const handleToggle = (e) => {
    setSearchValue(EMPTY_STRING);
    setLastKeySearch(EMPTY_STRING);
    setIsTableVisible(e.target.checked);
  };
  const fetchTransactions = useCallback(
    debounce((searchKey, currentPage) => {
      const queryParams = {
        pageSize: DEFAULT_LIMIT,
        pageNumber: currentPage,
        tenantId: currentUserInfo?.tenantId,
        searchKey: searchKey,
      };
      dispatch(fetchTransactionDetails(queryParams));
    }, 300),
    [dispatch]
  );

  const debouncefetchWalletTransactions = useCallback(
    debounce((currentPage, searchKey) => {
      const formattedQueryParams = `pageSize=${DEFAULT_LIMIT}&pageNumber=${currentPage}&searchKey=${searchKey}`;

      dispatch(
        fetchWalletTransactions({
          formattedQueryParams,
          id,
        })
      );
    }, 300),
    [dispatch, id]
  );

  const handleInputChange = (value) => {
    const valueToSearch = value
      .trim()
      .replace(CONSECUTIVE_WHITESPACE_REGEX, SINGLE_SPACE_STRING);
    if (!value || (valueToSearch && lastKeySearch !== valueToSearch)) {
      setLastKeySearch(valueToSearch);
      isTableVisible
        ? debouncefetchWalletTransactions(1, valueToSearch)
        : fetchTransactions(valueToSearch, 1);
    }
  };

  const availablePermissions = get(selectedCurrentUserInfo, "permissions", []);
  const hasCanViewWalletPermission =
    selectedCurrentUserInfo &&
    checkIfUserHasPermission(CAN_VIEW_WALLET, availablePermissions);
  return (
    <div
      id='transaction-container'
      className='flex-1 flex flex-col bg-white h-full shadow-md round-b-md'
    >
      <>
        {(canDownloadTransactions || canFilterTransactions) && (
          <div className='p-4 flex gap-8 bg-white border-contrast-200 border-b justify-end'>
            {canDownloadTransactions && (
              <button className='shadow-sm hover:bg-contrast-50 active:bg-white border-contrast-300 border rounded-md py-2 px-4 text-sm text-contrast-700 font-medium flex items-center gap-2'>
                <RenderSVG Svg={DownloadIcon} alt='Download' />
                Download Statement
              </button>
            )}
            {canFilterTransactions && (
              <button className='shadow-sm hover:bg-contrast-50 active:bg-white border-contrast-300 border rounded-md py-2 px-4 text-sm text-contrast-700 font-medium flex items-center gap-2'>
                <RenderSVG
                  Svg={CalenderIconDark}
                  color='none'
                  fill='none'
                  alt='Calender'
                />
                Filter By Date Range
              </button>
            )}
          </div>
        )}
        <div className='w-full flex h-full'>
          <div className='flex flex-col flex-1 w-full h-full'>
            <div className='p-4 px-6 flex gap-8 bg-white border-contrast-200 '>
              <div id='user-container' className='flex-1 flex items-center'>
                <div className='flex-1 relative '>
                  <Search
                    placeholder={
                      isTableVisible
                        ? "User / Transaction Id / Email"
                        : "User / TavaBooking Id / Payment Provider / Email"
                    }
                    handleUpdate={(value) => handleInputChange(value)}
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                  />
                </div>
              </div>
              {hasCanViewWalletPermission && isWalletEnabled && !!walletId && (
                <div className='flex items-center gap-2 flex-none'>
                  <span className='text-sm 2xl:text-lg lg:text-md text-contrast-900 font-bold'>
                    View Wallet Transactions
                  </span>
                  <label className='relative inline-flex items-center cursor-pointer'>
                    <input
                      type='checkbox'
                      name='isTableVisible'
                      checked={isTableVisible}
                      onChange={handleToggle}
                      className='sr-only peer'
                    />
                    <div className="w-11 h-6 bg-secondary-200 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-secondary-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-secondary-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-secondary-600"></div>
                  </label>
                </div>
              )}
            </div>

            <div className='w-full justify-center items-center border-contrast-200 h-full'>
              {isTableVisible ? (
                <WalletTransactions searchValue={searchValue}/>
              ) : (
                <TransactionsTable
                  fetchTransactions={fetchTransactions}
                  searchValue={searchValue}
                />
              )}
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default Transactions;
