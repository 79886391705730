import { get, unescape } from "lodash";
import { v4 as uuid } from "uuid";
import {
  BookingImportantInfo,
  BookingLinks,
  BookingPricing,
} from "../../../components/organisms/HotelBookingInfoSections";
import { HotelConfirmationPropTypes } from "../../../prop-types";
import {
  renderStars,
  getFormattedAddress,
  getFormattedDateObject,
  getFutureFormattedDateObject,
  getLeadGuestInfo,
  getDayDifference,
  getTotalBookingAmountForTrip,
  getHotelBookingAmount,
  getDateDetails
  
} from "../../../helper";
import { DEFAULT_VALUES, BOOKING_STATUS_CODES } from "../../../constants";
import classNames from "classnames";
import {HotelPassengersInfo} from "../../../components/organisms/HotelBookingInfoSections";

const { ZERO, ONE, EMPTY_OBJECT } = DEFAULT_VALUES;
const { CONFIRMED } = BOOKING_STATUS_CODES;

const calculateAdultsAndChildren = (hotelRooms) => {
  let totalPassengers = {
    adults: 0,
    children: 0,
  };

  hotelRooms.forEach((room) => {
    const roomPassengers = room.hotelPassenger.reduce(
      (total, passenger) => {
        if (passenger?.paxType === 1) total.adults = total.adults + 1;
        else total.children = total.children + 1;
        return total;
      },
      { adults: 0, children: 0 }
    );

    totalPassengers = {
      adults: totalPassengers.adults + roomPassengers.adults,
      children: totalPassengers.children + roomPassengers.children,
    };
  });

  return totalPassengers;
};

const HotelConfirmation = ({
  hotelsInfo,
  isSpinnerActive,
  bookingId,
  hotelBookingStatus,
  hotelImages
}) => {
  const blockRoomResult = get(
    hotelsInfo,
    "0.blockRoomResJson.blockRoomResult",
    {}
  );
  const { blockRoomReqJson = EMPTY_OBJECT } = hotelsInfo[ZERO];
  const amount = getHotelBookingAmount(hotelsInfo);
  const bookingReqJson = get(hotelsInfo, "0.bookingReqJson", {});
  const hotelRoomsInfo = get(bookingReqJson, "hotelRoomsDetails", []);
  const dayRates = get(blockRoomResult, "hotelRoomsDetails.0.dayRates", []);
  const { profileDetails: leadGuest } = getLeadGuestInfo(
    get(bookingReqJson, "hotelRoomsDetails.0.hotelPassenger", [])
  );
  const { adults, children } = calculateAdultsAndChildren(
    get(bookingReqJson, "hotelRoomsDetails", [])
  );
  const hotelPolicyInfo = get(
    hotelsInfo,
    "0.blockRoomResJson.blockRoomResult.hotelPolicyDetail",
    ""
  );
  const decodedHotelInfo = unescape(hotelPolicyInfo);
  const isHotelBookingSuccessful = hotelBookingStatus === CONFIRMED;


  const checkInDateObject = dayRates?.[ZERO];
  const checkOutDateObject = dayRates?.slice(-1)[ZERO];
  let checkIn;
  let checkOut;
  let totalDays;
  if ((checkInDateObject, checkOutDateObject)) {
    checkIn = getFormattedDateObject(checkInDateObject.date);
    checkOut = getFutureFormattedDateObject(checkOutDateObject.date);
    totalDays = getDayDifference(
      checkInDateObject.Date,
      checkOutDateObject.Date
    );
  }
  if (!checkInDateObject)
     checkIn = getDateDetails(blockRoomReqJson?.checkInDate);
  if (!checkOutDateObject)
     checkOut = getDateDetails(blockRoomReqJson?.checkOutDate);


  const { title, firstName, lastName, email } = leadGuest;
  const noOfRooms = hotelRoomsInfo.length;
  const hotelBookingDuration = totalDays + ONE || ONE;
  const adultsText = `${adults} Adult${adults > 1 ? "s" : ""}`;
  const childsCountText = children > 1 ? "'s" : "";
  const childsText =
    children > 0 ? `| ${children} Child${childsCountText}` : "";
  const roomsText = `${noOfRooms} Room${noOfRooms > 1 ? "s" : ""}`;
  const hotelName = get(blockRoomResult, "hotelName", "");
  const addressLine1 = get(blockRoomResult, "addressLine1", "");
  const starRating = get(blockRoomResult, "starRating", "");
  const cancellationPolicies = get(
    blockRoomResult,
    "hotelRoomsDetails.0.cancellationPolicy",
    ""
  );

  const cancellationMsgArr = cancellationPolicies
    ?.split("#^#")[1]
    ?.replaceAll("#!#", "")
    ?.split("|")
    ?.filter((item) => item && item !== "undefined")
    ?.map((msg) => (
      <li className='list-item' key={uuid()}>
        {msg}
      </li>
    ));

  return (
    <div className='py-16 relative'>
      <div className='container px-8 mx-auto -mt-12'>
        <div className='grid grid-cols-12 gap-8 '>
          <div className="col-span-12  flex flex-col gap-4 xl:col-span-8">
            <div className='flex flex-col gap-8'>
              <div className='bg-white rounded-lg border border-dashed border-contrast-300 shadow-sm'>
                <div>
                  <div
                    className={classNames(
                      "flex gap-6 px-4 py-3 border-b border-dashed border-contrast-300 justify-between ",
                      {
                        "bg-yellow-100 border-none rounded-t-md": isSpinnerActive,
                        "bg-red-50 border-none rounded-t-md": !isHotelBookingSuccessful && !isSpinnerActive,
                      }
                    )}
                  >
                    <h4
                      className={classNames({
                        "text-contrast-900 font-bold text-sm":
                          isHotelBookingSuccessful,
                        "font-bold text-yellow-800": isSpinnerActive,
                        "font-bold text-red-700": !isHotelBookingSuccessful && !isSpinnerActive,
                      })}
                    >
                      {!isHotelBookingSuccessful
                        ? (isSpinnerActive ? "Hotel booking is under process, kindly wait" : "Hotel Booking Failed")
                        : "Hotel Booked"}
                    </h4>
                    {bookingId && (
                      <p className='text-contrast-800 font-semibold text-xs'>
                        Booking ID:{" "}
                        <span className='text-[#4338ca] bg-[#e0e7ff] px-2 py-0.5 rounded-2xl'>
                          {bookingId}
                        </span>
                      </p>
                    )}
                  </div>
                  <div
                    className={classNames(
                      "flex gap-6 px-4 py-3 border-b border-dashed border-contrast-300 justify-between",
                      {
                        "bg-red-50": !isHotelBookingSuccessful && !isSpinnerActive,
                        "bg-yellow-100 border-none rounded-t-md": isSpinnerActive,
                      }
                    )}
                  >
                    <div className='flex-1'>
                      <h4 className='text-contrast-900 font-bold text-base mb-2'>
                        {hotelName}
                      </h4>
                      <p className='text-contrast-700 text-xs mb-2'>
                        {getFormattedAddress(addressLine1)}
                      </p>
                      <div className='flex gap-2 items-center mb-2'>
                        <div className='flex items-center gap-0.5'>
                          {renderStars(starRating)}
                        </div>
                      </div>
                    </div>
                    {hotelImages &&
                      <div className='shrink-0'>
                        <img
                          src={hotelImages?.[0]}
                          className='rounded-md h-28'
                          width='180'
                          alt='Hotel Room'
                        />
                      </div>
                    }
                  </div>
                  <div className='p-4 bg-primary-50/30'>
                    <div className='grid grid-cols-12 gap-4'>
                      <div className='col-span-12 md:col-span-12 lg:col-span-6'>
                        <div className='flex gap-3 justify-between items-center'>
                          <div>
                            <span className='text-contrast-600 text-xs font-medium'>
                              Check In
                            </span>
                            <h5 className='text-xs  text-contrast-900 mb-1 font-bold'>
                              {checkIn ? (
                                <>
                                  {checkIn.day}{" "}
                                  <strong className='text-sm'>
                                    {`${checkIn.date} ${checkIn.month} `}
                                  </strong>
                                  {checkIn.year}
                                </>
                              ) : (
                                "..."
                              )}
                            </h5>
                          </div>
                          <div className='icon shrink-0'>
                            <div className='bg-contrast-200 font-medium text-contrast-800 rounded-2xl px-3 py-0.5 text-[10px]'>
                              {`${hotelBookingDuration} Night${hotelBookingDuration > 1 ? "s" : ""
                                }`}
                            </div>
                          </div>
                          <div>
                            <span className='text-contrast-600 text-xs font-medium'>
                              Check Out
                            </span>
                            <h5 className='text-xs text-contrast-900 mb-1 font-bold'>
                              {checkOut ? (
                                <>
                                  {checkOut.day}{" "}
                                  <strong className='text-sm'>
                                    {`${checkOut.date} ${checkOut.month} `}
                                  </strong>
                                  {checkOut.year}
                                </>
                              ) : (
                                "..."
                              )}
                            </h5>
                          </div>
                        </div>
                      </div>
                      <div className='col-span-12 md:col-span-12 lg:col-span-6 flex justify-between md:justify-around gap-4'>
                        <div className='text-end'>
                          <span className='text-contrast-600 text-xs font-medium'>
                            Guest & Rooms
                          </span>
                          <h5 className='text-xs text-contrast-800 font-bold mb-1'>
                            {adultsText} {childsText} | {roomsText}
                          </h5>
                        </div>
                        <div>
                          <span className='text-contrast-600 text-xs font-medium'>
                            Primary Guest
                          </span>
                          <h5 className='text-xs  text-contrast-900 mb-1 font-bold'>
                            {title} {firstName} {lastName}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  {isHotelBookingSuccessful && (
                    <div className='p-4 bg-amber-50'>
                      <div className='flex items-center gap-2 pl-2'>
                        <ul className='text-xs text-green-700 flex flex-col !list-disc'>
                          {cancellationMsgArr}
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <BookingPricing
                amount={amount}
                hotelRoomsInfo={hotelRoomsInfo}
                numberOfAdults={adults}
              />
              <HotelPassengersInfo passengersData = {hotelRoomsInfo} />
              {isHotelBookingSuccessful && !isSpinnerActive && <BookingImportantInfo decodedHotelInfo={decodedHotelInfo} />}
            </div>
          </div>
          <BookingLinks
            isSpinnerActive={isSpinnerActive}
            bookingId={bookingId}
            tenantBookingStatus={hotelBookingStatus}
            checkInDate={checkIn}
          />
        </div>
      </div>
    </div>
  );
};

HotelConfirmation.propTypes = HotelConfirmationPropTypes;

export default HotelConfirmation;
