import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useRef } from "react";
import { Route, Routes } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ROUTES, AVAILABLE_PERMISSIONS } from "../constants";
import PageNotFound from "../screens/PageNotFound";
import PrivateRoute from "./Authentication/PrivateRoute";
import FlightResults from "../screens/FlightResults";
import Profile from "../screens/Profile";
import Payment from "../screens/Booking/FlightBookings/Payment";
import HotelResults from "../screens/HotelResults";
import HotelInfo from "../screens/HotelInfo";
import HotelReview from "../screens/HotelReview";
import FooterLinkContent from "../screens/Footer/FooterLinkContent";
import FlightBooking from "../screens/Booking/FlightBookings";
import Policies from "../screens/Policies";
import { Account } from "../screens/Account";
import Dashboard from "../screens/Dashboard";
import ApprovalsDetails from "../screens/Approvals/ApprovalsDetails/ApprovalsDetails";
import Approvals from "../screens/Approvals/Approvals";
import CostCodes  from "../screens/CostCodes";
import SidePanel from "../components/organisms/SidePanel";
import {
  LogIn,
  ForgotPassword,
  SetPassword,
  AuthConfirmation,
  setIsSidePanelCollapsed,
  selectIsSidePanelCollapsed,
} from "../screens/Auth";
import NavBar from "../components/organisms/NavBar";
import classNames from "classnames";
import Permissions from "../screens/Permissions";
import SelectedTrip from "../screens/Booking/SelectedTrip/SelectedTrip";
import Users from "../screens/Users";
import Transactions from "../screens/Transactions";
import Trips from "../screens/Booking/Trips/Trips";
import WalletTransactions from "../screens/Account/WalletTransactions";
import Spinner, { SPINNER_NAMES } from "../components/organisms/Spinner";
import PermissionDenied from "../screens/PermissionDenied";
import { selectUserInfo } from "../screens/Profile";
import TripConfirmation from "../screens/Booking/TripConfirmation";
import Preview from "../screens/Booking/Preview";
import FlightCancellation from "../screens/Booking/FlightBookings/FlightCancellation";
import HotelCancellation from "../screens/HotelBooking/HotelCancellation";
import Invoice from "../screens/Booking/Preview/Invoice";

const {
  CAN_VIEW_DASHBOARD,
  CAN_VIEW_APPROVALS,
  CAN_VIEW_BOOKINGS,
  CAN_VIEW_COST_CODES,
  CAN_VIEW_POLICIES,
  CAN_VIEW_USERS,
  CAN_VIEW_SETTINGS,
  CAN_VIEW_ROLES,
  CAN_VIEW_TRANSACTIONS_DETAILS,
  CAN_BOOK,
} = AVAILABLE_PERMISSIONS;

const { FETCH_USER } = SPINNER_NAMES;

const {
  BOOKINGS,
  LOGIN,
  FLIGHTS,
  PROFILE,
  FLIGHT_PRICE,
  PAYMENT,
  PAYMENT_CONFIRM,
  HOTELS,
  HOTEL_INFO,
  HOTEL_PRICE,
  PRIVACY_POLICY,
  PAYMENT_SECURITY,
  COOKIE_POLICY,
  CANCELLATION_AND_REFUND_POLICY,
  FLIGHT_CANCELLATION,
  MY_TRIPS_FLIGHT_CANCELLATION,
  MY_TRIPS_HOTEL_CANCELLATION,
  TERMS_AND_CONDITION,
  POLICIES,
  ACCOUNT,
  DASHBOARD,
  COST_CODES,
  APPROVALS,
  APPROVALS_DETAILS,
  BOOKING_ID,
  FORGOT_PASSWORD,
  PERMISSIONS,
  USERS,
  TRANSACTIONS,
  WALLET_TRANSACTIONS,
  AUTH_CONFIRMATION,
  SET_PASSWORD,
  PREVIEW,
  INVOICE,
  HOTEL_CANCELLATION,
  CURRENT_USER_BOOKINGS,
  MY_TRIPS_BOOKING_ID,
  MY_TRIPS_FLIGHTS,
  MY_TRIPS_HOTELS,
  MY_TRIPS_HOTEL_INFO,
  MY_TRIPS_HOTEL_PRICE,
  MY_TRIPS_FLIGHT_PRICE,
  MY_TRIPS_PAYMENT,
  MY_TRIPS_PAYMENT_CONFIRM,
  MY_TRIPS_PREVIEW_INVOICE,
  MY_TRIPS_PREVIEW
} = ROUTES;

const RouteComponent = ({
  showSidePanel,
  element,
  showNavBar = true,
  permission = null,
  collapseByDefault = false,
  message = "",
}) => {
  const dispatch = useDispatch();
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
  const userInfo = useSelector(selectUserInfo);
  const { permissions = [] } = userInfo || {};
  const isCollapsed = useSelector(selectIsSidePanelCollapsed) || false
  const containerRef= useRef(null);


  useEffect(() => {
    dispatch(setIsSidePanelCollapsed(collapseByDefault));
  }, [collapseByDefault]);

  return (
    <div className='relative flex overflow-y-hidden'>
      {showSidePanel && (
        <div
        className={classNames(
          "h-screen hidden md:block print:hidden transition-all duration-300 ease-in-out",
          {
            "w-16": isCollapsed,
            "w-64": !isCollapsed,
          }
        )}
        >
          <SidePanel />
        </div>
      )}
      <div
        className={classNames(
          "flex print:block flex-col print:h-auto h-screen w-full overflow-x-hidden",
          {
            "overflow-y-auto bg-contrast-100": showSidePanel,
          }
        )}
        ref={containerRef}
      >
        {showNavBar && (
          <NavBar
            setIsSidePanelOpen={setIsSidePanelOpen}
            isSidePanelOpen={isSidePanelOpen}
          />
        )}
        {!permission || (permission && permissions.includes(permission)) ? (
          <div className='flex-grow w-full rounded-lg'>
            <element.type refContainer={containerRef} />
          </div>
        ) : (
          <Spinner name={FETCH_USER} message={message}>
            {userInfo && <PermissionDenied />}
          </Spinner>
        )}
      </div>
      {showSidePanel && (
        <div className='md:hidden'>
          {isSidePanelOpen && (
            <div
              className='fixed inset-0 bg-black opacity-50'
              onClick={() => setIsSidePanelOpen(false)}
            ></div>
          )}
          <div className='overflow-hidden'>
            <div
              className={classNames(
                "absolute w-2/3 z-[999] h-screen overflow-y-scroll inset-0 overflow-hidden transition-all duration-300 ease-in-out",
                {
                  "translate-x-0": isSidePanelOpen,
                  "-translate-x-full": !isSidePanelOpen,
                }
              )}
            >
              <SidePanel setIsSidePanelOpen={setIsSidePanelOpen} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const RoutesLayout = () => {
  const {t} = useTranslation();
  return(
  <Routes>
    <Route
      exact
      path={LOGIN}
      element={
        <PrivateRoute>
          <RouteComponent
            showSidePanel={false}
            showNavBar={false}
            element={<LogIn />}
          />
        </PrivateRoute>
      }
    />
    <Route
      exact
      path={FORGOT_PASSWORD}
      element={
        <PrivateRoute>
          <RouteComponent
            showSidePanel={false}
            showNavBar={false}
            element={<ForgotPassword />}
          />
        </PrivateRoute>
      }
    />
<Route
  exact
  path={BOOKINGS} 
  element={
    <PrivateRoute>
      <RouteComponent
        showSidePanel={true}
        element={<Trips />}
        permission={CAN_VIEW_BOOKINGS}
        message={t("userBookings.spinnerMessage")}
      />
    </PrivateRoute>
  }
/>
<Route
  exact
  path={CURRENT_USER_BOOKINGS} 
  element={
    <PrivateRoute>
      <RouteComponent
        showSidePanel={true}
        element={<Trips />}
        permission={CAN_VIEW_BOOKINGS}
        message={t("userBookings.spinnerMessage")}
      />
    </PrivateRoute>
  }
/>
    <Route
      exact
      path={BOOKING_ID}
      element={
        <PrivateRoute>
          <RouteComponent
            showSidePanel={true}
            element={<SelectedTrip />}
            permission={CAN_BOOK}
          />
        </PrivateRoute>
      }
    />
        <Route
      exact
      path={MY_TRIPS_BOOKING_ID}
      element={
        <PrivateRoute>
          <RouteComponent
            showSidePanel={true}
            element={<SelectedTrip />}
            permission={CAN_BOOK}
          />
        </PrivateRoute>
      }
    />
    <Route
      exact
      path={PREVIEW}
      element={
        <PrivateRoute>
          <RouteComponent
            showSidePanel={true}
            element={<Preview />}
            permission={CAN_BOOK}
          />
        </PrivateRoute>
      }
    />
    <Route
      exact
      path={FLIGHTS}
      element={
        <PrivateRoute>
          <RouteComponent
            showSidePanel={true}
            showNavBar={true}
            element={<FlightResults />}
            collapseByDefault={true}
            permission={CAN_BOOK}
          />
        </PrivateRoute>
      }
    />
    <Route
      exact
      path={MY_TRIPS_FLIGHTS}
      element={
        <PrivateRoute>
          <RouteComponent
            showSidePanel={true}
            showNavBar={true}
            element={<FlightResults />}
            collapseByDefault={true}
            permission={CAN_BOOK}
          />
        </PrivateRoute>
      }
    />
    <Route
      exact
      path={FLIGHT_PRICE}
      element={
        <PrivateRoute>
          <RouteComponent
            showSidePanel={true}
            collapseByDefault={true}
            element={<FlightBooking />}
            permission={CAN_BOOK}
          />
        </PrivateRoute>
      }
    />
    <Route
      exact
      path={MY_TRIPS_FLIGHT_PRICE}
      element={
        <PrivateRoute>
          <RouteComponent
            showSidePanel={true}
            collapseByDefault={true}
            element={<FlightBooking />}
            permission={CAN_BOOK}
          />
        </PrivateRoute>
      }
    />
    <Route
      exact
      path={PAYMENT}
      element={
        <PrivateRoute>
          <RouteComponent
            showSidePanel={false}
            showNavBar={false}
            element={<Payment />}
            permission={CAN_BOOK}
          />
        </PrivateRoute>
      }
    />
    <Route
      exact
      path={MY_TRIPS_PAYMENT}
      element={
        <PrivateRoute>
          <RouteComponent
            showSidePanel={false}
            showNavBar={false}
            element={<Payment />}
            permission={CAN_BOOK}
          />
        </PrivateRoute>
      }
    />
    <Route
      exact
      path={PAYMENT_CONFIRM}
      element={
        <PrivateRoute>
          <RouteComponent
            showSidePanel={true}
            element={<TripConfirmation />}
            permission={CAN_BOOK}
          />
        </PrivateRoute>
      }
    />
    <Route
      exact
      path={MY_TRIPS_PAYMENT_CONFIRM}
      element={
        <PrivateRoute>
          <RouteComponent
            showSidePanel={true}
            element={<TripConfirmation />}
            permission={CAN_BOOK}
          />
        </PrivateRoute>
      }
    />
    <Route
      exact
      path={HOTELS}
      element={
        <PrivateRoute>
          <RouteComponent
            showSidePanel={true}
            showNavBar={true}
            element={<HotelResults />}
            collapseByDefault={true}
            permission={CAN_BOOK}
          />
        </PrivateRoute>
      }
    />
    <Route
      exact
      path={MY_TRIPS_HOTELS}
      element={
        <PrivateRoute>
          <RouteComponent
            showSidePanel={true}
            showNavBar={true}
            element={<HotelResults />}
            collapseByDefault={true}
            permission={CAN_BOOK}
          />
        </PrivateRoute>
      }
    />
    <Route
      exact
      path={HOTEL_INFO}
      element={
        <PrivateRoute>
          <RouteComponent
            showSidePanel={true}
            collapseByDefault={true}
            showNavBar={true}
            element={<HotelInfo />}
            permission={CAN_BOOK}
          />
        </PrivateRoute>
      }
    />
    <Route
      exact
      path={MY_TRIPS_HOTEL_INFO}
      element={
        <PrivateRoute>
          <RouteComponent
            showSidePanel={true}
            collapseByDefault={true}
            showNavBar={true}
            element={<HotelInfo />}
            permission={CAN_BOOK}
          />
        </PrivateRoute>
      }
    />
    <Route
      exact
      path={HOTEL_PRICE}
      element={
        <PrivateRoute>
          <RouteComponent
            showSidePanel={true}
            element={<HotelReview />}
            collapseByDefault={true}
            permission={CAN_BOOK}
          />
        </PrivateRoute>
      }
    />
    <Route
      exact
      path={MY_TRIPS_HOTEL_PRICE}
      element={
        <PrivateRoute>
          <RouteComponent
            showSidePanel={true}
            element={<HotelReview />}
            collapseByDefault={true}
            permission={CAN_BOOK}
          />
        </PrivateRoute>
      }
    />
    <Route
      exact
      path={PROFILE}
      element={
        <PrivateRoute>
          <RouteComponent showSidePanel={true} element={<Profile />} />
        </PrivateRoute>
      }
    />
    <Route
      exact
      path={POLICIES}
      element={
        <PrivateRoute>
          <RouteComponent
            showSidePanel={true}
            element={<Policies />}
            permission={CAN_VIEW_POLICIES}
          />
        </PrivateRoute>
      }
    />
    <Route
      exact
      path={DASHBOARD}
      element={
        <PrivateRoute>
          <RouteComponent
            showSidePanel={true}
            element={<Dashboard />}
            permission={CAN_VIEW_DASHBOARD}
          />
        </PrivateRoute>
      }
    />
    <Route
      exact
      path={ACCOUNT}
      element={
        <PrivateRoute>
          <RouteComponent
            showSidePanel={true}
            element={<Account />}
            permission={CAN_VIEW_SETTINGS}
          />
        </PrivateRoute>
      }
    />
    <Route
      exact
      path={COST_CODES}
      element={
        <PrivateRoute>
          <RouteComponent
            showSidePanel={true}
            element={<CostCodes />}
            permission={CAN_VIEW_COST_CODES}
            message={t("costCodes.spinnerMessage")}
          />
        </PrivateRoute>
      }
    />
    <Route
      exact
      path={APPROVALS}
      element={
        <PrivateRoute>
          <RouteComponent
            element={<Approvals />}
            showSidePanel={true}
            permission={CAN_VIEW_APPROVALS}
          ></RouteComponent>
        </PrivateRoute>
      }
    />
    <Route
      exact
      path={APPROVALS_DETAILS}
      element={
        <PrivateRoute>
          <RouteComponent
            permission={CAN_VIEW_APPROVALS}
            element={<ApprovalsDetails />}
            showSidePanel={true}
          />
        </PrivateRoute>
      }
    />
    <Route
      exact
      path={USERS}
      element={
        <PrivateRoute>
          <RouteComponent
            showSidePanel={true}
            element={<Users />}
            permission={CAN_VIEW_USERS}
            message={t("users.spinnerMessage")}
          />
        </PrivateRoute>
      }
    />
    <Route
      exact
      path={PERMISSIONS}
      element={
        <PrivateRoute>
          <RouteComponent
            element={<Permissions />}
            showSidePanel={true}
            permission={CAN_VIEW_ROLES}
          />
        </PrivateRoute>
      }
    />
    <Route
      exact
      path={TRANSACTIONS}
      element={
        <PrivateRoute>
          <RouteComponent
            showSidePanel={true}
            element={<Transactions />}
            permission={CAN_VIEW_TRANSACTIONS_DETAILS}
            message={t("transactions.spinnerMessage")}
          />
        </PrivateRoute>
      }
    />

    <Route
      exact
      path={PRIVACY_POLICY}
      element={
        <PrivateRoute>
          <RouteComponent
            showSidePanel={false}
            showNavBar={false}
            element={<FooterLinkContent />}
          />
        </PrivateRoute>
      }
    />
    <Route
      exact
      path={PAYMENT_SECURITY}
      element={
        <PrivateRoute>
          <RouteComponent
            showSidePanel={false}
            showNavBar={false}
            element={<FooterLinkContent />}
          />
        </PrivateRoute>
      }
    />
    <Route
      exact
      path={COOKIE_POLICY}
      element={
        <PrivateRoute>
          <RouteComponent
            showSidePanel={false}
            showNavBar={false}
            element={<FooterLinkContent />}
          />
        </PrivateRoute>
      }
    />
    <Route
      exact
      path={CANCELLATION_AND_REFUND_POLICY}
      element={
        <PrivateRoute>
          <RouteComponent
            showSidePanel={false}
            showNavBar={false}
            element={<FooterLinkContent />}
          />
        </PrivateRoute>
      }
    />
    <Route
      exact
      path={FLIGHT_CANCELLATION}
      element={
        <PrivateRoute>
          <RouteComponent
            showSidePanel={true}
            showNavBar={true}
            element={<FlightCancellation />}
          />
        </PrivateRoute>
      }
    />
    <Route
      exact
      path={TERMS_AND_CONDITION}
      element={
        <PrivateRoute>
          <RouteComponent
            showSidePanel={false}
            showNavBar={false}
            element={<FooterLinkContent />}
          />
        </PrivateRoute>
      }
    />
    <Route
      exact
      path={AUTH_CONFIRMATION}
      element={
        <PrivateRoute>
          <RouteComponent
            showSidePanel={false}
            showNavBar={false}
            element={<AuthConfirmation />}
          />
        </PrivateRoute>
      }
    />
    <Route
      exact
      path={SET_PASSWORD}
      element={
        <PrivateRoute>
          <RouteComponent
            showSidePanel={false}
            showNavBar={false}
            element={<SetPassword />}
          />
        </PrivateRoute>
      }
    />
    <Route
      path='*'
      element={
        <PrivateRoute>
          <RouteComponent
            showSidePanel={false}
            showNavBar={false}
            element={<PageNotFound />}
          />
        </PrivateRoute>
      }
    />
    <Route
      exact
      path={WALLET_TRANSACTIONS}
      element={
        <PrivateRoute>
          <RouteComponent
            showSidePanel={true}
            element={<WalletTransactions />}
          />
        </PrivateRoute>
      }
    />
    <Route
      exact
      path={HOTEL_CANCELLATION}
      element={
        <PrivateRoute>
          <RouteComponent
            showSidePanel={true}
            element={<HotelCancellation />}
          />
        </PrivateRoute>
      }
    />
    <Route
      exact
      path={INVOICE}
      element={
        <PrivateRoute>
          <RouteComponent
            showSidePanel={true}
            element={<Invoice />}
            permission={CAN_BOOK}
          />
        </PrivateRoute>
      }
    />
    <Route
      exact
      path={MY_TRIPS_PREVIEW_INVOICE}
      element={
        <PrivateRoute>
          <RouteComponent
            showSidePanel={true}
            element={<Invoice />}
            permission={CAN_BOOK}
          />
        </PrivateRoute>
      }
    />
    <Route
      exact
      path={MY_TRIPS_PREVIEW}
      element={
        <PrivateRoute>
          <RouteComponent
            showSidePanel={true}
            element={<Preview />}
            permission={CAN_BOOK}
          />
        </PrivateRoute>
      }
    />
    <Route
      exact
      path={MY_TRIPS_FLIGHT_CANCELLATION}
      element={
        <PrivateRoute>
          <RouteComponent
            showSidePanel={true}
            showNavBar={true}
            element={<FlightCancellation />}
          />
        </PrivateRoute>
      }
    />
    <Route
      exact
      path={MY_TRIPS_HOTEL_CANCELLATION}
      element={
        <PrivateRoute>
          <RouteComponent
            showSidePanel={true}
            showNavBar={true}
            element={<HotelCancellation />}
          />
        </PrivateRoute>
      }
    />
  </Routes>
)};

export default RoutesLayout;
