import { get, isEmpty } from "lodash";
import { v4 as uuid } from "uuid";
import { mapGuestDetails } from "./mapGuestDetails";
import { getPriceWithConversion } from "./priceFormatter";
import { getFromSessionStorage } from "./index";
import {
  DEFAULT_VALUES,
  DEFAULT_CURRENCY_CODE,
  BOOKING_TYPES,
  CACHE_KEYS,
} from "../constants";

const { ZERO, ONE, EMPTY_ARRAY, EMPTY_STRING, EMPTY_OBJECT } = DEFAULT_VALUES;
const { NON_VOUCHER_BOOKING } = BOOKING_TYPES;
const { CURRENCY_INFO } = CACHE_KEYS;

export const mapPaymentReq = ({
  userCountryInfo,
  pricePolicyInfo,
  leadGuest,
}) => {
  const code = get(userCountryInfo, "currency.code", DEFAULT_CURRENCY_CODE);
  const { exchangeRate } = getFromSessionStorage(CURRENCY_INFO);

  const baseURL = window.location.href.replace(
    window.location.pathname,
    EMPTY_STRING
  );
  const roomDetails = get(
    pricePolicyInfo,
    "blockRoomResult.hotelRoomsDetails",
    EMPTY_ARRAY
  );
  const totalPublishedPrice = roomDetails.reduce(
    (total, room) => total + room.price.publishedPriceRoundedOff,
    0
  );
  const {
    email,
    phoneNumber: contactNo,
    firstName: name,
  } = get(leadGuest, "profileDetails", EMPTY_OBJECT);

  return {
    division: "HOTEL",
    successUrl: `${baseURL}/booking-confirmed`,
    cancelUrl: `${baseURL}/booking-failed`,
    lineItems: [
      {
        priceData: {
          currency: code,
          productData: {
            name,
            images: EMPTY_ARRAY,
            description: EMPTY_STRING,
            metadata: {
              id: ONE.toString(),
            },
          },
          unitAmount: (totalPublishedPrice * 100).toString(),
        },
        quantity: ONE,
      },
    ],
    selectedPaymentPortal: "RAZORPAY",
    cardInfo: {
      billerDetails: {
        billerAddress: EMPTY_STRING,
        billerCity: EMPTY_STRING,
        billerCountry: EMPTY_STRING,
        billerPin: EMPTY_STRING,
        billerState: EMPTY_STRING,
      },
      cardDetails: {
        number: EMPTY_STRING,
        name: EMPTY_STRING,
        expiryMonth: EMPTY_STRING,
        expiryYear: EMPTY_STRING,
        cvv: EMPTY_STRING,
      },
      fetchBinFailed: null,
      saveCard: null,
    },
    checkoutId: EMPTY_STRING,
    payMode: EMPTY_STRING,
    payOption: EMPTY_STRING,
    networkConsent: false,
    deviceFingerPrintID: EMPTY_STRING,
    mcid: EMPTY_STRING,
    enforceOtpOnPage: false,
    version: "1.5",
    msgId: EMPTY_STRING,
    operation: "PAYMENT_INIT",
    requestDate: new Date().toISOString().split(".")[ZERO],
    validateOnly: false,
    requestData: {
      clientId: "14000004",
      clientIdHash: EMPTY_STRING,
      transactionType: "PURCHASE",
      transactionAmount: {
        totalAmount: totalPublishedPrice,
        paymentAmount: totalPublishedPrice,
        serviceFeeAmount: ZERO,
        currency: code,
      },
      redirect: {
        returnUrl: EMPTY_STRING,
        cancelUrl: EMPTY_STRING,
        returnMethod: "GET",
      },
      clientRef: uuid(),
      comment: EMPTY_STRING,
      shouldTokenize: true,
      cssLocation1: EMPTY_STRING,
      cssLocation2: EMPTY_STRING,
      useReliability: true,
      "extraData ": "{1,2,3}",
    },
    amount: getPriceWithConversion(totalPublishedPrice, exchangeRate).toFixed(2),
    currency: code,
    email,
    contactNo,
  };
};

const mapBookingRequest = ({
  tavaBookingId,
  pricePolicyReqBody,
  leadGuest,
  otherGuests,
  isSamePanForAllAllowed,
  roomGuests,
  loggedInUserEmail,
  userCountryInfo,
  totalPublishedPrice,
  isPassportRequired,
  isPANRequired
}) => {
  const currency = get(userCountryInfo, "currency.code", DEFAULT_CURRENCY_CODE);
  const endUserIp = get(pricePolicyReqBody, "endUserIp", EMPTY_STRING);
  const traceId = get(pricePolicyReqBody, "traceId", EMPTY_STRING);
  const resultIndex = get(pricePolicyReqBody, "resultIndex", ZERO);
  const hotelCode = get(pricePolicyReqBody, "hotelCode", ZERO);
  const categoryId = get(pricePolicyReqBody, "categoryId", EMPTY_STRING);
  const hotelName = get(pricePolicyReqBody, "hotelName", ZERO);
  const guestNationality = get(pricePolicyReqBody, "guestNationality", ZERO);
  const noOfRooms = get(pricePolicyReqBody, "noOfRooms", ZERO);
  const isVoucherBooking = get(pricePolicyReqBody, "isVoucherBooking", ZERO);
  let hotelRoomsDetails = get(
    pricePolicyReqBody,
    "hotelRoomsDetails",
    EMPTY_ARRAY
  );

  let firstAdultGuestPan = EMPTY_STRING;
  const firstSelectedGuest = otherGuests.find((guest) => guest.isSelected && guest.profileDetails?.isChild === false)
  if (firstSelectedGuest) {
    firstAdultGuestPan = firstSelectedGuest?.panCardDetails.panCardNumber || EMPTY_STRING;
  }

  hotelRoomsDetails = hotelRoomsDetails.map((room, index) => ({
    ...room,
    hotelPassenger: mapGuestDetails(
      leadGuest,
      otherGuests,
      isSamePanForAllAllowed,
      roomGuests,
      index,
      firstAdultGuestPan,
      isPassportRequired,
      isPANRequired
    ),
  }));
  const accountEmail =
    loggedInUserEmail || get(leadGuest, "profileDetails.email", "");

  const country = get(leadGuest, "profileDetails.nationality.name", "");
  const countryCode = get(leadGuest, "profileDetails.nationality.isoCode", "");
  const state = get(leadGuest, "profileDetails.state", "");

  const paymentDetails = {
    totalPrice: parseFloat(totalPublishedPrice.toFixed(2)),
    country,
    countryCode,
    state,
    currency,
  };

  return {
    accountEmail,
    resultIndex,
    hotelCode,
    hotelName,
    guestNationality,
    noOfRooms,
    isVoucherBooking,
    tavaBookingId,
    categoryId,
    endUserIp,
    traceId,
    hotelRoomsDetails,
    paymentDetails,
  };
};

export const mapHotelBookingAPIReq = ({
  pricePolicyReqBody,
  tavaBookingId,
  pricePolicyInfo,
  userCountryInfo,
  voucherBookingType,
  leadGuest,
  otherGuests,
  isSamePanForAllAllowed,
  roomGuests,
  loggedInUserEmail,
  hotelImage,
  checkInDate,
  isPassportRequired,
  isPANRequired,
  hotelSearchRequest,
  timestamp,
}) => {
  pricePolicyReqBody = {
    ...pricePolicyReqBody,
    isVoucherBooking:
      voucherBookingType === NON_VOUCHER_BOOKING ? "false" : "true",
  };

  const roomDetails = get(
    pricePolicyInfo,
    "blockRoomResult.hotelRoomsDetails",
    EMPTY_ARRAY
  );
  const totalPublishedPrice = roomDetails.reduce(
    (total, room) =>
      total +
      (room.price.offeredPrice || ZERO) +
      (room.price.tavaMarkup || ZERO),
    ZERO
  );

  const blockRoomResult = pricePolicyInfo.blockRoomResult;
  const updateBlockRoomResult = { ...blockRoomResult, hotelImage };

  const rebookMetaData = {
    hotelSearchRequest : hotelSearchRequest,
    hotelTimeStamp: timestamp,
  }
  return {
    totalPrice: totalPublishedPrice.toFixed(2),
    bookingRequest: mapBookingRequest({
      tavaBookingId,
      pricePolicyReqBody,
      leadGuest,
      otherGuests,
      isSamePanForAllAllowed,
      roomGuests,
      loggedInUserEmail,
      userCountryInfo,
      totalPublishedPrice,
      isPassportRequired,
      isPANRequired
    }),
    blockRoomReq: pricePolicyReqBody,
    blockRoomRes: { blockRoomResult: updateBlockRoomResult },
    checkInDate,
    rebookMetaData: rebookMetaData
  };
};

export const mapGenericBookingRequest = ({ bookingType, ...rest }) => ({
  hotelBookingRequest: mapHotelBookingAPIReq({ ...rest }),
  bookingType,
});
