import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { selectSelectedTripId, checkFlightRebookStatus, checkHotelRebookStatus, setHotelRebookTimer, selectSelectedTrip, setSelectedTrip, initiateBookCallback, updateHotelVoucherStatus, setIsHotel, setIsFlight, selectIsFlight, selectIsHotel } from "../../../screens/Booking/Trips";
import { setFlightRebookTimestamp, setFlightRebookDetail } from "../../../screens/Booking/Trips";
import { DEFAULT_VALUES, PAYMENT_PROVIDERS, DEFAULT_CURRENCY_CODE } from "../../../constants";
import { MODALS } from "../AppModals";
import { setSelectedModal } from "../Modal";
import { setRebookResponse } from "../../../screens/Booking/Trips"; import { cloneDeep, get, isEmpty } from 'lodash'
import { FlightBlue, Hotel, RenderSVG } from '../../../assets/icons';
import { selectUserInfo } from '../../../screens/Profile';
import Spinner from '../Spinner';
import { SPINNER_NAMES } from '../../organisms/Spinner/spinner.constants'
import { getNextDate, useClickOutside } from '../../../helper';
import { selectCurrentUserInfo } from '../../../screens/Auth';
import { useNavigate } from 'react-router-dom';
import CombinedFlightAndHotelRebookModal from '../AppModals/RebookStatusModal';

const { EMPTY_OBJECT, ZERO, EMPTY_STRING } = DEFAULT_VALUES;
const { TRIP_PAYMENT_MODAL } = MODALS;
const { UPDATE_HOTEL_VOUCHERED_STATUS } = SPINNER_NAMES;
const { REACT_APP_SHOULD_ENABLE_REBOOK = 'false', REACT_APP_HOTEL_PAY_LATER = 'false', REACT_APP_TBO_SESSION_EXPIRATION_TIME = 14 } = process.env;

const isWithinSessionExpirationTime = (timeStamp) => {
  const timeStampDate = new Date(timeStamp);
  const currentTime = new Date();
  const sessionExpirationTimeInMinutes = parseInt(REACT_APP_TBO_SESSION_EXPIRATION_TIME, 10)
  const sessionExpirationTimeInMilliseconds = sessionExpirationTimeInMinutes * 60 * 1000;
  const diffInMilliseconds = currentTime - timeStampDate;
  return diffInMilliseconds <= sessionExpirationTimeInMilliseconds;
}


const PaymentButton = ({ shouldDisable }) => {
  const navigate = useNavigate();
  const clickOutsideRef = useRef(null)
  const dispatch = useDispatch();
  const tripDetails = useSelector(selectSelectedTrip);
  const selectedTrip = useSelector(selectSelectedTrip);
  
  const flightRebookTimeStamp = tripDetails?.metadata?.flightRebookData?.flightRebookTimestamp
  const rebookTimestamp = selectedTrip?.metadata?.hotelRebookData?.hotelTimeStamp;
  const [isRebookModalOpen, setIsRebookModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [continueButtonEnabled, setContinueButtonEnabled] = useState(false);
  const [data, setData] = useState({ flight: 'false', hotel: 'false' })
  useClickOutside(clickOutsideRef, setIsModalOpen);

  const nextDate = getNextDate(new Date());
  const selectedTripId = useSelector(selectSelectedTripId);
  const { id: tavaBookingId } = selectedTrip;
  const { metadata } = selectedTrip;
  const shouldEnableRebook = REACT_APP_SHOULD_ENABLE_REBOOK?.toLowerCase() === 'true' && !isEmpty(metadata);
  const currentUserInfo = useSelector(selectCurrentUserInfo);
  const myTripPaymentRoute = `/users/${currentUserInfo?.id}/bookings/${tavaBookingId}/payment`;
  const userInfo = useSelector(selectUserInfo)
  const [isVoucheredBooking, setIsVoucheredBooking] = useState(true);
  const currency = get(userInfo, "tenant.currency", DEFAULT_CURRENCY_CODE);
  const isFlight = selectedTrip.flights.length > 0;
  const isFlightAvailable = useSelector(selectIsFlight);
  const isHotelAvailable = useSelector(selectIsHotel);
  const isHotel = selectedTrip.hotels.length > 0;
  const hotelRoomsDetails = get(selectedTrip, "hotels[0].blockRoomResJson.blockRoomResult.hotelRoomsDetails", [])
  const lowestLastCancellationDate = !isEmpty(hotelRoomsDetails) && hotelRoomsDetails.reduce(
    (minDate, roomDetail) => {
      const roomLastVoucherDate = new Date(roomDetail.lastVoucherDate);
      return roomLastVoucherDate < minDate ? roomLastVoucherDate : minDate;
    },
    nextDate
  );

  const isNonVoucheredHotel = isHotel && !isVoucheredBooking;

  const canRebookFlight = tripDetails?.flights.length && shouldEnableRebook && !isEmpty(metadata) && !isWithinSessionExpirationTime(flightRebookTimeStamp);

  const canRebookHotel = tripDetails?.hotels.length && shouldEnableRebook && !isEmpty(metadata) && !isWithinSessionExpirationTime(rebookTimestamp);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const isVoucherExpired = (() => {
    const voucherExpiryDate = get(
      selectedTrip,
      "hotels[0].voucherExpiryDate",
      EMPTY_STRING
    );
    const currentDateTime = new Date();
    const targetDateTime = new Date(voucherExpiryDate);
    return currentDateTime > targetDateTime;
  })();

  useEffect(() => {
      let timer;
      if (continueButtonEnabled && isRebookModalOpen ) {
        timer = setTimeout(() => {
          dispatch(setSelectedModal(TRIP_PAYMENT_MODAL));
          setIsRebookModalOpen(false);
        }, 5000);
      }
      return () => {
        clearTimeout(timer);
      };
    }, [continueButtonEnabled, isRebookModalOpen]);

  useEffect(() => {
    const isVoucherBooking = get(
      selectedTrip,
      "hotels[0].isVoucheredBooking",
      true
    );
    if (!isVoucherBooking && isVoucherExpired) setIsVoucheredBooking(true);
    else setIsVoucheredBooking(isVoucherBooking);
  }, [selectedTrip])

  const toShowNonVoucherBooking =
  !isEmpty(hotelRoomsDetails) && !isVoucherExpired && lowestLastCancellationDate >= new Date();

  const { bookType } = selectedTrip || EMPTY_OBJECT;
  const handleHotelPaymentChange = () => {
    setIsVoucheredBooking((prev) => !prev);
  }

  const handleSubmit = (responseStatusCode) => {
    if (responseStatusCode === 4) {
      let updatedSelctedTrip = cloneDeep(selectedTrip);
      updatedSelctedTrip.hotels[0].isVoucheredBooking = isVoucheredBooking;
      if (!shouldDisable) {
        dispatch(
          updateHotelVoucherStatus({
            id: tavaBookingId,
            requestBody: { isVoucheredBooking },
          })
        );
        dispatch(setSelectedTrip(updatedSelctedTrip));
        shouldEnableRebook ? setContinueButtonEnabled(true) : dispatch(setSelectedModal(TRIP_PAYMENT_MODAL));
      }
    }
  };

  const handlePaymentButton = () => {
    if (isHotel && REACT_APP_HOTEL_PAY_LATER === "true" && toShowNonVoucherBooking) {
      toggleModal();
      return;
    }
    if (isFlight) {
      getFlightRebookStatus();
    }
    else {
      getHotelRebookStatus()
    };
  };

  const handleNonVoucheredHotel = (responseStatusCode) => {
    if (responseStatusCode === 4) {
      const bookCallbackRequest = {
        isPayLater: true,
        paymentSessionId: tavaBookingId,
        transactionId: null,
        currency,
        amount: ZERO,
        paymentMethod: PAYMENT_PROVIDERS.RAZORPAY,
        isHotel: isHotelAvailable,
        isFlight: isFlightAvailable,
      };
      const bookCallbackRequestBody = {
        requestBody: bookCallbackRequest,
        url: `/book-callback/${tavaBookingId}`,
      };
      dispatch(
        updateHotelVoucherStatus({
          id: tavaBookingId,
          requestBody: { isVoucheredBooking },
        })
      ).then((res) => {
        if (res.payload) {
          toggleModal();
          dispatch(initiateBookCallback(bookCallbackRequestBody));
          navigate(`${myTripPaymentRoute}/confirm`);
        }
      })
    }
  }

  const getFlightRebookStatus = () => {
    if (canRebookFlight) {
      dispatch(setFlightRebookDetail(EMPTY_OBJECT));
      dispatch(setRebookResponse(EMPTY_OBJECT));
      if (!canRebookHotel) {
        dispatch(setRebookResponse({ statusCode: 4 }))
      }
      setData({ flight: "true", hotel: "false" });
      setIsRebookModalOpen(true);
      dispatch(setFlightRebookTimestamp(new Date().toISOString()));
      dispatch(checkFlightRebookStatus(selectedTripId)).then((res) => {
        const responseStatusCode =
          res?.payload?.statusCode || res?.payload?.outbound?.statusCode || 0;
        const responseStatusCodeInbound = 
        res?.payload?.inbound?.statusCode || 4;
        if (isHotel) {
          (responseStatusCode === 4 && responseStatusCodeInbound===4) && getHotelRebookStatus();
        } else {
          responseStatusCode === 4 && (setContinueButtonEnabled(true));
        }
      });
    } else {
      dispatch(setFlightRebookDetail({ statusCode: 4 }))
      isHotel ? getHotelRebookStatus() : dispatch(setSelectedModal(TRIP_PAYMENT_MODAL));
    }
  };

  const getHotelRebookStatus = () => {
    if (canRebookHotel) {
      dispatch(setRebookResponse(EMPTY_OBJECT));
      setData({ flight: 'false', hotel: 'true' })
      setIsRebookModalOpen(true)
      dispatch(setHotelRebookTimer(new Date().toISOString()));
      dispatch(checkHotelRebookStatus(selectedTripId)).then((res) => {
        const responseStatusCode = res?.payload?.statusCode || 0;
        (isNonVoucheredHotel && !isFlight) ? handleNonVoucheredHotel(responseStatusCode) : handleSubmit(responseStatusCode);
      });
    } else {
      isNonVoucheredHotel && !isFlight ? handleNonVoucheredHotel(4) : handleSubmit(4);
    }
  };

  return (
    <>
      {
        <div className='pl-4 border-l border-gray-100 relative'>
          <div
            className={classNames(
              "pl-[15px] pr-[17px] py-[9px] rounded-md shadow justify-center items-center gap-2 flex",
              {
                "cursor-pointer": !shouldDisable,
                "cursor-not-allowed": shouldDisable,
                "bg-contrast-300": shouldDisable,
                "bg-indigo-600": !shouldDisable,
              }
            )}
            onClick={() => {
              dispatch(setIsFlight(selectedTrip?.flights?.length > 0));
              dispatch(setIsHotel(selectedTrip?.hotels?.length > 0));
              setContinueButtonEnabled(false);
              !shouldDisable &&
                isHotel
                ? handlePaymentButton()
                : getFlightRebookStatus()
            }
            }
          >
            <div className="text-white text-sm font-medium font-['Inter'] leading-tight">
              Proceed to Book
            </div>
          </div>
          {isHotel &&
            REACT_APP_HOTEL_PAY_LATER === "true" &&
            toShowNonVoucherBooking &&
            isModalOpen && (
              <div
                ref={clickOutsideRef}
                className='flex flex-col w-64 right-8 absolute p-4 bg-white rounded-md shadow-md border border-gray-200'
              >
                <h2 className='text-lg font-medium mb-4'>Payment Type</h2>
                {isFlight && (
                  <>
                    <div className='mb-4 flex flex-col gap-2'>
                      <h3 className='flex items-center gap-2 text-lg font-semibold text-primary-700'>
                        <RenderSVG
                          Svg={FlightBlue}
                          width='16'
                          height='16'
                          alt='trip_icon'
                        />
                        Flight
                      </h3>
                      <p className='text-sm text-gray-700'>
                        Your flight booking currently has the 'pay-now' option
                        selected.
                      </p>
                    </div>
                    <div className='border-b-2 border-dashed border-gray-200 mb-4' />
                  </>
                )}
                <div className='mb-4 flex flex-col gap-2'>
                  <h3 className='flex items-center gap-2 text-lg font-semibold text-primary-700'>
                    <RenderSVG
                      Svg={Hotel}
                      width='16'
                      height='16'
                      alt='trip_icon'
                    />
                    Hotel
                  </h3>
                  <p className='text-sm'>Choose how to pay for the hotel:</p>
                  <label className='flex items-center mb-2'>
                    <input
                      type='radio'
                      value='payNow'
                      checked={isVoucheredBooking}
                      onChange={handleHotelPaymentChange}
                      className='mr-2 peer peer-checked:bg-blue-50 peer-checked:border-primary-600 peer-checked:text-primary-600'
                    />
                    <span className='text-sm'>Pay Now</span>
                  </label>
                  <label className='flex items-center'>
                    <input
                      type='radio'
                      value='payLater'
                      checked={!isVoucheredBooking}
                      onChange={handleHotelPaymentChange}
                      className='mr-2 peer-hidden focus:outline-none'
                    />
                    <span className='text-sm'>Pay Later</span>
                  </label>
                </div>
                <button
                  className="mt-2 flex items-center justify-center py-2 bg-primary-700 text-white rounded-md"
                  onClick={() => {
                    getFlightRebookStatus();
                  }}
                >
                  {isNonVoucheredHotel ? (
                    <Spinner name={UPDATE_HOTEL_VOUCHERED_STATUS}>
                      Continue
                    </Spinner>
                  ) : (
                    "Continue"
                  )}
                </button>
              </div>
            )}
          {isRebookModalOpen && (
            <CombinedFlightAndHotelRebookModal
              isModalOpen={isRebookModalOpen}
              setIsModalOpen={setIsRebookModalOpen}
              flight={data.flight}
              hotel={data.hotel}
              continueButtonEnabled = {continueButtonEnabled}
              setContinueButtonEnabled={setContinueButtonEnabled}
              getHotelRebookStatus={getHotelRebookStatus}
            />
          )}
        </div>
      }
    </>
  );
};

export default PaymentButton;
