export const hasCheckInDatePassed = (checkInDate) => {
    if (!checkInDate || !checkInDate.month || !checkInDate.date || !checkInDate.year) {
      return true;
    }
  
    const { month, date, year } = checkInDate;
    const monthIndex = new Date(`${month} 1, 2000`).getMonth();
    if (monthIndex === -1) {
      return true;
    }
  
    const checkIn = new Date(year, monthIndex, date);
    const now = new Date();
    now.setHours(0, 0, 0, 0);

    return now > checkIn;
  };
