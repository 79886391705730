import { getPriceWithConversion } from "./priceFormatter";
import { getHotelBookingAmount } from "./getHotelBookingAmount";
import { getFlightBookingAmount } from "./getFlightBookingAmount";
import { DEFAULT_VALUES, DEFAULT_EXCHANGE_RATE } from "../constants";

const { ZERO } = DEFAULT_VALUES;

export const getTotalBookingAmountForTrip = (
  trips,
  tenantCurrency,
  exchangeRate,
  shouldShowHotelPaymentDetails = true,
  isFlight = true,
  isHotel = true
) => {
  let flightTotalPrice = ZERO;
  let hotelTotalPrice = ZERO;
  const currencyExchangeRate =
    exchangeRate[tenantCurrency] || DEFAULT_EXCHANGE_RATE;

  const flightBooking = getFlightBookingAmount(trips?.flights || []);
  const hotelBooking = shouldShowHotelPaymentDetails ? getHotelBookingAmount(trips?.hotels || []) : 0;

  const flightPrice = trips?.flights?.length && isFlight ? parseFloat(flightBooking) : ZERO;
  const hotelPrice = trips?.hotels?.length && isHotel ? parseFloat(hotelBooking) : ZERO;
  if (!isNaN(hotelPrice) && hotelPrice > 0)
        hotelTotalPrice = hotelPrice;

  if (!isNaN(flightPrice) && flightPrice > 0) 
    flightTotalPrice = getPriceWithConversion(
      flightPrice,
      currencyExchangeRate
    );

  const totalSum =shouldShowHotelPaymentDetails ? trips.totalPrice || (flightTotalPrice + hotelTotalPrice) : flightTotalPrice;
  const totalAmount = parseFloat(totalSum).toFixed(2);
  return totalAmount;
};

export const getTotalFlightPriceForTrip = (
  trips,
  tenantCurrency,
  exchangeRate

) => {
  let totalFlightPrice = ZERO;
  const currencyExchangeRate = exchangeRate[tenantCurrency] || DEFAULT_EXCHANGE_RATE;
  const flightBooking = getFlightBookingAmount(trips?.flights || []);
  const flightPrice = trips?.flights?.length ? parseFloat(flightBooking) : ZERO;
  if (!isNaN(flightPrice) && flightPrice > 0) {
    totalFlightPrice = getPriceWithConversion(flightPrice, currencyExchangeRate);
  }
  
  const res = parseFloat(totalFlightPrice.toFixed(2));
  return res;

}

export const getTotalHotelPriceForTrip = (
  trips,
  tenantCurrency,
  exchangeRate
) => {
  let totalHotelPrice = ZERO;
  const currencyExchangeRate = exchangeRate[tenantCurrency] || DEFAULT_EXCHANGE_RATE;
  const hotelBooking = getHotelBookingAmount(trips?.hotels || []);
  const hotelPrice = trips?.hotels?.length ? parseFloat(hotelBooking) : ZERO;
  if (!isNaN(hotelPrice) && hotelPrice > 0) 
    totalHotelPrice = hotelPrice
  const res = Math.round(totalHotelPrice * 100) / 100
  return res;
}
