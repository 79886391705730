import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames";
import { v4 as uuid } from "uuid";
import Modal, { setSelectedModal } from "../../Modal";
import { TBOAPICallSkeleton } from "../../AppSkeletons";
import { resetFlights, setActiveFilters, setFlightSearchOptions, setFlightRebookFilters } from "../../Search";
import Spinner, { SPINNER_NAMES } from "../../Spinner";
import { selectCountryInfo } from "../../../../screens/Profile";
import { setSessionFlag } from "../../../../screens/session";
import {
  selectSelectedTrip,
  selectFlightRebookDetail,
  setFlightRebookTimestamp,
  checkHotelRebookStatus,
  selectSelectedTripId,
  selectRebookResponse,
  setHotelRebookTimer,
  selectIsFlight,
  selectIsHotel,
  setIsHotel,
  setIsFlight,
  setRebookResponse,
} from "../../../../screens/Booking/Trips";
import {
  setAddressDetails,
  setBookingTravelers,
  setContactDetails,
  setSelectedTravelers,
  setSpecialServices,
} from "../../../../screens/Booking/FlightBookings";
import {
  setCurrentFlightType,
  setFlightPriceInfo,
  setFlightPriceReq,
  setSelectedFlightId,
  setSelectedFlightInfo,
  setSelectedFlightOptions,
} from "../../../../screens/FlightResults";
import {
  getFormattedDateTime,
  getFormattedRebookPriceRequest,
  getQueryParams,
  mapTravelerCount,
  mapTripPassengersToTravelers,
  setToSessionStorage,
} from "../../../../helper";
import { BaggageIcon, Check, Cross, CrossRed, Flight, FlightIcon, QuestionIcon, PendingIcon, RenderSVG, CircledExclaimation } from "../../../../assets/icons";
import { MODALS } from "../modals.constants";
import {
  CACHE_KEYS, DEFAULT_VALUES, INITIAL_FILTERS,
  DEFAULT_FLIGHT_SEARCH_OPTIONS,
  FLIGHTS_RESULT_TYPE,
  MIN_MONTHS_DIFF_FOR_PASSPORT_EXPIRY,
} from "../../../../constants";
import { useEffect, useState } from "react";
import { get, isEmpty } from "lodash";
import { setOtherGuests } from "../../../../screens/HotelBooking";
import { setHotelInfo, setHotelInfoReqBody } from "../../../../screens/HotelInfo";
import { cancelAllAPIRequests } from "../../../../infrastructure/httpMethods/requestingMethods";
import { CheckCircleIcon } from "../../../../assets/icons";
import Tabs from "../../../organisms/Tabs";
const { REACT_APP_SHOULD_ENABLE_REBOOK = 'true', REACT_APP_TBO_SESSION_EXPIRATION_TIME = 0 } = process.env;
const shouldEnableRebook = REACT_APP_SHOULD_ENABLE_REBOOK?.toLowerCase() === 'true';

const { FLIGHT_REBOOK, HOTEL_REBOOK } = SPINNER_NAMES;
const { EMPTY_OBJECT, EMPTY_STRING, EMPTY_ARRAY, ONE, FOUR, ZERO } = DEFAULT_VALUES;
const { TRIP_PAYMENT_MODAL, REBOOK_STATUS_MODAL } = MODALS;
const { SESSION_ID } = CACHE_KEYS;
const { OUTBOUND_RESULT } = FLIGHTS_RESULT_TYPE;
const PRICE = 'flights/price';
const FLIGHTS = 'flights';

const InfoMessage = ({ message }) => (
  <div className='flex gap-3 justify-center items-center bg-[#F8F8FA] p-4 border border-gray-200 rounded-lg'>
    <div className='p-3 rounded-full bg-primary-100/50 w-fit'>
      <div className='rounded-full bg-primary-600 text-white w-5 h-5 text-center font-bold'>i</div>
    </div>
    <div>{message}</div>
  </div>
);

const Button = ({ onClick, text, additionalClasses }) => (
  <div
    className={classNames(
      "pl-[15px] pr-[17px] py-[9px] rounded-md shadow justify-center items-center gap-2 flex",
      additionalClasses
    )}
    onClick={onClick}
  >
    <div className="text-sm font-medium">{text}</div>
  </div>
);

const checkAvailabilityFlightMessage = (flightRebookStatusCode, responseMessage = EMPTY_STRING) => {
  switch (flightRebookStatusCode) {
    case 1:
      return "The flight you saved is not available, please search again.";
    case 2:
      return "Special services for the saved flights are not available due to updated policies, features or rates.";
    case 3:
      return `${responseMessage}`;
    case 4:
      return "Flight you saved is available for booking, kindly proceed to payment.";
    default:
      return "Something went wrong.";
  }
}

const checkAvailabilityHotelMessage = (responseStatusCode, responseMessage = EMPTY_STRING) => {
  switch (responseStatusCode) {
    case 1:
      return "The hotel you saved is not available, please search again.";
    case 2:
      return "Selected room/rooms not available in the saved hotel, please select another room option.";
    case 3:
      return `${responseMessage}`;
    case 4:
      return "Hotel you saved is available for booking, kindly proceed to payment.";
    case 5:
      return "Some rooms of the saved hotels are not available due to updated policies, features or rates.";
    default:
      return "Something went wrong.";
  }
}

const getHighestPriorityStatusCode = (inbound, outbound) => {
  const priority = {
    1: 1,
    3: 2,
    2: 3,
    4: 4
  };

  if (priority[inbound] < priority[outbound]) {
    return inbound;
  }
  return outbound;
};

export default function CombinedFlightAndHotelRebookModal({ isModalOpen, setIsModalOpen, flight, hotel, continueButtonEnabled, setContinueButtonEnabled, getHotelRebookStatus }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const rebookResponse = useSelector(selectRebookResponse);
  const hotelRebookStatusCode = rebookResponse.statusCode || 0;
  const isFlightAvailable = useSelector(selectIsFlight);
  const isHotelAvailable = useSelector(selectIsHotel);
  const [responseStatusCodeHotel, setResponseStatusCodeHotel] = useState(0);
  const [timer, setTimer] = useState(5); 
  const { ip: endUserIp } = useSelector(selectCountryInfo) || {};
  const flightRebookDetail = useSelector(selectFlightRebookDetail);
  const tripDetails = useSelector(selectSelectedTrip);
  const isFlight = tripDetails?.flights?.length > 0;
  const isHotel = tripDetails?.hotels?.length > 0;
  const {sessionId: flightSessionId, outbound = {}, inbound = {}, statusCode=0, message} = flightRebookDetail||{};
  const sessionId = outbound?.sessionId || flightSessionId;
  const miniFareRules = get(tripDetails, 'metadata.flightRebookData.flightSelectedElement.miniFareRules', null);
  const responseStatusInbound = statusCode || inbound?.statusCode || 0;
  const rebookResponseMessageFlight = message || inbound?.message || outbound?.message || EMPTY_STRING;
  const rebookResponseMessageHotel = get(rebookResponse,'message', EMPTY_STRING);
  const responseStatusOutbound = statusCode || outbound?.statusCode || 0;
  const flightRebookStatusCode = getHighestPriorityStatusCode(responseStatusInbound, responseStatusOutbound);
  const encodedCurrentIP = btoa(endUserIp);
  const sessionParams = `${sessionId ? sessionId : uuid()}_${encodedCurrentIP}`;


  useEffect(() => {
    if (continueButtonEnabled && timer > 0 ) {
      const interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000); 
      return () => clearInterval(interval); 
    }
  }, [continueButtonEnabled, timer]);

  const sessionData = {
    sessionParams,
    encodedCurrentIP,
    sessionId,
  };
  const sessionIdHotel = rebookResponse?.sessionId;
  const sessionParamsHotel = `${sessionIdHotel}_${encodedCurrentIP}`;
  const sessionDataHotel = {
    sessionParams: sessionParamsHotel,
    encodedCurrentIP,
    sessionId: sessionIdHotel,
  };

  useEffect(() => {
    if (!isEmpty(rebookResponse))
      setResponseStatusCodeHotel(rebookResponse.statusCode);
  }, [rebookResponse]);

  const handleClose = (bookType) => {
    cancelAllAPIRequests();
    bookType === 'hotel' && dispatch(setIsHotel(false));
    bookType === 'flight' && dispatch(setIsFlight(false));
    if(bookType === 'flight'){ 
      isHotelAvailable?getHotelRebookStatus() : setIsModalOpen(false);
    }
    if (bookType === 'hotel'){
      if(isFlightAvailable){ 
        dispatch(setRebookResponse({ statusCode: 10 }));
        setContinueButtonEnabled(true);
      }
      else { setIsModalOpen(false); }
    }
  };

  const handleNavigate = (queryString) => navigate(`${location.pathname}/${queryString}`);

  const searchHotelAgain = () => {
    const hotelSearchRequest = get(
      tripDetails,
      "metadata.hotelRebookData.hotelSearchRequest",
      {}
    );
    const updatedSearchFilters = {
      ...hotelSearchRequest,
      sessionId: sessionIdHotel,
      timestamp: new Date().toISOString(),
    };
    const queryString = getQueryParams(updatedSearchFilters);
    dispatch(setSelectedModal(null));
    setToSessionStorage("hotelsSession", sessionDataHotel);
    navigate(`hotels?${queryString}`);
    setToSessionStorage('HOTEL_SEARCH_FILTERS', updatedSearchFilters);
    dispatch(setHotelRebookTimer(''))
  };

  const searchHotelPrice = () => {
    const hotelCode = get(rebookResponse, "curRequest.hotelCode", "");
    const hotelPriceRequest = {
      ...rebookResponse?.curRequest,
      disableAPI: true,
    }
    const { hotelRoomsDetails, paymentDetails } = tripDetails?.hotels[0]?.bookingReqJson;
    const { state, country } = paymentDetails;
    const passengersWithRoomIndex = hotelRoomsDetails.flatMap((room, roomIndex) => {
      const { hotelPassenger } = room;

      return hotelPassenger.map((passenger) => {
        const {
          title,
          firstName,
          middleName,
          lastName,
          paxType,
          phoneNumber,
          email,
          pan,
        } = passenger;

        return {
          id: uuid(),
          profileDetails: {
            title,
            firstName,
            middleName,
            lastName,
            isChild: paxType !== 1,
          },
          rebookData: {
            phoneNumber,
            email,
            state,
            country,
          },
          passportDetails: {
            passportNumber: EMPTY_STRING,
            issueCountry: { isoCode: EMPTY_STRING },
            issuedDate: getFormattedDateTime(new Date())[0],
            expiryDate: getFormattedDateTime(
              new Date(
                new Date().setMonth(
                  new Date().getMonth() + MIN_MONTHS_DIFF_FOR_PASSPORT_EXPIRY
                )
              )
            )[0],
          },
          panCardDetails: {
            panCardNumber: pan,
            surname: lastName,
            isChild: paxType !== 1,
          },
          roomIndex,
          isSelected: true,
        };
      });
    });
    dispatch(setOtherGuests(passengersWithRoomIndex))
    dispatch(setHotelInfoReqBody(hotelPriceRequest));
    dispatch(setSelectedModal(null));
    dispatch(setHotelInfo(rebookResponse?.curResponse));
    sessionStorage.removeItem('HOTEL_INFO_REQUEST_BODY');
    sessionStorage.removeItem('HOTEL_SEARCH_FILTERS');
    setToSessionStorage("hotelsSession", sessionDataHotel);
    dispatch(setSessionFlag(`session_updated_on_${Date()}`));
    handleNavigate(`hotels/${hotelCode}?sessionId=${sessionParams}`);
  };

  const searchFlightAgain = () => {
    dispatch(setSelectedModal(null));
    dispatch(resetFlights())
    dispatch(setActiveFilters(INITIAL_FILTERS));
    dispatch(setFlightSearchOptions(DEFAULT_FLIGHT_SEARCH_OPTIONS));
    dispatch(setCurrentFlightType(OUTBOUND_RESULT));
    dispatch(setSelectedFlightInfo(EMPTY_OBJECT));
    dispatch(setSelectedFlightOptions(EMPTY_OBJECT));
    dispatch(setFlightRebookTimestamp(EMPTY_STRING));
    dispatch(
      setSelectedFlightId({
        inbound: null,
        outbound: null,
        packages: null,
      })
    );

    const searchFilters =
      flightRebookDetail?.nextApiReq || flightRebookDetail?.outbound?.Metadata || tripDetails?.metadata?.flightRebookData?.flightSearchRequest;
    const updatedSearchFilters = {
      ...searchFilters,
      sessionId: sessionParams,
      timestamp: new Date().toISOString()
    };
    const queryString = getQueryParams(updatedSearchFilters);

    setToSessionStorage(SESSION_ID, sessionData);
    handleNavigate(`${FLIGHTS}?${queryString}`);
  };

  const navigateToFlightPrice = () => {
    const userDetails =
      tripDetails?.flights[ZERO]?.bookingJson?.journeyDetails[ZERO]
        ?.travelerDetails[ZERO];

    const {
      email = "",
      phoneCode = "+91",
      phoneNumber = "",
      address = "",
      city = "",
      state = tripDetails?.flights?.[ZERO]?.bookingJson?.journeyDetails?.[ZERO]?.price?.paymentsDetails?.state || "",
    } = userDetails || {};
    const nationality = {
      isoCode: "",
      name: userDetails?.country || "India",
    };

    const formattedPhoneNumber = phoneCode.replace("+", "") + phoneNumber
    const contactInfo = { email, phoneCode, phoneNumber: formattedPhoneNumber };
    const addressInfo = { address, city, state, nationality };
    const passengersCount = mapTravelerCount(
      tripDetails?.flights[ZERO]?.bookingJson.journeyDetails[ZERO]?.travelerDetails
    );

    const outboundResponse = [flightRebookDetail?.outbound?.priceResponse];
    const inboundResponse = flightRebookDetail?.inbound?.priceResponse && [
      flightRebookDetail?.inbound?.priceResponse,
    ];
    dispatch(setContactDetails(contactInfo));
    dispatch(setAddressDetails(addressInfo));
    dispatch(setFlightRebookFilters({ passengersCount }));
    dispatch(
      setSelectedTravelers(
        mapTripPassengersToTravelers(
          tripDetails?.flights[ZERO]?.bookingJson.journeyDetails[ZERO]
            ?.travelerDetails
        )
      )
    );
    dispatch(
      setBookingTravelers(
        mapTripPassengersToTravelers(
          tripDetails?.flights[ZERO]?.bookingJson.journeyDetails[ZERO]
            ?.travelerDetails
        )
      )
    );

    const flightPriceInfo = [...outboundResponse];
    inboundResponse && flightPriceInfo.push(...inboundResponse);
    dispatch(setFlightPriceInfo(flightPriceInfo));
    dispatch(setSpecialServices(EMPTY_ARRAY));

    const mapOutboundReq = getFormattedRebookPriceRequest(
      flightRebookDetail?.outbound, endUserIp, miniFareRules
    );
    const mapInboundReq =
      flightRebookDetail?.inbound &&
      getFormattedRebookPriceRequest(flightRebookDetail?.inbound, endUserIp, miniFareRules);
    if (flightRebookDetail?.inbound)
      mapOutboundReq[ZERO].flightSelectedElement = [mapOutboundReq[ZERO].flightSelectedElement, mapInboundReq[ZERO].flightSelectedElement]
    if (flightRebookDetail?.inbound)
      mapInboundReq[ZERO].flightSelectedElement = [mapInboundReq[ZERO].flightSelectedElement, mapOutboundReq[ZERO].flightSelectedElement[ZERO]]
    const flightPriceReq = [...mapOutboundReq];
    mapInboundReq && flightPriceReq.push(...mapInboundReq);

    dispatch(setFlightPriceReq(flightPriceReq));
    dispatch(setSelectedModal(null));

    const queryParams = `${SESSION_ID}=${sessionParams}`;
    handleNavigate(`${PRICE}?${queryParams}`);
    setToSessionStorage(SESSION_ID, sessionData);
    dispatch(setSessionFlag(`session_updated_on_${Date()}`));
  };


  return (
    <Modal shouldShowModalFromProps={isModalOpen}>
      <div className='bg-white px-4 pb-2 pt-2 sm:p-6 sm:pb-4'>
        <div className='w-full p-3 border-b border-contrast-300 shadow-sm  justify-start items-center gap-4 inline-flex'>
          <div className={`p-3 rounded-full bg-primary-100/50`}>
            <div
              className={`rounded-full bg-primary-600 text-white w-5 h-5 text-center font-bold`}
            >
              !
            </div>
          </div>
          <div className="grow shrink basis-0 text-contrast-900 text-2xl font-bold font-['Inter'] leading-loose">
            Checking Availability
          </div>
          <button
            type='button'
            className='p-[9px] rounded-[19px] justify-center items-center flex hover:shadow hover:bg-zinc-300'
            onClick={() => { setIsModalOpen(false); cancelAllAPIRequests(); }}
          >
            <RenderSVG Svg={Cross} alt='cross' className='relative text-contrast-900' />
          </button>
        </div>
      </div>
      <div className="p-6">
        <div className="flex gap-4 justify-center">
          {isFlight && (<div className="text-center w-1/2 flex justify-center">
            <div className="flex items-center cursor-pointer text-xs md:text-sm text-contrast-600 gap-2 hover:text-blue-500">
              <Spinner name={FLIGHT_REBOOK}>
                {isFlightAvailable ? ((flightRebookStatusCode === 4) ? (<RenderSVG
                  Svg={CheckCircleIcon}
                  className="w-4 h-4"
                  alt="flight-icon"
                />) : ((
                  <RenderSVG
                    Svg={CircledExclaimation}
                    className="w-4 h-4"
                    alt="flight-icon"
                  />
                ))) : (
                  <RenderSVG
                    Svg={CrossRed}
                    className="w-4 h-4"
                    alt="flight-icon"
                  />
                )}
              </Spinner>
              <p className="pt-1 text-[16px] text-black">Flight Availability</p>
            </div>
          </div>)}
          {isHotel && (<div className="text-center w-1/2 flex justify-center">
            <div className="flex items-center cursor-pointer text-xs md:text-sm text-contrast-600 gap-2 hover:text-blue-500">
              <Spinner name={[HOTEL_REBOOK]}>
                {(isHotelAvailable && hotelRebookStatusCode !== 0) ? ((hotelRebookStatusCode === 4) ? (<RenderSVG
                  Svg={CheckCircleIcon}
                  className="w-4 h-4"
                  alt="hotel-icon"
                />) : (<RenderSVG
                  Svg={CircledExclaimation}
                  className="w-4 h-4"
                  alt="hotel-icon"
                />)) : (hotelRebookStatusCode===10? <RenderSVG
                  Svg={CrossRed}
                  className="w-4 h-4"
                  alt="hotel-icon"
                />:<RenderSVG
                  Svg={PendingIcon}
                  className="w-4 h-4"
                  alt="hotel-icon"
                />)}
              </Spinner>
              <p className="pt-1 text-[16px] text-black">Hotel Availability</p>
            </div>
          </div>)}
        </div>
      </div>
      <div className='icon mb-2 py-6'>
        <RenderSVG Svg={BaggageIcon} className='mx-auto' width={100} height={100} />
      </div>
      <div className='p-6'>
        <Spinner name={[FLIGHT_REBOOK, HOTEL_REBOOK]} showSkeleton={true} loaderComponent={<TBOAPICallSkeleton />}>
        {(continueButtonEnabled===true)? (
          <>
              <div className="flex items-center justify-between gap-3 p-4 rounded-lg text-green-500">
                <div className="font-medium">
                  Redirecting to Payment <span className="font-bold">{timer}</span>
                </div>
                <button
                  className="text-primary-600 underline hover:text-primary-700 cursor-pointer"
                  onClick={() => {
                    setIsModalOpen(false);
                    dispatch(setSelectedModal(TRIP_PAYMENT_MODAL));
                  }}
                >
                  Continue
                </button>
              </div>
          </>):(
            <>
            {flight === 'true' && (
            <>
              <InfoMessage message={checkAvailabilityFlightMessage(flightRebookStatusCode, rebookResponseMessageFlight)} />
              <div className="flex justify-center gap-4 mt-6">
                <Button
                  onClick={searchFlightAgain}
                  text="Search Flight Again"
                  additionalClasses="hover:cursor-pointer"
                />
                {flightRebookStatusCode > ONE && (
                  <Button
                    onClick={navigateToFlightPrice}
                    text="Proceed with this price"
                    additionalClasses="bg-indigo-800 text-white hover:cursor-pointer"
                  />
                )}
                <Button
                  onClick={() => handleClose('flight')}
                  text="Cancel"
                  additionalClasses="bg-red-800 text-white hover:cursor-pointer"
                />
              </div>
            </>
          )}
          {hotel === 'true' && (
            <>
              <InfoMessage message={checkAvailabilityHotelMessage(responseStatusCodeHotel, rebookResponseMessageHotel)} />
              <div className="flex justify-center gap-4 mt-6">
                <Button
                  onClick={searchHotelAgain}
                  text="Search Hotel Again"
                  additionalClasses="hover:cursor-pointer"
                />
                {responseStatusCodeHotel == 3 && (
                  <Button
                    onClick={searchHotelPrice}
                    text="Proceed with this price"
                    additionalClasses="bg-indigo-800 text-white hover:cursor-pointer"
                  />
                )}
                <Button
                  onClick={() => handleClose('hotel')}
                  text="Cancel"
                  additionalClasses="bg-red-800 text-white hover:cursor-pointer"
                />
              </div>
            </>
          )}
          </>
          )}
        </Spinner>
      </div>
    </Modal >
  );
}