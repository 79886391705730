import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { get, isEmpty } from "lodash";
import { Helmet } from "react-helmet";
import * as Yup from "yup";
import HotelPolicyModal from "../../components/organisms/AppModals/HotelPolicyModal";
import {
  getFromSessionStorage,
  getFormattedLongDate,
  setToSessionStorage,
  getNextDate,
  renderStars,
  getFormattedAddress,
  getCancellationCharges,
  getFormattedDateObject,
  getDayDifference,
  getFutureFormattedDateObject,
  getHotelDocumentRequirement,
  getFromLocalStorage,
  getCurrentDateTime,
  getFormattedDate,
  getPassportValidationSchema,
  getPanValidationSchema,
  getPrimaryPaxValidationSchema,
} from "../../helper";
import {
  selectHotelInfo,
  selectPricePolicyInfo,
  selectPricePolicyReqBody,
  selectSelectedRoomInfo,
} from "../HotelInfo";
import { actions as hotelActions } from "../HotelInfo/hotelInfo.reducer";
import { actions as hotelBookingActions } from "../HotelBooking/hotelBooking.reducer";
import { Check, CrossRed, RenderSVG } from "../../assets/icons";
import { selectHotelSearchFilters } from "../../components/organisms/Search";
import Spinner, {
  SPINNER_NAMES,
  selectActiveSpinners,
} from "../../components/organisms/Spinner";
import ErrorMessage from "../../components/atoms/ErrorMessage";
import GuestDetailsSection from "./GuestDetailsSection";
import { selectCountryInfo, selectUserInfo } from "../Profile";
import PriceBreakdownSection from "./PriceBreakdownSection";
import HotelDetailsChangeModal from "../../components/organisms/AppModals/HotelDetailsChangeModal/HotelDetailsChangeModal";
import CancellationPolicyInfo from "./CancellationPolicyInfo";
import { setSelectedModal } from "../../components/organisms/Modal";
import { MODALS } from "../../components/organisms/AppModals";
import {
  selectSelectedTrip,
  selectSelectedTripId,
  setSearchTimeLog,
  setShowTimer,
  setShowTimerExpireBanner,
} from "../Booking/Trips";
import {
  selectSelectedPromoCode,
  setPromoCode,
  setTripStatus
} from "../Booking/FlightBookings";
import PromoCodeCard from "../../components/molecules/PromoCodeCard";
import {
  initiateHotelBooking,
  selectOtherGuests,
} from "../HotelBooking";
import {
  DEFAULT_VALUES,
  CACHE_KEYS,
  ROUTES,
  BOOKING_TYPES,
  CANCELLATION_CHARGE_TYPES,
  BOOKING_DOCUMENTS_TYPE,
  SEARCH_SECTION,
} from "../../constants";
import { mapGenericBookingRequest } from "../../helper/mapHotelBookingAPIRequest";
import { selectCurrentUserInfo, selectTenantDetails } from "../Auth";
import { TBOAPICallSkeleton } from "../../components/organisms/AppSkeletons";
import { selectTripStatus, selectTripOrder, setHotelSearchQuery } from "../Booking/FlightBookings";
import { toast } from "react-toastify";
import { checkingGuestValidation } from "../../helper/hotelBookingHelpers";
import PaymentType from "./PaymentType";
import { selectHotelRebookTimer } from "../Booking/Trips/trips.selector";

const { EMPTY_OBJECT, EMPTY_ARRAY, EMPTY_STRING, ZERO, ONE } = DEFAULT_VALUES;
const { PERCENTAGE, AMOUNT, NIGHTS } = CANCELLATION_CHARGE_TYPES;
const { PRICE_POLICY_REQUEST_BODY, BOOKING_INFORMATION, HOTEL_SEARCH_TIME, HOTEL_SEARCH_FILTERS } = CACHE_KEYS;
const { VERIFY_HOTEL_PRICE_POLICY, HOTEL_BOOKING, FETCH_FLIGHTS_RESULTS } = SPINNER_NAMES;
const { HOTEL_DETAILS_CHANGE_MODAL } = MODALS;
const { BOOKING_ID, TERMS_AND_CONDITION, CANCELLATION_AND_REFUND_POLICY } =
  ROUTES;
const { NON_VOUCHER_BOOKING, VOUCHER_BOOKING } = BOOKING_TYPES;
const ERROR_VISIBILITY_DURATION = 3000;
const { AMENITIES_MODAL } = MODALS;
const { PASSPORT, PAN } = BOOKING_DOCUMENTS_TYPE
const { FLIGHT } = SEARCH_SECTION;

const { setPricePolicyReqBody } = hotelActions;
const { setHotelBookReqBody, setHotelBookRes } = hotelBookingActions;

const hotelGuidelines = [
  {
    id: 1,
    data: "Passport, Aadhaar, Driving License and Govt. ID are accepted as ID proof(s)",
  },
];

const HotelReview = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const bookingPrimaryDetailsFormRef = useRef(null);
  const valueRef = useRef(null);
  const guestDetailsRef = useRef(null);

  const currentUserInfo = useSelector(selectCurrentUserInfo);
  const activeSpinners = useSelector(selectActiveSpinners);
  const userInfo = useSelector(selectUserInfo);
  const tavaBookingId = useSelector(selectSelectedTripId);
  const searchFilters = useSelector(selectHotelSearchFilters);
  const selectedCountryInfo = useSelector(selectCountryInfo);
  const selectedRoomDetails = useSelector(selectSelectedRoomInfo);
  const pricePolicyReqBody = useSelector(selectPricePolicyReqBody);
  const pricePolicyInfo = useSelector(selectPricePolicyInfo);
  const otherGuests = useSelector(selectOtherGuests);
  const appliedPromoCode = useSelector(selectSelectedPromoCode);
  const selectedHotelInfo = useSelector(selectHotelInfo);
  const tenantDetails = useSelector(selectTenantDetails);
  const selectedTrip = useSelector(selectSelectedTrip);
  const searchHotelFilters = useSelector(selectHotelSearchFilters);
  const selectedHotelRebookTimer = useSelector(selectHotelRebookTimer)

  const [isSpinnerActive, setIsSpinnerActive] = useState(false);
  const [voucherBookingType, setVoucherBookingType] = useState(VOUCHER_BOOKING);
  const [errorMessage, setErrorMessage] = useState("");
  const [isBookingInProcess, setIsBookingInProcess] = useState(false);
  const [showHotelPolicytModal, setShowHotelPolicyModal] = useState(false);
  const [isUserAgreementsAndCancellationPolicyChecked, setIsUserAgreementsAndCancellationPolicyChecked] = useState(false);
  const [agreementsSectionHighlighted, setAgreementsSectionHighlighted] = useState(false);
  const [checkinTime, setCheckinTime] = useState("");
  const [checkoutTime, setCheckoutTime] = useState("");
  const tripStatus = useSelector(selectTripStatus);
  const selectedTripOrder = useSelector(selectTripOrder); 
  const {REACT_APP_HOTEL_PAY_LATER = 'false'} = process.env;
  
  const hotelTotalFare = valueRef.current;
  const { isAISearchInitiated, flightSearchQuery } = tripStatus;
  const isUserAuthenticated = !!userInfo?.id;
  const selectedOtherGuests = otherGuests?.filter((guest) => guest.isSelected);
  const isPromoCodeApplied = !isEmpty(appliedPromoCode);
  const nextDate = getNextDate(new Date());
  const { email: loggedInUserEmail } = userInfo || {};
  const tenantConfig = get(tenantDetails, "tenantConfig");
  const hotelInfo = get(pricePolicyInfo, "blockRoomResult", EMPTY_OBJECT);
  const hotelRebookSearchRequest = get(
    selectedTrip,
    "metadata.hotelRebookData.hotelSearchRequest",
    EMPTY_OBJECT
  );
  const hotelImages = get(
    selectedHotelInfo,
    "priceResult.hotelDetails.images",
    EMPTY_OBJECT
  );
  const brandName = { tenantConfig };
  const { hotelRoomsDetails = [] } = hotelInfo;
  const dayRates = !!Object.keys(selectedHotelInfo).length
    ? get(
      selectedHotelInfo,
      "priceResult.hotelRoomsResult.hotelRoomsDetails[0].dayRates",
      EMPTY_ARRAY
    )
    : get(hotelRoomsDetails, "0.dayRates", EMPTY_ARRAY);
  const NIGHT = "Night";
  const GUEST = "Guest";
  const ROOM = "Room";
  const checkInDateObject = dayRates?.[ZERO];
  const checkOutDateObject = dayRates?.slice(-1)[ZERO];
  let checkIn;
  let checkOut;
  let totalDays;
  // if ((checkInDateObject, checkOutDateObject)) {
  //   checkIn = getFormattedDateObject(checkInDateObject.date);
  //   checkOut = getFutureFormattedDateObject(checkOutDateObject.date);
  //   totalDays = getDayDifference(
  //     checkInDateObject.date,
  //     checkOutDateObject.date
  //   );
  // }
  const selectedHotelSearchFilters =
  (!isEmpty(searchFilters) && searchFilters) || getFromSessionStorage(HOTEL_SEARCH_FILTERS) || hotelRebookSearchRequest;
  checkIn = selectedHotelSearchFilters.checkInDate;
  checkOut = selectedHotelSearchFilters.checkOutDate;
  const noOfNights = selectedHotelSearchFilters.noOfNights || ONE;

  const { validationAtConfirm = {}, validationAtVoucher = {} } =
    get(hotelInfo, "validationInfo") || {};

  const isPANRequired = getHotelDocumentRequirement(hotelRoomsDetails, PAN);
  const isPassportRequired = getHotelDocumentRequirement(hotelRoomsDetails, PASSPORT);
  const isSamePanForAllAllowed =
    validationAtConfirm?.isSamePanForAllAllowed ||
    validationAtVoucher?.isSamePanForAllAllowed;
  const areAllGuestDetailsRequired = true; //To-do: Need to update this to previous logic once discussed with provider regarding the requirement for all pax details.

  const lowestLastCancellationDate = hotelRoomsDetails.reduce(
    (minDate, roomDetail) => {
      const roomLastVoucherDate = new Date(roomDetail.lastVoucherDate);
      return roomLastVoucherDate < minDate ? roomLastVoucherDate : minDate;
    },
    nextDate
  );

  const toShowNonVoucherBooking =
    !isEmpty(hotelRoomsDetails) && lowestLastCancellationDate >= new Date();

  const { cancellationPolicies = [] } = selectedRoomDetails[ZERO] || {};
  const { isCancellationPolicyChanged, isHotelPolicyChanged, isPriceChanged } =
    hotelInfo || {};

  const roomGuests = get(searchFilters, 'roomGuests', []).length > 0
    ? get(searchFilters, 'roomGuests', [])
    : get(hotelRebookSearchRequest, 'roomGuests', []);

  const noOfRooms = get(searchFilters, 'noOfRooms') ??
    get(hotelRebookSearchRequest, 'noOfRooms', ZERO);

  const hotelPolicyDetail =
    hotelInfo?.hotelPolicyDetail ||
    get(selectedHotelInfo, "priceResult.hotelDetails.hotelPolicy", "");
  const { hotelName, starRating } = !!Object.keys(selectedHotelInfo).length
    ? get(selectedHotelInfo, "priceResult.hotelDetails", "")
    : hotelInfo;
  const address =
    get(selectedHotelInfo, "priceResult.hotelDetails.address", "") ||
    hotelInfo?.addressLine1;
  const hotelCode = get(pricePolicyReqBody, "hotelCode", EMPTY_STRING);
  const freeCancellationBeforeDate = (() => {
    for (let i = 0; i < cancellationPolicies.length; i++) {
      if (cancellationPolicies[i].charge != 0) {
        return cancellationPolicies[i].fromDate;
      }
    }
  })();
  const nonRefundable = [];
  const partialRefundable = [];

  cancellationPolicies.forEach((item) => {
    if (item.charge === 100 && item.chargeType === PERCENTAGE) {
      nonRefundable.push(item);
    } else {
      partialRefundable.push(item);
    }
  });
  let nonRefundableFromDate = nonRefundable[ZERO]?.fromDate;
  const totalGuests = roomGuests.reduce(
    (total, guestObject) =>
      total + guestObject["noOfAdults"] + guestObject["noOfChild"],
    ZERO
  );

  const roomAndBedType = (roomDetail) =>
    roomDetail?.[0]?.roomTypeName?.split(",") || [];
  const getGuestsCount = (guests = []) => {
    return guests.reduce(
      (total, guestObject) =>
        total + guestObject["noOfAdults"] + guestObject["noOfChild"],
      ZERO
    );
  };

  const amenityDetails = get(
    pricePolicyInfo,
    "blockRoomResult.hotelRoomsDetails[0].amenity",
    EMPTY_ARRAY
  );

  const handleClose = () => setShowHotelPolicyModal(false);
  useEffect(() => {
    if (isEmpty(pricePolicyReqBody)) {
      const data = getFromSessionStorage(PRICE_POLICY_REQUEST_BODY);
      dispatch(setPricePolicyReqBody(data));
    }
    return () => {
      dispatch(setPromoCode());
    };
  }, [dispatch]);

  useEffect(() => {
    if (isCancellationPolicyChanged || isHotelPolicyChanged)
      dispatch(setSelectedModal(HOTEL_DETAILS_CHANGE_MODAL));
  }, [dispatch, hotelInfo]);

  useEffect(() => {
    isPromoCodeApplied && setVoucherBookingType(VOUCHER_BOOKING);
  }, [isPromoCodeApplied]);

  const handleOptionChange = (e) => {
    setVoucherBookingType(e.target.value);
  };

  const showErrorMessage = (msg) => {
    setErrorMessage(msg);
    setTimeout(() => {
      setErrorMessage("");
    }, ERROR_VISIBILITY_DURATION);
  };

  const getPaymentButtonText = () => {
      return t("travelerInfo.continueButton.save");
  };

  const verifyGuestCountValidation = (values, inValidGuestData) => {
    if (totalGuests !== selectedOtherGuests.length) {
      const remainingGuests = totalGuests - selectedOtherGuests.length;
      showErrorMessage(
        remainingGuests < 0
          ? `Please remove ${Math.abs(
            remainingGuests
          )} guests before proceeding.`
          : `Please add ${remainingGuests} guests before proceeding.`
      );
      return;
    }
    if (!isEmpty(inValidGuestData)) {
      showErrorMessage("Please fill all the required details of each guest correctly");
      return;
    }
    const roomHasAdult = Array(noOfRooms).fill(false);
    selectedOtherGuests.forEach((guest) => {
      if (!guest.profileDetails.isChild) {
        roomHasAdult[guest.roomIndex] = true;
      }
    });
    if (roomHasAdult.filter((hasAdult) => hasAdult).length < noOfRooms) {
      showErrorMessage("Each room must have at least one adult.");
      return;
    }
    initiatePayment(values);
    setErrorMessage("");
  };
  
  useEffect(() => {
    const extractCheckInOutTimes = () => {
      try {
        if (hotelPolicyDetail) {
          const segments = hotelPolicyDetail.split("|");
          segments?.forEach((segment) => {
            const trimmedSegment = segment
              .trim()
              .replace(/<\/?[^>]+(>|$)/g, "");
            if (trimmedSegment?.startsWith("CheckIn Time-Begin")) {
              const match = trimmedSegment?.match(/: ([\d:]+ [AP]M)/);
              if (match) setCheckinTime(match[1]);
            }
            if (trimmedSegment?.startsWith("CheckOut Time")) {
              const match = trimmedSegment?.match(/: ([\d:]+ [AP]M)/);
              if (match) setCheckoutTime(match[1]);
            }
          });
        }
      } catch (error) {
        console.error("Error in extractCheckInOutTimes:", error);
      }
    };
    extractCheckInOutTimes();
  }, [hotelPolicyDetail]);

  const initiatePayment = async (values) => {
    setIsBookingInProcess(true);
    const bookingAPIRequest = mapGenericBookingRequest({
      pricePolicyReqBody,
      tavaBookingId,
      pricePolicyInfo,
      userCountryInfo: selectedCountryInfo,
      voucherBookingType,
      leadGuest: values,
      otherGuests: selectedOtherGuests,
      isSamePanForAllAllowed,
      roomGuests,
      loggedInUserEmail,
      bookingType: "hotel",
      hotelImage: hotelImages?.[ZERO],
      checkInDate: checkIn,
      isPassportRequired,
      isPANRequired,
      hotelSearchRequest: !isEmpty(searchHotelFilters) ? searchHotelFilters : hotelRebookSearchRequest,
      timestamp: !isEmpty(selectedHotelRebookTimer) ? selectedHotelRebookTimer : selectedHotelSearchFilters.timestamp,
    });

    dispatch(setHotelBookReqBody(bookingAPIRequest));
    const initiateBookingRequest = {
      requestBody: {
        ...bookingAPIRequest,
      },
    };

    dispatch(initiateHotelBooking(initiateBookingRequest)).then((res) => {
      setIsBookingInProcess(false);
      if (!res.payload) return;

      const updatedBookingAPIRes = {
        ...res.payload,
        tavaBookingId,
      };

      const handleNavigationAndStorage = () => {
        setToSessionStorage(BOOKING_INFORMATION, updatedBookingAPIRes);
        if (
          window.location.pathname.includes(
            `/users/${currentUserInfo?.id}/bookings`
          )
        ) {
          navigate(`/users/${currentUserInfo?.id}/bookings/${tavaBookingId}`);
        } else {
          navigate(`/bookings/${tavaBookingId}`);
        }
        dispatch(setHotelBookRes(updatedBookingAPIRes));
      };

      if (
        isAISearchInitiated &&
        selectedTripOrder.length === 2 &&
        flightSearchQuery
      ) {
        if (selectedTripOrder[1] === FLIGHT) {          
          dispatch(setHotelSearchQuery(EMPTY_OBJECT));
          navigate(`/bookings/${tavaBookingId}/flights?${flightSearchQuery}`);
        } else {
          handleNavigationAndStorage();
        }
      } else {
        handleNavigationAndStorage();
      }
      toast.success("Hotel saved successfully!");
    });    
  };

  const passportValidationSchema = getPassportValidationSchema();
  const panValidationSchema = getPanValidationSchema();

  const baseValidationSchema = {
    profileDetails: Yup.object().shape(getPrimaryPaxValidationSchema(t)),
  };

  const guestValidationSchema = Yup.object().shape({
    ...baseValidationSchema,
    ...(isPassportRequired && passportValidationSchema),
    ...(isPANRequired && !isSamePanForAllAllowed && panValidationSchema.fields),
  });

  const handlePayment = () => {
    const inValidGuestData = checkingGuestValidation(
      guestValidationSchema,
      otherGuests
    )
    const { isValid, handleSubmit, values } = bookingPrimaryDetailsFormRef.current || {};
    if (isUserAgreementsAndCancellationPolicyChecked || !isValid) {
      handleSubmit();
      isValid
      ? verifyGuestCountValidation(values, inValidGuestData)
        : guestDetailsRef.current?.scrollIntoView({ behavior: "smooth" });
    } else {
      setAgreementsSectionHighlighted(true);
      setTimeout(
        () => setAgreementsSectionHighlighted(false),
        ERROR_VISIBILITY_DURATION
      );
    }
  };

  const handleClick = () => {
    const hotelSearchTime = getFromLocalStorage(HOTEL_SEARCH_TIME);
    handlePayment();
    let key = "hotelSearchQuery";
    let value = EMPTY_OBJECT;
    dispatch(setTripStatus({key, value}));
    dispatch(setSearchTimeLog(hotelSearchTime));
    dispatch(setShowTimer({ id: tavaBookingId, showTime: true }));
    dispatch(setShowTimerExpireBanner({ id: tavaBookingId, hotel: false }));
  }
  const isCurrentAndnonRefundableFromDateSame = () => {
    return getCurrentDateTime() === getFormattedDate(freeCancellationBeforeDate) ? true : false;
  }
  const isChargeThreeMakingNonRefundable=(charge, fromDate)=>{
    const res = (charge - noOfNights) == ZERO
    if(res) nonRefundableFromDate=fromDate;
    return res;
  }
  const isChargeOneValid = (charge,fromDate) => {
    const isChargeValid= (hotelTotalFare - charge) > ZERO
    if(!isChargeValid){
     nonRefundableFromDate=fromDate;
   }
   return isChargeValid;
}
  const isPartialRefundSectionValid = (charge, chargeType, fromDate) => (charge && (chargeType == NIGHTS ? !isChargeThreeMakingNonRefundable(charge, fromDate) : true) && (chargeType == AMOUNT ? isChargeOneValid(charge, fromDate) : true));
  const nightLabel = noOfNights > ONE ? NIGHT + "s" : NIGHT;
  const guestLabel = getGuestsCount(roomGuests) > ONE ? GUEST + "s" : GUEST;
  const roomLabel = noOfRooms > ONE ? ROOM + "s" : ROOM;

  return (
    <div className='flex items-center min-h-[80vh]'>
      <Helmet>
        <title>{brandName | "Review Hotel"}</title>
      </Helmet>
      <div className='w-full bg-white'>
        <Spinner
          name={VERIFY_HOTEL_PRICE_POLICY}
          showSkeleton={true}
          loaderComponent={<TBOAPICallSkeleton />}
        ></Spinner>
        <header className='relative bg-primary-800'>
          <div className='absolute w-full h-full top-0 left-0 bg-gradient-to-r from-contrast-900/0 to-primary-900/50'></div>
          <div className='container px-8 pt-8 pb-16 mx-auto relative'>
            <h4 className='text-xl text-white mb-1 font-bold'>
              Review your Booking
            </h4>
          </div>
        </header>
        <main className='pb-16 relative'>
          <div className='container px-4 md:px-8 mx-auto -mt-8'>
            <div className='grid grid-cols-12 gap-3 md:gap-8 relative'>
              <div className='col-span-12 xl:col-span-8 flex flex-col gap-4'>
                <div className='flex flex-col gap-8'>
                  <div className='bg-white rounded-lg border border-dashed border-contrast-300 shadow-sm'>
                    <div className='flex gap-6 px-4 py-3 border-b border-dashed border-contrast-300 justify-between'>
                      <div className='flex-1'>
                        <h4 className='text-contrast-900 font-bold text-base mb-2'>
                          {hotelName}
                        </h4>
                        <p className='text-contrast-500 text-xs mb-2'>
                          {getFormattedAddress(address)}
                        </p>
                        <div className='flex gap-2 items-center mb-2'>
                          <div className='flex items-center gap-0.5'>
                            {renderStars(starRating)}
                          </div>
                        </div>
                      </div>
                      {hotelImages && <div className='shrink-0'>
                        <img
                          src={hotelImages?.[ZERO]}
                          alt={hotelName}
                          className='w-full rounded-lg h-28'
                        />
                      </div>}
                    </div>
                    <div className='p-4 bg-primary-100/30'>
                      <div className='grid grid-cols-12 gap-4'>
                        <div className='col-span-12 md:col-span-8 2xl:col-span-8'>
                          <div className='flex gap-6 justify-between items-center'>
                            <div>
                              <span className='text-contrast-500 text-xs font-medium'>
                                Check In
                              </span>
                              <h5 className='text-sm md:text-sm  text-contrast-900 mb-1'>
                                {checkIn ? (
                                   <strong className="text-sm"> {checkIn} </strong>
                                  // <>
                                  //   {checkIn.day}{" "}
                                  //   <strong className='text-sm'>
                                  //     {`${checkIn.date} ${checkIn.month} `}
                                  //   </strong>
                                  //   {checkIn.year}{" "}
                                  //   {checkinTime && <span>-</span>}{" "}
                                  //   {checkinTime}
                                  // </>
                                ) : (
                                  "..."
                                )}
                              </h5>
                            </div>
                            <div className='icon shrink-0'>
                              <div className='bg-contrast-200 font-medium text-contrast-700 rounded-2xl px-3 py-0.5 text-[10px]'>
                                {noOfNights} {nightLabel}
                              </div>
                            </div>
                            <div>
                              <span className='text-contrast-500 text-xs font-medium'>
                                Check Out
                              </span>
                              <h5 className='text-xs md:text-sm  text-contrast-900 mb-1'>
                                {checkOut ? (
                                  <strong className="text-sm"> {checkOut} </strong>
                                  // <>
                                  //   {checkOut.day}{" "}
                                  //   <strong className='text-sm'>
                                  //     {`${checkOut.date} ${checkOut.month} `}
                                  //   </strong>
                                  //   {checkOut.year}{" "}
                                  //   {checkoutTime && <span>-</span>}{" "}
                                  //   {checkoutTime}
                                  // </>
                                ) : (
                                  "..."
                                )}
                              </h5>
                            </div>
                          </div>
                        </div>
                        <div className='col-span-12 md:col-span-4 2xl:col-span-4 flex gap-4'>
                          <div className='flex-1 text-end'>
                            <h5 className='text-xs text-contrast-800 font-bold mb-1'>
                              {noOfNights} {nightLabel} |{" "}
                              {getGuestsCount(roomGuests)} {guestLabel} |{" "}
                              {noOfRooms} {roomLabel}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='px-4 py-4 border-t border-dashed border-contrast-300'>
                      <div className='flex items-center justify-between mb-3'>
                        <div>
                          <h4 className='text-base font-bold text-contrast-900 flex-1'>
                            {roomAndBedType(selectedRoomDetails)[ZERO]}
                          </h4>
                        </div>
                        {!isEmpty(amenityDetails) && (
                          <button
                            onClick={() =>
                              dispatch(setSelectedModal(AMENITIES_MODAL))
                            }
                            className='font-semibold text-primary-700 hover:opacity-90 text-xs'
                          >
                            See Amenities
                          </button>
                        )}
                      </div>
                      <ul className='flex flex-col gap-1 font-medium mb-3'>
                        {/* <li className='flex items-center gap-2'>   //unneeded text block
                          <div className='p-0.5 bg-contrast-500 rounded-full'></div>
                          <span className='text-xs text-contrast-500'>
                            Room with free cancellation
                          </span>
                        </li> */}
                        {isCurrentAndnonRefundableFromDateSame() ? "" :
                          <li className='flex items-center gap-2'>
                            <RenderSVG
                              Svg={Check}
                              width='14'
                              className='text-green-700'
                            />
                            <span className='text-xs text-green-700'>
                              Free cancellation before{" "}
                              {getFormattedLongDate(freeCancellationBeforeDate, {
                                day: "numeric",
                                month: "long",
                                year: "numeric",
                              })}.
                            </span>
                          </li>
                        }
                        {partialRefundable.map(
                          (
                            { charge, chargeType, fromDate, toDate, currency },
                            index
                          ) => {
                            const refundability = `${getCancellationCharges(
                              charge,
                              chargeType,
                              currency
                            )} Charged amount will be debited if `;
                            if (isPartialRefundSectionValid(charge, chargeType, fromDate)) {
                              return (
                                <li key={index}>
                                  <div className='flex items-center'>
                                    <RenderSVG
                                      Svg={Check}
                                      width='14'
                                      className='text-yellow-700 inline-block'
                                    />
                                    {fromDate.split('T')[0] !== toDate.split('T')[0] ? (<span className='ml-2 text-xs text-yellow-700'>
                                      {refundability}
                                      cancelled between{" "}
                                      {getFormattedLongDate(fromDate, {
                                        day: "numeric",
                                        month: "long",
                                        year: "numeric",
                                      })}{" "}
                                      to{" "}
                                      {getFormattedLongDate(toDate, {
                                        day: "numeric",
                                        month: "long",
                                        year: "numeric",
                                      })}.
                                    </span>) : (<span className='ml-2 text-xs text-yellow-700'>
                                      {refundability}
                                      cancelled on{" "}
                                      {getFormattedLongDate(fromDate, {
                                        day: "numeric",
                                        month: "long",
                                        year: "numeric",
                                      })}
                                    </span>)}
                                  </div>
                                </li>
                              );
                            }
                          }
                        )}

                        <li className='flex items-center gap-2'>
                          <RenderSVG
                            Svg={CrossRed}
                            width='8'
                            className='text-red-700 ml-0.5'
                          />
                          {isCurrentAndnonRefundableFromDateSame() ?
                            <span className='text-xs text-red-700 ml-1'>
                              No Refund available.
                            </span>
                            :
                            <span className='text-xs text-red-700 ml-1'>
                              No Refund from{" "}
                              {getFormattedLongDate(nonRefundableFromDate, {
                                day: "numeric",
                                month: "long",
                                year: "numeric",
                              })}{" "}onwards.
                            </span>
                          }
                        </li>
                      </ul>
                      <div className='hidden xs:block'>
                        <CancellationPolicyInfo
                          cancellationPolicies={cancellationPolicies}
                          noOfNights={noOfNights}
                          hotelTotalFare={hotelTotalFare}
                        />
                      </div>
                    </div>

                    <div className='p-4 bg-amber-50'>
                      <div className='flex items-center mb-3'>
                        <h4 className='text-base font-bold text-amber-500 flex-1 justify-between'>
                          Important Information
                        </h4>
                        {(!activeSpinners.includes(
                          VERIFY_HOTEL_PRICE_POLICY
                        )) && (hotelPolicyDetail !== EMPTY_STRING) && (
                          <button
                            className='text-[16px] text-blue-600 text font-semibold disabled:cursor-not-allowed disabled:opacity-50'
                            onClick={() => {
                              setShowHotelPolicyModal(true);
                            }}
                            disabled={activeSpinners.includes(
                              VERIFY_HOTEL_PRICE_POLICY
                            )}
                          >
                            View All
                          </button>
                        )}
                      </div>
                      <ul className='list-disc text-contrast-600 text-xs pl-5 flex flex-col gap-1'>
                        {hotelGuidelines.map(({ id, data }) => (
                          <li key={id} className='list-item'>
                            {data}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <GuestDetailsSection
                  bookingPrimaryDetailsFormRef={bookingPrimaryDetailsFormRef}
                  guestDetailsRef={guestDetailsRef}
                  isPANRequired={isPANRequired}
                  isPassportRequired={isPassportRequired}
                  isSamePanForAllAllowed={isSamePanForAllAllowed}
                  areAllGuestDetailsRequired={areAllGuestDetailsRequired}
                />
                {REACT_APP_HOTEL_PAY_LATER === "true" && toShowNonVoucherBooking && (
                  <PaymentType
                    handleOptionChange={handleOptionChange}
                    voucherBookingType={voucherBookingType}
                    cancellationPolicies={cancellationPolicies}
                    toShowNonVoucherBooking={toShowNonVoucherBooking}
                  />
                )}
                <div>
                  <div className={classNames("flex flex-row items-center mb-2 ", {
                    "border-2 error-animate bg-red-100/20 p-1.5":
                      agreementsSectionHighlighted,
                  })}
                  >
                    <input
                      className="w-5 h-5 mr-3"
                      type='checkbox'
                      checked={isUserAgreementsAndCancellationPolicyChecked}
                      onChange={() => {
                        setIsUserAgreementsAndCancellationPolicyChecked(!isUserAgreementsAndCancellationPolicyChecked)
                      }}
                    />
                    <p className='text-xs'>
                      I agree to Tavatrip's{" "}
                      <button
                        className='text-primary-700'
                        onClick={() => window.open(TERMS_AND_CONDITION, "_blank")}
                      >
                        User Agreement
                      </button>{" "}
                      and{" "}
                      <button
                        className='text-primary-700'
                        onClick={() =>
                          window.open(CANCELLATION_AND_REFUND_POLICY, "_blank")
                        }
                      >
                        Cancellation Policy
                      </button>
                    </p>
                  </div>
                  <div className='flex md:justify-start'>
                    <button
                      className={classNames(
                        "py-3 px-4 rounded-md bg-primary-600 hover:bg-primary-700 active:bg-primary-600 shadow-sm text-sm text-white font-medium w-52 h-11 disabled:cursor-not-allowed disabled:opacity-50",
                        {
                          "opacity-50": isSpinnerActive,
                        }
                      )}
                      onClick={handleClick}
                      disabled={activeSpinners.some(
                        (spinnerName) =>
                          spinnerName === VERIFY_HOTEL_PRICE_POLICY ||
                          spinnerName === HOTEL_BOOKING
                      )}
                    >
                      <Spinner
                        name={[HOTEL_BOOKING, FETCH_FLIGHTS_RESULTS, VERIFY_HOTEL_PRICE_POLICY]}
                        setIsSpinnerActive={setIsSpinnerActive}
                      >
                        {getPaymentButtonText()}
                      </Spinner>
                    </button>
                  </div>
                  <ErrorMessage errorMessage={errorMessage} />
                </div>
              </div>
              <div className='col-span-12 xl:col-span-4 sticky top-0'>
                {voucherBookingType === VOUCHER_BOOKING && <PriceBreakdownSection hotelTotalFare={valueRef}/>}
                <PromoCodeCard isHotel={true} />
              </div>
            </div>
          </div>
        </main>
      </div>
      <HotelDetailsChangeModal
        hasPriceChanged={isPriceChanged}
        havePoliciesChanged={
          isHotelPolicyChanged || isCancellationPolicyChanged
        }
        updatedCancellationPolicy={hotelPolicyDetail}
        updatedPrice={get(
          hotelInfo,
          "hotelRoomsDetails.0.price.publishedPriceRoundedOff"
        )}
      />
      <div>
        {showHotelPolicytModal &&
          (<HotelPolicyModal
            handleClose={handleClose} />)}
      </div>
    </div>
  );
};

export default HotelReview;
