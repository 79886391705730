import Trips from "./Trips";
import * as tripsActions from "./trips.actions";
import * as selectors from "./trips.selector";
import * as slice from "./trips.reducer";

export const {
  fetchTrips,
  createTrip,
  fetchPaymentProviders,
  initiatePayment,
  initiateBookCallback,
  fetchTripsById,
  downloadEticket,
  updatePaymentStatus,
  deleteChildByBookingId,
  updateHotelVoucherStatus,
  checkHotelRebookStatus,
  checkFlightRebookStatus,
} = tripsActions;

export const {
  selectSelectedTrip,
  selectSelectedCategoryTrips,
  selectPaymentProviders,
  selectPaymentAPIResponse,
  selectBookCallbackResponse,
  selectSelectedTripId,
  selectTripCategory,
  selectSearchTimeLog,
  selectShowTimer,
  selectShowTimerExpireBanner,
  selectSelectedUserInfo,
  selectSelectedHotelViewMore,
  selectUpdatePaymentStatus,
  selectNewTripDetail,
  selectedHotelVoucherResponse,
  selectFlightRebookDetail,
  selectRebookResponse,
  selectIsFlight,
  selectIsHotel
} = selectors;

export const {
  name,
  reducer,
  actions: {
    setSelectedTrip,
    setSelectedTripId,
    setBookCallbackResponse,
    setTripCategory,
    setSearchTimeLog,
    deleteSearchTimeLog,
    setShowTimer,
    deleteShowTimer,
    setShowTimerExpireBanner,
    deleteShowTimerBanner,
    setSelectedUserInfo,
    setSelectedHotelViewMore,
    setCurrentPage,
    setSearchKey,
    setTripData,
    setSelectedTripDetail,
    setRebookResponse,
    setHotelRebookTimer,
    setFlightRebookDetail,
    setFlightRebookTimestamp,
    setIsFlight,
    setIsHotel
  },
} = slice;

export default Trips;
