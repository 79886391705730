import reducer from "./trips.reducer";

export const selectSlice = (state) => state[reducer.name];

export const selectSelectedCategoryTrips = (state) =>
  selectSlice(state).selectedCategoryTrips;

export const selectSelectedTrip = (state) => selectSlice(state).selectedTrip;

export const selectSelectedTripId = (state) =>
  selectSlice(state).selectedTripId;

export const selectPaymentAPIResponse = (state) =>
  selectSlice(state).paymentAPIResponse;

export const selectBookCallbackResponse = (state) =>
  selectSlice(state).bookCallbackResponse;

export const selectPaymentProviders = (state) =>
  selectSlice(state).paymentProviders;

export const selectTripCategory = (state) => selectSlice(state).tripCategory;

export const selectSearchTimeLog = (state) => selectSlice(state).searchTimeLog;

export const selectShowTimer = (state) => selectSlice(state).showTimer;

export const selectDownloadEticket = (state) =>
  selectSlice(state).downloadEticket;

export const selectShowTimerExpireBanner = (state) => selectSlice(state).showTimerExpireBanner;

export const selectSelectedUserInfo = (state) => selectSlice(state).selectedUserInfo

export const selectSelectedHotelViewMore = (state) => selectSlice(state).selectedHotelViewMore;

export const selectCurrentPage = (state) => selectSlice(state).currentPage;

export const selectSearchKey = (state) => selectSlice(state).searchKey;

export const selectUpdatePaymentStatus = (state) => selectSlice(state).searchKey;

export const selectTripDetail = (state) =>selectSlice(state).newTripDetail;

export const selectedHotelVoucherResponse = (state) => selectSlice(state).generateVoucherResponse;

export const selectRebookResponse = (state) => selectSlice(state).rebookResponse;

export const selectHotelRebookTimer = (state)=> selectSlice(state).hotelRebookTimeStamp;

export const selectFlightRebookDetail = (state) => selectSlice(state).flightRebookDetail;

export const selectIsFlight = (state) => selectSlice(state).isFlight;

export const selectIsHotel = (state) => selectSlice(state).isHotel;
