import { getFormattedBaggageWeight } from "./getFormattedBaggageWeight";
import { convertMinutesToHours, formatDurationToTime } from "./getFormattedDuration";
import { FARE_CLASS_CATEGORY, DEFAULT_VALUES } from "../constants";

const { ZERO, EMPTY_OBJECT, EMPTY_STRING } = DEFAULT_VALUES;
const PRICE = "price";

export const getFormattedRebookPriceRequest = (response, EndUserIp, miniFareRules) => {
  const { nextApiReq = EMPTY_OBJECT, ssrResponse = EMPTY_OBJECT, priceResponse = EMPTY_OBJECT } = response || EMPTY_OBJECT;
  const { traceId: traceIdFromSSR = EMPTY_STRING, resultIndex: resultIndexFromSSR = EMPTY_STRING, isLCC: isLLCFromSSR = EMPTY_STRING } = ssrResponse;
  const { data = EMPTY_OBJECT, resultIndex: resultIndexFromAPI = EMPTY_STRING, isLCC: isLCCFromAPI = EMPTY_STRING } = nextApiReq || EMPTY_OBJECT;
  const { TraceId = EMPTY_STRING, EndUserIp: EndUserIPFromAPI = EMPTY_STRING, TokenId = EMPTY_OBJECT } = data;
  const traceId = TraceId || traceIdFromSSR || EMPTY_STRING;
  const endUserIp = EndUserIPFromAPI || EndUserIp || EMPTY_STRING;
  const source = priceResponse?.provider || EMPTY_STRING;
  const resultIndex = resultIndexFromAPI || resultIndexFromSSR || EMPTY_STRING;
  const isLCC = isLCCFromAPI || isLLCFromSSR || false;
  const { fareDetails, travelerPricings, documentsRequired, refund } =
    priceResponse?.flightPriceInfo;
  const { OfferedFare, BaseFare, Tax, TavaMarkup, PublishedFare } = fareDetails;
  const {
    isPanRequiredAtTicket,
    isPanRequiredAtBook,
    isGSTAllowed,
    isGSTMandatory,
    isPassportRequiredAtTicket,
    isPassportRequiredAtBook,
    isPassportFullDetailRequiredAtBook,
  } = documentsRequired;
  const { isRefundable, refundAmount, refundableTax, refundConditions } =
    refund;
  const journeyId = response?.priceResponse?.journeyId;

  const updatedSegments = response?.priceResponse?.flightPriceInfo?.segments.map((segment) => {
    const updatedSegment = segment.map((item) => {
      const { Origin, Destination, Airline, Baggage, Duration, CabinBaggage, CabinClass } = item;

      const baggage = {
        weight: getFormattedBaggageWeight(Baggage),
        cabinBaggage: CabinBaggage,
      };

      const {
        AirportCode: originAirportCode,
        AirportName: originAirportName,
        Terminal: originTerminal,
      } = Origin.Airport;

      const { DepTime: originDepTime } = Origin;
      const [dateOfOrigin, timeOfOrigin] = originDepTime.split("T");
      const formattedOriginTime = timeOfOrigin.slice(0, 5);

      const departure = {
        iataCode: originAirportCode,
        airportName: originAirportName,
        terminal: originTerminal,
        date: dateOfOrigin,
        time: formattedOriginTime,
      };

      const {
        AirportCode: destAirportCode,
        AirportName: destAirportName,
        Terminal: destTerminal,
      } = Destination.Airport;

      const { ArrTime: destDepTime } = Destination;
      const [dateOfArrival, timeOfArrival] = destDepTime.split("T");
      const formattedTime = timeOfArrival.slice(0, 5);

      const arrival = {
        iataCode: destAirportCode,
        airportName: destAirportName,
        terminal: destTerminal,
        date: dateOfArrival,
        time: formattedTime,
      };

      const {
        AirlineCode: carrierCode,
        AirlineName: carrierName,
        FlightNumber: flightNumber,
        OperatingCarrier: operatingCarrierCode,
      } = Airline;

      return {
        departure: departure,
        arrival: arrival,
        carrierCode,
        carrierName,
        flightNumber,
        cabinClass: FARE_CLASS_CATEGORY[CabinClass],
        operatingCarrierCode,
        baggageInfo: {
          checkInBaggage: baggage.weight,
          cabinBaggage: baggage.cabinBaggage,
        },
        totalDuration: Duration
      };
    });

    const totalDuration = updatedSegment.reduce((total, segment) => {
      const segmentDuration = segment.totalDuration;
      return total + segmentDuration;
    }, 0);

    return {
      segments: updatedSegment,
      duration: formatDurationToTime(convertMinutesToHours(totalDuration)),
    };
  });

  const { Baggage, CabinBaggage } =
    response?.priceResponse?.flightPriceInfo?.segments[ZERO][ZERO];

  const baggage = {
    weight: getFormattedBaggageWeight(Baggage),
    cabinBaggage: CabinBaggage,
  };

  const travelerDetails = [];

  const { travelerType, travelersCount, price } = travelerPricings[ZERO];
  const totalPerTraveler = price.grandTotal / travelersCount;
  const basePerTraveler = price.baseFare / travelersCount;
  const taxPerTraveler = Tax / travelersCount;

  for (let i = 1; i <= travelersCount; i++) {
    travelerDetails.push({
      id: i,
      travelerType: travelerType,
      price: {
        totalPrice: totalPerTraveler,
        basePrice: basePerTraveler,
        taxPrice: taxPerTraveler,
      },
    });
  }

  const itineraries = updatedSegments
  const rebookPriceResponse = [
    {
      journeyId,
      source,
      // isReissuanceFlight
      resultIndex,
      isLCC,
      endUserIp,
      traceId,
      tokenId: TokenId,
      price: {
        flightId: resultIndex,
        itineraries,
        price: {
          grandTotal: OfferedFare,
          baseFare: BaseFare,
          taxPrice: Tax,
        },
        travelerDetails,
      },
      //session
      key: PRICE,
      baggage,
      selectedElement: {
        flightId: resultIndex,
        resultIndex: resultIndex,
        isLCC,
        // isReissuanceFlight // TODO: Need to check in case of reissuance flight
        isRefundable,
        refundConditions,
        refundableTax,
        refundAmount,
        isPanRequiredAtTicket,
        isPanRequiredAtBook,
        isGSTAllowed,
        isGSTMandatory,
        isPassportRequiredAtTicket,
        isPassportRequiredAtBook,
        isPassportFullDetailRequiredAtBook,
        price: {
          tavaMarkup: TavaMarkup,
          publishedPrice: PublishedFare,
          totalPrice: OfferedFare,
          taxPrice: Tax,
          basePrice: BaseFare,
          travelerPricing: travelerDetails,
        },
        itineraries,
        baggage,
        source,
        miniFareRules,
        // airlineRemark
        // costPolicyViolation:{"violationType":"MIN","violatingDifference:"1084.81"}
      },
      flightSelectedElement: {
        flightId: resultIndex,
        resultIndex,
        isLCC,
        isRefundable,
        isPanRequiredAtTicket,
        isPanRequiredAtBook,
        isGSTAllowed,
        isGSTMandatory,
        isPassportRequiredAtTicket,
        isPassportRequiredAtBook,
        isPassportFullDetailRequiredAtBook,
        price: {
          tavaMarkup: TavaMarkup,
          publishedPrice: PublishedFare,
          totalPrice: OfferedFare,
          taxPrice: Tax,
          basePrice: BaseFare,
          travelerPricing: travelerDetails,
        },
        itineraries,
        baggage,
        source,
        miniFareRules,
        // airlineRemark
        // costPolicyViolation:{"violationType":"MIN","violatingDifference:"1084.81"}
      },
    },
  ];
  return rebookPriceResponse;
};
