import classNames from "classnames";
import { BOOKING_STATUS_CODES, DEFAULT_VALUES } from "../../../constants";

const { EMPTY_STRING } = DEFAULT_VALUES;
const {
  CONFIRMED,
  FAILED,
  CANCELLED,
  PARTIALLY_CONFIRMED,
  PARTIALLY_CANCELLED,
  PENDING,
} = BOOKING_STATUS_CODES;

const BOOKING_DETAILS = {
  CONFIRMED: {
    status: "Approved",
    Message: "Your Trip booking has been successful",
  },
  FAILED: {
    status: "Failed",
    Message: "Your Trip booking has failed.",
    refundMessage:
      "If payment was deducted, a full refund will be processed within 6-8 working days. Please note that the exact processing time may vary depending on your selected payment method and financial institution.",
  },
  CANCELLED: {
    status: "Cancelled",
    Message: "Your Trip booking has cancelled.",
    refundMessage:
      "You will receive the refund amount for your cancelled booking within 6-8 working days. Please note that the exact processing time may vary depending on your selected payment method and financial institution.",
  },
  PARTIALLY_CONFIRMED: {
    status: "Partially Confirmed",
    Message: "Your Trip booking is partially successful.",
    refundMessage:
      "We regret to inform you that your booking has failed partially. You will receive the refund amount for your failed booking within 6-8 working days. Please note that the exact processing time may vary depending on your selected payment method and financial institution.",
  },
  PARTIALLY_CANCELLED: {
    status: "Partially Cancelled",
    Message: "Your Trip booking is partially cancelled.",
    refundMessage:
      "You will receive the refund amount for your cancelled and failed booking within 6-8 working days. Please note that the exact processing time may vary depending on your selected payment method and financial institution.",
  },
  PENDING: {
    status: "Pending",
    Message:
      "Your Trip cancellation is pending, please check the status again in a while",
  },
};

const Header = ({ id, status ,bookingFailedReason = EMPTY_STRING}) => {
  const headerDetails = BOOKING_DETAILS[status];
  if (!headerDetails) return;
  return (
    <div
      className={classNames("relative print:hidden", {
        "bg-green-200": status === CONFIRMED,
        "bg-orange-200": status === CANCELLED || status === PARTIALLY_CANCELLED,
        "bg-red-200": status === FAILED,
        "bg-yellow-200": status === PARTIALLY_CONFIRMED || status === PENDING,
      })}
    >
      <div
        className={classNames(
          "absolute w-full h-full top-0 left-0 bg-gradient-to-r from-contrast-900/0",
          {
            "to-green-900/50": status === CONFIRMED,
            "to-orange-900/50":
              status === CANCELLED || status === PARTIALLY_CANCELLED,
            "to-red-900/50": status === FAILED,
            "to-yellow-900/50": status === PARTIALLY_CONFIRMED || status === PENDING,
          }
        )}
      ></div>
      <div className='container px-8 pt-12 pb-20 mx-auto relative flex flex-col items-start'>
        <h4 className='text-xl text-contrast-900 mb-1 font-bold'>
          {headerDetails.Message}
        </h4>
        <div className='flex items-center gap-2 mb-7'>
          <p className='text-contrast-600 text-sm'>
            Reference Number
            <span className='mx-1 font-semibold text-contrast-900'>{id}</span>
          </p>

          <span
            className={classNames(
              "font-semibold text-xs px-3 py-1 rounded-3xl",
              {
                "text-green-800 bg-green-100": status === CONFIRMED,
                "text-orange-800 bg-orange-100":
                  status === CANCELLED || status === PARTIALLY_CANCELLED,
                "text-red-800 bg-red-100": status === FAILED,
                "text-yellow-800 bg-yellow-100":
                  status === PARTIALLY_CONFIRMED || status === PENDING,
              }
            )}
          >
            {headerDetails.status}
          </span>
        </div>
        {headerDetails.refundMessage && (
          <div
            className={classNames(
              "w-full text-md bg-white p-4 rounded-md my-1 font-medium",
              {
                "text-green-600": status === CONFIRMED,
                "text-orange-600":
                  status === CANCELLED || status === PARTIALLY_CANCELLED,
                "text-red-600": status === FAILED,
                "text-yellow-600": status === PARTIALLY_CONFIRMED || status === PENDING,
              }
            )}
          >
            {bookingFailedReason && (
              <div className='font-bold '>
                Booking failed reason : {bookingFailedReason}
              </div>
            )}
            {headerDetails.refundMessage}
          </div>
        )}
      </div>
    </div>
  );
};
export default Header;
