import { createSlice } from "@reduxjs/toolkit";
import { get, isEmpty } from "lodash";
import {
  fetchFlights,
  getCalendarDatePrices,
  getIATALocations,
  getHotelLocations,
  getHotels,
} from "./index";
import { handleGroupedFlights, convertKeysToCamelCase } from "../../../helper";
import { getFormattedFlightSearchResponse } from "../../../ResponseMapper";
import { getFormattedFarePricesPerDates } from "../../../ResponseMapper/FareCalendarMapping/getFormattedFareCalendarResponse";
import {
  DEFAULT_VALUES,
  LOCATION_TYPE,
  INITIAL_FLIGHT_COUNT,
  SORTING_HEADERS,
  FLIGHTS_RESULT_TYPE,
  INITIAL_FILTERS,
  INITIAL_SORT_FILTERS,
  DEFAULT_FLIGHT_SEARCH_OPTIONS,
  INITIAL_HOTEL_FILTERS,
  INITIAL_HOTEL_VALUES
} from "../../../constants";
import { getHotelsStaticData } from "./search.actions";
import popularCitiesData from "../../../assets/json/popularHotelLocations.json";

const { EMPTY_ARRAY, EMPTY_OBJECT, GDS, ZERO } = DEFAULT_VALUES;
const { FROM, TO } = LOCATION_TYPE;
const { cheapest } = SORTING_HEADERS;
const { INBOUND_RESULT, OUTBOUND_RESULT } = FLIGHTS_RESULT_TYPE;

const initialState = {
  flights: EMPTY_OBJECT,
  flightsCount: INITIAL_FLIGHT_COUNT,
  filteredFlights: EMPTY_OBJECT,
  activeFilters: INITIAL_FILTERS,
  resetActiveFilters: INITIAL_SORT_FILTERS,
  flightSearchOptions: DEFAULT_FLIGHT_SEARCH_OPTIONS,
  flightTokens: EMPTY_OBJECT,
  searchFilters: EMPTY_OBJECT,
  flightRebookFilters: EMPTY_OBJECT,
  flightsReqBody: EMPTY_OBJECT,
  toIataLocations: EMPTY_ARRAY,
  fromIataLocations: EMPTY_ARRAY,
  farePricesPerDates: EMPTY_OBJECT,
  filteredHotelResult: EMPTY_OBJECT,
  isNonStopSelected: false,
  sortedFlights: EMPTY_OBJECT,
  activeSortOrder: {
    inbound: cheapest.name,
    outbound: cheapest.name,
    packages: cheapest.name,
  },
  reIssuanceFlightData: {
    isReissuance: false,
    flightId: null,
    searchFilters: null,
  },
  // TODO: Need to move to separate hotel redux state
  hotels: EMPTY_OBJECT,
  hotelsStaticData: EMPTY_ARRAY,
  isHotelsLoaded: false,
  isStaticDataLoaded: false,
  searchHotelFilters: null,
  hotelLocations:popularCitiesData,
  filteredHotels: EMPTY_ARRAY,
  hotelsSortingValues: INITIAL_HOTEL_VALUES,
  hotelsActiveFilters: INITIAL_HOTEL_FILTERS,
  resetHotelsActiveFilters: INITIAL_HOTEL_FILTERS,
  hotelsCount: ZERO
};

const isFlightResultsEmpty = (flights) =>
  isEmpty(flights) ||
  (isEmpty(flights.isolated.inbound) &&
    isEmpty(flights.isolated.outbound) &&
    isEmpty(flights.packages));

const slice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setSearchFilters(state, action) {
      state.searchFilters = action.payload || initialState.searchFilters;
    },
    setFlights(state, action) {
      state.flights = action.payload || initialState.flights;
    },
    setHotels(state, action) {
      state.hotels = action.payload || initialState.hotels;
    },
    setHotelsStaticData(state, action) {
      state.hotelsStaticData = action.payload || initialState.hotelsStaticData;
    },
    setSearchHotelFilters(state, action) {
      if (action.payload) state.searchHotelFilters = action.payload;
    },
    setActiveFilters(state, action) {
      state.activeFilters = action.payload || initialState.activeFilters;
    },
    setFilteredHotelResult(state, action) {
      state.filteredHotelResult = action.payload || initialState.filteredHotelResult;
    },
    setFlightSearchOptions(state, action) {
      state.flightSearchOptions =
        action.payload || initialState.flightSearchOptions;
    },
    setFilteredFlights(state, action) {
      state.filteredFlights = action.payload || initialState.filteredFlights;
    },
    setSearch(state, action) {
      if (action.payload) return action.payload;
    },
    setFlightSearchRequest(state, action){
      state.flightsReqBody = action.payload || initialState.flightsReqBody;
    },
    resetFlights(state) {
      state.flights = initialState.flights;
      state.flightsCount = initialState.flightsCount;
      state.flightTokens = initialState.flightTokens;
      state.filteredFlights = initialState.filteredFlights;
      state.activeSortOrder = initialState.activeSortOrder;
      state.reIssuanceFlightData = initialState.reIssuanceFlightData;
    },
    resetHotelSearchResult(state) {
      state.hotels = initialState.hotels;
      state.hotelsStaticData = initialState.hotelsStaticData;
      state.searchHotelFilters = initialState.searchHotelFilters;
      state.hotelsCount = initialState.hotelsCount;
      state.filteredHotels = initialState.filteredHotels;
    },
    resetActiveFilters(state) {
      state.activeFilters.packages = initialState.resetActiveFilters;
      state.activeFilters.isolated.inbound = initialState.resetActiveFilters;
      state.activeFilters.isolated.outbound = initialState.resetActiveFilters;
    },
    setFlightsCount(state, action) {
      state.flightsCount = action.payload || initialState.flightsCount;
    },
    setIsNonStopSelected(state, action) {
      state.isNonStopSelected =
        action.payload || initialState.isNonStopSelected;
    },
    setSortedFlights(state, action) {
      state.sortedFlights = action.payload;
    },
    setActiveSortOrder(state, action) {
      const { activeSort, type } = action.payload;
      switch (type) {
        case INBOUND_RESULT:
          state.activeSortOrder.inbound = activeSort;
          break;
        case OUTBOUND_RESULT:
          state.activeSortOrder.outbound = activeSort;
          break;
        default:
          state.activeSortOrder.packages = activeSort;
          break;
      }
    },
    setReIssuanceFlightData(state, action) {
      state.reIssuanceFlightData = {
        ...state.reIssuanceFlightData,
        ...action.payload,
      };
    },
    setFlightRebookFilters(state,action){
      state.flightRebookFilters = action.payload;
    },
    setHotelsActiveFilters(state, action){
      state.hotelsActiveFilters = action.payload || initialState.hotelsActiveFilters;
    },
    setFilteredHotels(state, action){
      state.filteredHotels = action.payload || initialState.filteredHotels;
    },
    setHotelsSortingValues(state, action){
      state.hotelsSortingValues = action.payload || initialState.hotelsSortingValues;
    },
    setHotelsCount(state, action) {
      state.hotelsCount = action.payload || initialState.hotelsCount;
    },
    setIsHotelsLoaded(state, action) {
      state.isHotelsLoaded = action.payload || initialState.isHotelsLoaded;
    },
    setIsStaticDataLoaded(state, action) {
      state.isStaticDataLoaded = action.payload || initialState.isStaticDataLoaded;
    },
    resetHotelsActiveFilters(state) {
      state.resetHotelsActiveFilters = initialState.resetHotelsActiveFilters;
    },
    setHotelLocations(state, action) {
      state.hotelLocations =  action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFlights.fulfilled, (state, action) => {
        const travelType = get(action, "meta.arg.body.travelType", "domestic");
        if (!action.payload) return;
        const formattedTBOFlightsResult = getFormattedFlightSearchResponse(
          state.flights,
          state.flightsCount,
          action.payload,
          state.searchFilters.tripType,
          travelType,
          state.searchFilters.fareType
        );
        const { flights, count, tokenId, traceId } = formattedTBOFlightsResult;
        const groupedFlights = handleGroupedFlights(flights);
        const source = get(action, "meta.arg.body.sources", []);
        const isExistingFlightsEmpty = isFlightResultsEmpty(state.flights);
        const isReceivedFlightsEmpty = isFlightResultsEmpty(groupedFlights);

        const nonGDSCondition =
          !source && (isExistingFlightsEmpty || !isReceivedFlightsEmpty);
        const gdsCondition = source?.includes(GDS) && isExistingFlightsEmpty;

        if (nonGDSCondition || gdsCondition) {
          state.flights = groupedFlights;
          state.filteredFlights = groupedFlights;
          state.flightTokens = { traceId, tokenId };
          state.flightsCount = { ...state.flightsCount, ...count };
        }
      })
      .addCase(getHotels.fulfilled, (state, action) => {
        if (!action.payload) return;
        state.hotels = action.payload;
        state.isHotelsLoaded = true;

        if (state.isStaticDataLoaded) {
          if (
            state.hotels &&
            state.hotels.hotelResults
          ) {
            const staticDataMap = {};
            state.hotelsStaticData?.forEach((staticHotel) => {
              if (staticHotel?.hotelCode) {
                staticDataMap[staticHotel.hotelCode] = staticHotel;
              }
            });

            const updatedHotelResults =
              state.hotels?.hotelResults.map((hotel) => {
                const staticData = staticDataMap[hotel.hotelCode];
                if (staticData) return {...hotel, ...staticData}
                else return hotel;
              }).filter((hotel) => hotel?.hotelName && !isEmpty(hotel?.hotelAddress));
            state.hotels.hotelResults = updatedHotelResults;
          }
        }
      })
      .addCase(getHotelsStaticData.fulfilled, (state, action) => {
        if (!action.payload) return;
        state.hotelsStaticData = action.payload;

        state.isStaticDataLoaded = true;

        if (state.isHotelsLoaded) {
          if (
            state.hotels &&
            state.hotels.hotelResults
          ) {
            const staticDataMap = {};
            state.hotelsStaticData?.forEach((staticHotel) => {
              if (staticHotel?.hotelCode) {
                staticDataMap[staticHotel.hotelCode] = staticHotel;
              }
            });

            const updatedHotelResults =
              state.hotels?.hotelResults.map((hotel) => {
                const staticData = staticDataMap[hotel.hotelCode];

                if (staticData) return {...hotel, ...staticData};
                else return hotel;
              }).filter((hotel) => hotel?.hotelName && !isEmpty(hotel?.hotelAddress));;
            state.hotels.hotelResults = updatedHotelResults;
          }
        }
      })
      .addCase(getIATALocations.fulfilled, (state, action) => {
        if (!action.payload) return;
        if (action.payload.type === FROM)
          state.fromIataLocations = action.payload;
        else if (action.payload.type === TO)
          state.toIataLocations = action.payload;
        else {
          state.fromIataLocations = action.payload;
          state.toIataLocations = action.payload;
        }
      })
      .addCase(getCalendarDatePrices.fulfilled, (state, action) => {
        if (!action.payload) return;
        state.farePricesPerDates = getFormattedFarePricesPerDates(
          action.payload
        );
      })
      .addCase(getHotelLocations.fulfilled, (state, action) => {
        if (!action.payload) return;
        const transformedPayload = convertKeysToCamelCase(get(action, "payload.hits", []));
        state.hotelLocations = transformedPayload;
      });
  },
});

export default slice;

export const { name, reducer, actions } = slice;
