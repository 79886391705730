import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RenderSVG, Plus, Cross, InfoIcon } from "../../assets/icons";
import { actions as hotelBookActions } from "../HotelBooking/hotelBooking.reducer";
import { selectHotelSearchFilters } from "../../components/organisms/Search";
import { selectOtherGuests } from "../HotelBooking";
import HotelGuestModal from "./HotelGuestModal";
import BookingPrimaryDetails from "./BookingPrimaryDetails";
import { DEFAULT_VALUES, MESSAGES } from "../../constants";
import { mapOtherGuestsToTravelers } from "../../helper";
import { selectSelectedTrip } from "../Booking/Trips";
import { Tooltip } from "react-tooltip";
import { isEmpty, get } from "lodash";
import Asterisk from "../../components/atoms/Asterisk";

const { PAN_NAME_MSG } = MESSAGES;
const { ZERO, ONE } = DEFAULT_VALUES;
const { setOtherGuests } = hotelBookActions;

const GuestDetailsSection = ({
  bookingPrimaryDetailsFormRef,
  guestDetailsRef,
  isPANRequired,
  isPassportRequired,
  isSamePanForAllAllowed,
  areAllGuestDetailsRequired,
}) => {
  const dispatch = useDispatch();
  
  const searchFilters = useSelector(selectHotelSearchFilters);
  const otherGuests = useSelector(selectOtherGuests);
  const tripDetails = useSelector(selectSelectedTrip);

  const [showGuestModal, setShowGuestModal] = useState(false);
  const [selectedRoomIndex, setSelectedRoomIndex] = useState(0);
  const [guestIdToBeEdited, setGuestIdToBeEdited] = useState(null);
  const [shouldConvertToChild, setShouldConvertToChild] = useState(true);
  const [editingGuestValues, setEditingGuestValues] = useState({});
  const [draggedGuestId, setDraggedGuestId] = useState(null);
  const [showInfoMessage, setShowInfoMessage] = useState(false);
  const [isDragging, setIsDragging] = useState(false);

  const hotelRebookSearchRequest = get(
    tripDetails,
    "metadata.hotelRebookData.hotelSearchRequest",
    {}
  );

  const roomGuestsFromFilters = get(searchFilters, 'roomGuests', []);
  const roomGuestsFromSearchRequest = get(hotelRebookSearchRequest, 'roomGuests', []);

  const roomGuests =
    roomGuestsFromFilters.length > 0
      ? roomGuestsFromFilters
      : roomGuestsFromSearchRequest;
  const guestCount = roomGuests.reduce(
    (acc, guestObject) => {
      acc.numberOfAdults += guestObject["noOfAdults"];
      acc.numberOfChildren += guestObject["noOfChild"];
      return acc;
    },
    { numberOfAdults: ZERO, numberOfChildren: ZERO }
  );
  const guestDetails = {
    guestIdToBeEdited,
    setGuestIdToBeEdited,
    shouldConvertToChild,
    setShouldConvertToChild,
    editingGuestValues,
    setEditingGuestValues,
  };
  const documentsRequirements = {
    isPANRequired,
    isPassportRequired,
    isSamePanForAllAllowed,
  };

  const totalNoOfAdults = guestCount.numberOfAdults;
  const totalNoOfChildren = guestCount.numberOfChildren;
  const totalGuests = totalNoOfAdults + totalNoOfChildren;

  const handleRemoveSelectedGuest = (guestId) => {
    const updatedGuestsArray = otherGuests.map((guest) => {
      setShowGuestModal(false);
      if (guest.id === guestId) {
        return {
          ...guest,
          isSelected: false,
          roomIndex: null,
        };
      }
      return guest;
    });
    dispatch(setOtherGuests(updatedGuestsArray));
  };

  const handleClose = () => setShowGuestModal(false);

  const getRequiredNoOfGuests = (roomIndex) => {
    const adults = roomGuests[roomIndex].noOfAdults;
    const children = roomGuests[roomIndex].noOfChild;

    const adultSuffix = `${adults > ONE ? "Adults" : "Adult"}`;
    const childSuffix = `${children > ONE ? "Children" : "Child"}`;
    const adultsCountTag = adults ? `${adults} ${adultSuffix}` : "";
    const childrenCountTag = children ? `${children} ${childSuffix}` : "";
    const separator = adultsCountTag && childrenCountTag ? ", " : "";

    return `${adultsCountTag}${separator}${childrenCountTag}`;
  };

  const handleGuestEdit = (guestId) => {
    setShowGuestModal(true);
    const searchedChildrenCount = roomGuests[selectedRoomIndex]?.noOfChild;
    const guestToEdit = otherGuests.find((guest) => guest.id === guestId);
    const { isChild } = guestToEdit.profileDetails || {};
    if (!searchedChildrenCount) {
      setShouldConvertToChild(isChild);
    }
    setGuestIdToBeEdited(guestId);
    setEditingGuestValues(guestToEdit);
  };

  const handleAddGuest = (index) => {
    setSelectedRoomIndex(index);
    setGuestIdToBeEdited(null);
    setEditingGuestValues(null);
    setShouldConvertToChild(true);
    setShowGuestModal(true);
  };

  const handleDragStart = (e, guestId) => {
    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer.setData("text/plain", guestId);
    setDraggedGuestId(guestId);
    setIsDragging(true);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = "move";
    setIsDragging(false);
  };
  const handleDrop = (e, roomIndex) => {
    e.preventDefault();
    const updatedGuestsArray = otherGuests.map((guest) => {
      if (guest.id === draggedGuestId) {
        return { ...guest, roomIndex };
      }
      return guest;
    });
    dispatch(setOtherGuests(updatedGuestsArray));
    setDraggedGuestId(null); 
    setIsDragging(false);
  };

  const handleInfoClick = () => setShowInfoMessage((prev) => !prev);
  

  useEffect(() => {
    isEmpty(otherGuests) && tripDetails && 
      dispatch(
        setOtherGuests(
          mapOtherGuestsToTravelers(
            tripDetails?.passengers,
            roomGuests.length,
            roomGuests
          )
        )
      );
  }, [tripDetails]);

  return (
    <div
      className='bg-white rounded-xl border border-contrast-300'
      ref={guestDetailsRef}
    >
      <div className='px-3 md:px-6 py-4 flex items-center border-b border-contrast-300 bg-contrast-50'>
        <h4 className='text-base font-bold text-contrast-900 flex-1'>
          Guest Details&nbsp;&nbsp;
          {isPANRequired && (
            <span className='text-red-500 font-semibold text-sm'>
              <Asterisk />
              {PAN_NAME_MSG}
            </span>
          )}
        </h4>
        {roomGuests.length > ONE && <div
          className='w-5 h-5'
          data-tooltip-id='room message'
          data-tooltip-place='top'
          data-tooltip-content='You can drag and drop guests to move them between rooms.'
        >
          <RenderSVG
            Svg={InfoIcon}
            className='relative min-w-[20px] cursor-pointer'
            alt='info-icon'
            onClick={handleInfoClick}
          />
          <Tooltip
            id='room message'
            className='!w-56 !sm:w-72 !bg-primary-900 !rounded-lg !z-50'
          />
        </div>}
      </div>
      <div className='px-3 md:px-6 pt-3'>
        {areAllGuestDetailsRequired && (
          <div className='py-2'>
            {roomGuests.map((_, index) => {
              const shouldShowSection =
                roomGuests[index]?.noOfAdults >= ONE ||
                roomGuests[index]?.noOfChild > ZERO;

              return (
                <div
                  key={index}
                  className='mb-6'
                  onDragOver={handleDragOver}
                  onDrop={(e) => handleDrop(e, index)}
                >
                  {shouldShowSection && (
                    <div>
                      <div className='font-semibold mb-2'>
                        Room {index + ONE}
                        <span className='font-light text-sm text-contrast-800 ml-1'>
                          ({getRequiredNoOfGuests(index)})
                        </span>
                      </div>
                      <div className='flex-wrap flex gap-3'>
                        <div className='flex-wrap flex gap-3'>
                          {otherGuests
                            .filter(
                              ({ roomIndex, isSelected }) =>
                                isSelected && roomIndex === index
                            )
                            .map(
                              ({
                                roomIndex,
                                id,
                                profileDetails: {
                                  firstName,
                                  middleName,
                                  lastName,
                                } = {},
                              }) =>
                                roomIndex === index && (
                                  <div
                                    key={id}
                                    draggable
                                    onDragStart={(e) => handleDragStart(e, id)}
                                    onDragOver={handleDragOver}
                                    onClick={() => {
                                      if (!isDragging) {
                                        setSelectedRoomIndex(index);
                                        handleGuestEdit(id);
                                      }
                                    }}
                                    className='bg-contrast-100 flex items-center gap-2 text-sm px-3 py-1 hover:cursor-pointer rounded-2xl'
                                  >
                                    <span>{`${firstName} ${middleName} ${lastName}`}</span>
                                    <button
                                      className='opacity-80 hover:opacity-100'
                                      onClick={(e) => {
                                        if (!isDragging) {
                                          e.stopPropagation();
                                          handleRemoveSelectedGuest(id);
                                        }
                                      }}
                                    >
                                      <RenderSVG
                                        Svg={Cross}
                                        width='14'
                                        className='text-contrast-900'
                                      />
                                    </button>
                                  </div>
                                )
                            )}
                          <button
                            className='py-2 px-4 inline-flex items-center justify-center gap-2 rounded-md bg-primary-100 hover:bg-primary-200 active:bg-primary-100 border border-primary-100 shadow-sm text-sm text-primary-700 font-medium'
                            onClick={() => handleAddGuest(index)}
                          >
                            <RenderSVG
                              Svg={Plus}
                              width='14'
                              className='text-contrast-900'
                            />
                            Add Guest
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
            {showGuestModal && (
              <HotelGuestModal
                handleClose={handleClose}
                documentsRequirements={documentsRequirements}
                roomIndex={selectedRoomIndex}
                totalNoOfAdults={totalNoOfAdults}
                totalNoOfChildren={totalNoOfChildren}
                handleGuestEdit={handleGuestEdit}
                guestDetails={guestDetails}
              />
            )}
            <BookingPrimaryDetails
              bookingPrimaryDetailsFormRef={bookingPrimaryDetailsFormRef}
              isPANRequired={isPANRequired}
              isPassportRequired={isPassportRequired}
              isSamePanForAllAllowed={isSamePanForAllAllowed}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default GuestDetailsSection;
