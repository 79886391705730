import { Formik, Form, Field } from "formik";
import { useState, useRef } from "react";
import { readString } from "react-papaparse";
import * as Yup from "yup";
import Modal from "../../Modal";
import classNames from "classnames";
import { InputField } from "../../../molecules";
import Spinner from "../../Spinner";
import {
  ROLE_LABEL,
  ENVIRONMENTS,
  MODAL_SIZE,
  DEFAULT_VALUES,
  INVITE_TEMPLATE_URL,
  REGEX
} from "../../../../constants";
import {
  RenderSVG,
  Cross,
  Plus,
  Trash,
  AddFolderIcon,
  CsvIcon,
} from "../../../../assets/icons";
import { isEmpty } from "lodash";
import Asterisk from "../../../atoms/Asterisk";

const { MD, XL } = MODAL_SIZE;
const { NAME, EMAIL_REGEX_WITH_WITESPACES, CAMEL_CASE_REGEX, DELEMITER_AND_NEXT_CHAR_REGEX, START_WITH_UPPER_CASE_REGEX, PASCAL_CASE_REGEX } = REGEX;
const { ZERO, ONE, TWO, DEFAULT_PRIMARY_TEXT, EMPTY_STRING } = DEFAULT_VALUES;
const { ADMIN, USER } = ROLE_LABEL;
const shouldAllowWallet = process.env.REACT_APP_ENV === ENVIRONMENTS.PRODUCTION;
const tableHeaders = ["First Name", "Last Name", "Email", "Admin", "Wallet Enabled", ""];
const FIRST_NAME = "First Name";
const EMAIL = "Email";

const validationSchema = Yup.array().of(
  Yup.object().shape({
    firstName: Yup.string()
      .trim("First Name Required")
      .matches(NAME, "Name must contain letters only")
      .required("First Name Required")
      .min(1, "First name must be at least 1 letters long")
      .max(100, "First name cannot exceed 100 characters."),
    middleName: Yup.string()
      ?.trim()
      .matches(NAME, "Name must contain letters only")
      .max(100, "Middle name cannot exceed 100 characters."),
    lastName: Yup.string()
      .trim("Last Name Required")
      .required("Last Name Required")
      .min(1, "Last name must be at least 1 letters long")
      .max(100, "Last name cannot exceed 100 characters.")
      .matches(NAME, "Name must contain letters only"),
    email: Yup.string()
      ?.trim()
      .required("Email Required")
      .max(100, "User email cannot exceed 100 characters.")
      .email("Invalid email format"),
    roleId: Yup.string().required("Select Role for user"),
  })
);

const getFormattedCsvDetails = (arr) => {
  return arr.map((obj) => {
    return Object.keys(obj).reduce((acc, key) => {
      const trimmedKey = key?.trim();
      const isCamelCase = CAMEL_CASE_REGEX.test( trimmedKey);
      const isPascalCase = PASCAL_CASE_REGEX.test(trimmedKey);
      const camelCaseKey = 
      isCamelCase 
      ? trimmedKey 
      : isPascalCase 
      ? ( trimmedKey.charAt(ZERO).toLowerCase() + trimmedKey.slice(ONE) )
      : trimmedKey
        .toLowerCase() 
        .replace(DELEMITER_AND_NEXT_CHAR_REGEX, (_, char) => char ? char.toUpperCase() : EMPTY_STRING) 
        .replace(START_WITH_UPPER_CASE_REGEX, (char) => char.toLowerCase()); 
      acc[camelCaseKey] = obj[key] || EMPTY_STRING; 
      return acc;
    }, {});
  });
};

const getTouchedFields = () => ({
  firstName: true,
  lastName: true,
  email: true,
  roleId: true,
  ...(shouldAllowWallet && {
    isWalletEnabled: true,
  }),
});

const AddUserModal = ({
  title = EMPTY_STRING,
  primaryButtonText = DEFAULT_PRIMARY_TEXT,
  handleCloseModal,
  handleSubmit,
  savedInitialValues,
  availableRoles,
  availableStatuses,
  spinnerName,
}) => {
  const fileInputRef = useRef(null);
  const [isSpinnerActive, setIsSpinnerActive] = useState(true);
  const [showFileUpload, setShowFileUpload] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [uploadedFile, setUploadedFile] = useState();
  const [csvData, setCsvData] = useState([]);

  const getAdminOrUserId = (roleName) =>
    availableRoles.find(({ label }) => roleName === label.toLowerCase())?.value;

  const isAdminId = (inputRoleId) => {
    const adminRoleID = availableRoles.find(
      ({ label }) => label.toLowerCase() === ADMIN
    ).value;
    return !!(adminRoleID === inputRoleId);
  };

  const addRow = (setValues) => {
    setValues((values) => [...values, savedInitialValues]);
  };

  const deleteRow = (index, setValues) => {
    setValues((values) => values.filter((_, rowIndex) => index !== rowIndex));
  };
  const isValidName = (name) => NAME.test(name?.trim())
  const isValidEmail = (email) => EMAIL_REGEX_WITH_WITESPACES.test(email);

  const handleCSVImport = ({ file, fileInput }) => {
    const reader = new FileReader();
    reader.onload = async (event) => {
      const data = event.target.result;
      try {
        const parsedData = await new Promise((resolve, reject) => {
          readString(data, {
            header: true,
            dynamicTyping: true,
            complete: (parsedData) => resolve(parsedData),
            error: (error) => reject(error),
          });
        });
        const formattedData = getFormattedCsvDetails(parsedData?.data);
        const result = formattedData.map(
          ({ firstName, lastName, email, admin, wallet }, index) => {
            if (!isValidName(firstName) || !isValidEmail(email)) {
              return null;
            }
            return {
              firstName: firstName?.trim(),
              lastName: lastName?.trim(),
              email: email?.trim(),
              roleId: (admin?.toString().toLowerCase()?.trim() === 'true' || admin?.toString().toLowerCase()?.trim() === 'yes') ? getAdminOrUserId(ADMIN) : getAdminOrUserId(USER),
              isWalletEnabled: (wallet?.toString().toLowerCase()?.trim() === 'true' || wallet?.toString().toLowerCase()?.trim() === 'yes')
            };
          }
        ).filter(item => item !== null);
        setCsvData(result);
        setShowFileUpload(false);
        fileInput.value = null;
      } catch (error) {
        fileInput.value = null;
      }
    };
    reader.readAsText(file);
  };

  const getFileExtension = (filename) => {
    const parts = filename.split(".");
    if (parts.length > ONE) return parts[parts.length - ONE];
    else return EMPTY_STRING;
  };

  const handleImport = () => fileInputRef.current.click();

  const handleUploadFile = async (event) => {
    if (!event.target) return;
    const fileInput = event.target;
    const file = fileInput.files[ZERO];
    const fileName = file.name;
    const extension = getFileExtension(fileName);
    setSelectedFile(fileName);
    if (!file) return;
    const fileToBeImported = { file, fileInput, fileName, extension };
    setUploadedFile(fileToBeImported);
    setCsvData(null);
    event.target.value = ""
  };

  const handleImportFile = (fileDetails) => {
    const { file, fileInput } = fileDetails;
    handleCSVImport({ file, fileInput });
  };

  return (
    <Modal
      size={showFileUpload ? MD : XL}
      shouldShowModalFromProps
      handleClose={handleCloseModal}
      modalClassname='overflow-visible'
    >
      <div className='flex flex-col divide-y divide-contrast-300'>
        <div
          className={classNames(
            "flex justify-between items-center p-6 top-0 sticky bg-white z-10",
            { " border-b-2 border-slate-200": showFileUpload }
          )}
        >
          <div className='text-2xl leading-8 font-bold'>{title}</div>
          <div className='flex gap-4'>
            <button
              onClick={() => setShowFileUpload(!showFileUpload)}
              className='flex items-center text-nowrap justify-center w-fit py-2 px-4 rounded-md text-sm text-primary-600 hover:bg-primary-200 bg-primary-100'
            >
              {showFileUpload ? "Add User" : "Upload CSV"}
            </button>
            <button onClick={handleCloseModal}>
              <RenderSVG Svg={Cross} className='text-contrast-900' />
            </button>
          </div>
        </div>
        {showFileUpload && (
          <div>
            <div
              className='border-2 border-contrast-400 group hover:border-primary-600 border-dashed m-4 rounded-lg px-6 py-16 cursor-pointer'
              onClick={handleImport}
            >
              <div>
                <div className='text-center'>
                  <div className='icon mb-1'>
                    <RenderSVG
                      Svg={AddFolderIcon}
                      className='mx-auto w-10 h-8'
                    />
                  </div>
                  <div className='mb-1'>
                    <div className='text-primary-400 group-hover:opacity-90 group-hover:underline text-lg inline'>
                      Upload your File here or Browse
                    </div>
                  </div>
                </div>
                <input
                  type='file'
                  className='hidden'
                  accept='.csv,.xlsx,.xls'
                  onChange={handleUploadFile}
                  ref={fileInputRef}
                />
              </div>
            </div>
            <div className='px-4 pb-2'>
              <div className='flex flex-col items-start sm:items-center bg-contrast-100 p-4 gap-2 rounded-lg'>
                <div className='flex justify-between items-center w-full'>
                  <div className='flex flex-col gap-2 w-[60%]'>
                    <div className='flex gap-2'>
                      <RenderSVG
                        Svg={CsvIcon}
                        className='w-8 h-8 fill-green-600'
                      />
                      <div className='flex items-center font-semibold text-sm'>
                        Table Example
                      </div>
                    </div>
                    <div className='text-contrast-400 text-xs'>
                      You can download the attached example and use it as a
                      starting point for your own file.
                    </div>
                  </div>
                  <div className='ml-auto flex gap-4'>
                    <a
                      href={INVITE_TEMPLATE_URL}
                      download='downloaded'
                      className='flex items-center text-nowrap justify-center w-fit py-2 px-4 rounded-md text-sm text-primary-600 hover:bg-contrast-200 bg-white cursor-pointer'
                    >
                      Download
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div>
              {selectedFile && (
                <div className='px-4 pb-2'>
                  <div className='flex flex-col items-start sm:items-center border-dashed border border-contrast-500 p-2 gap-2 rounded'>
                    <div className='flex justify-between items-center w-full'>
                      <div className=' flex justify-center text-xs sm:text-sm md:text-base'>
                        {selectedFile}
                      </div>
                      <div className='ml-auto flex '>
                        <button
                          className='rounded w-6 h-6 m-1 flex justify-center items-center flex-col disabled:cursor-not-allowed disabled:bg-contrast-600'
                          onClick={() => {
                            setSelectedFile(null);
                            setUploadedFile(null);
                          }}
                        >
                          <RenderSVG Svg={Trash} className='w-5 h-5 mr-2' />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        <Formik
          initialValues={isEmpty(csvData) ? [savedInitialValues] : csvData}
          validationSchema={validationSchema}
          enableReinitialize
          validateOnMount
        >
          {({
            values,
            setValues,
            setFieldValue,
            setTouched,
            validateForm,
            isValid,
          }) => (
            <Form>
              {!showFileUpload && (
                <>
                  <div className='px-6 py-4 overflow-y-scroll max-h-80'>
                    <table className='w-full border-collapse'>
                      <thead className='bg-contrast-100'>
                        <tr>
                          {tableHeaders.map(
                            (header, index) =>
                              (header !== "Wallet Enabled" ||
                                !!shouldAllowWallet) && (
                                <th
                                  key={index}
                                  className='border border-slate-200 text-xs font-semibold px-4 py-3 text-contrast-900 text-left uppercase'
                                >
                                  {header}{(header === tableHeaders[ZERO] || header === tableHeaders[ONE] || header === tableHeaders[TWO]) ? (<Asterisk />) : EMPTY_STRING}
                                </th>
                              )
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {values.map((row, rowIndex) => (
                          <tr key={rowIndex}>
                            <td className='border border-slate-200 px-4 pt-4 pb-3'>
                              <InputField
                                type='text'
                                name={`[${rowIndex}].firstName`}
                                label=''
                                placeholder='Enter First Name'
                                value={row.firstName?.trim()}
                              />
                            </td>
                            <td className='border border-slate-200 px-4 pt-4 pb-3'>
                              <InputField
                                type='text'
                                name={`[${rowIndex}].lastName`}
                                label=''
                                placeholder='Enter Last Name'
                                value={row.lastName?.trim()}
                              />
                            </td>
                            <td className='border border-slate-200 px-4 pt-4 pb-3'>
                              <InputField
                                type='email'
                                name={`[${rowIndex}].email`}
                                label=''
                                placeholder='Enter Email'
                                value={row.email?.trim()}
                              />
                            </td>
                            <td className='border border-slate-200 px-4 py-2'>
                              <div className='lg:flex md:flex text-sm font-medium text-contrast-900 justify-between w-[70%]'>
                                <div className='flex items-center gap-2'>
                                  <span className='text-contrast-900'>
                                    Admin
                                  </span>
                                  <label className='relative inline-flex items-center cursor-pointer'>
                                    <input
                                      type='checkbox'
                                      name={`[${rowIndex}].roleId`}
                                      checked={isAdminId(row.roleId)}
                                      onChange={() =>
                                        setFieldValue(
                                          `[${rowIndex}].roleId`,
                                          !isAdminId(row.roleId)
                                            ? getAdminOrUserId(ADMIN)
                                            : getAdminOrUserId(USER)
                                        )
                                      }
                                      className='sr-only peer'
                                    />
                                    <div className="w-11 h-6 bg-secondary-200 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-secondary-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-secondary-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-secondary-600"></div>
                                  </label>
                                </div>
                              </div>
                            </td>
                            {!!shouldAllowWallet && (
                              <td className='border border-slate-200 px-4 py-2'>
                                <div className='flex items-center gap-2'>
                                  <span className='text-contrast-900'>
                                    Wallet Enabled
                                  </span>
                                  <label className='relative inline-flex items-center cursor-pointer'>
                                    <Field
                                      type='checkbox'
                                      name={`[${rowIndex}].isWalletEnabled`}
                                      checked={row.isWalletEnabled}
                                      className='sr-only peer'
                                    />
                                    <div className="w-11 h-6 bg-secondary-200 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-secondary-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-secondary-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-secondary-600"></div>
                                  </label>
                                </div>
                              </td>
                            )}
                            <td className='border border-slate-200 px-4 py-2'>
                              <div className='flex justify-center'>
                                <button
                                  type='button'
                                  disabled={values.length <=1}
                                  onClick={() =>
                                    deleteRow(
                                      rowIndex,
                                      setValues
                                    )
                                  }
                                  className='text-red-600 hover:text-red-800 disabled:cursor-not-allowed disabled:opacity-70'
                                >
                                  <RenderSVG Svg={Trash} className='w-5 h-5' />
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className='flex justify-center border border-slate-200 px-4 py-2 bottom-0 sticky'>
                    <button
                      type='button'
                      onClick={() => addRow(setValues)}
                      className='flex items-center justify-center w-fit py-2 px-4 rounded-lg text-primary-600 hover:bg-primary-200 bg-primary-100'
                    >
                      <RenderSVG Svg={Plus} className='w-5 h-5 mr-2' />
                      Add User
                    </button>
                  </div>
                </>
              )}

              <div className='bottom-0 sticky bg-white'>
                <div colSpan={5} className='border border-slate-200 px-4 py-2'>
                  <div className='flex gap-3 justify-end p-6'>
                    <button
                      type='button'
                      className='rounded-md shadow-sm px-4 py-2 border border-contrast-300 text-sm leading-5'
                      onClick={handleCloseModal}
                    >
                      Cancel
                    </button>
                    {!showFileUpload ? (<button
                      type='button'
                      disabled={isSpinnerActive || !values.length} // values.length - formik doesn't validate the top-most object in values
                      className="ronded-md w-20 shadow-sm px-4 py-2 disabled:cursor-not-allowed disabled:opacity-75 bg-primary-600 hover:bg-primary-800 text-white text-sm leading-5"
                      onClick={() => {
                        if (!isValid) {
                          setTouched(values.map(getTouchedFields));
                          validateForm();
                        } else handleSubmit(values);
                      }}
                    >
                      <Spinner
                        setIsSpinnerActive={setIsSpinnerActive}
                        name={spinnerName}
                      >
                        {primaryButtonText}
                      </Spinner>
                    </button>
                    ) :
                      (
                        <button
                          type='button'
                          className='flex items-center text-nowrap justify-center w-fit py-2 px-4 rounded-md text-sm text-primary-600 hover:bg-primary-200 bg-primary-100 disabled:cursor-not-allowed'
                          onClick={() => handleImportFile(uploadedFile)}
                          disabled={(!selectedFile || !isEmpty(csvData))}
                        >
                          {(!selectedFile || isEmpty(csvData) ? "Import" : "Imported")}
                        </button>
                      )
                    }
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};
export default AddUserModal;
