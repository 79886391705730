import { cloneDeep, get, isEmpty } from "lodash";
import * as Yup from "yup";
import moment from "moment";
import {
  TRAVELER_TYPE,
  DEFAULT_VALUES,
  FLIGHT_SERVICE_TYPE,
  REGEX,
  TBO_FARE_TYPES,
  SSR_BUTTONS,
  MAX_TRAVELER_AGES,
  MIN_TRAVELER_AGES,
  INDEX,
} from "../constants";
import {
  isValidMaxDateOfBirth,
  isValidMinDateOfBirth,
  isConsistentTravelerCategory,
} from "./validateDateOfBirth";
import { getRequiredErrorMessage } from "./getErrorMessage";

const { HELD_INFANT, ADULT, CHILD } = TRAVELER_TYPE;
const { TRAVELERS, MEALS, PAYMENT, SAVE } = SSR_BUTTONS;
const { EMPTY_STRING, EMPTY_ARRAY, ZERO, ONE, THREE, EMPTY_OBJECT } = DEFAULT_VALUES;
const { AMADEUS } = FLIGHT_SERVICE_TYPE;
const {
  NAME,
  OPTIONAL_NAME,
  PASSPORT_NUMBER,
  PANCARD_NUMBER,
  NON_ZERO_PASSPORT_NUMBER,
  EMAIL,
  PHONE_NUMBER,
} = REGEX;
const { REGULAR } = TBO_FARE_TYPES;
const PASSPORT_EXPIRY_MONTH_COUNT = 6;
const { LAST } = INDEX;

const getMonthDifference = (expireDate, returnDate) => {
  const flightDepartureDate = moment(returnDate);
  const passportExpiryDate = moment(expireDate);

  const monthsDifference = passportExpiryDate.diff(
    flightDepartureDate,
    "months"
  );
  return monthsDifference;
};

const INITIAL_TRAVELER_DETAILS = {
  [ADULT]: EMPTY_ARRAY,
  [CHILD]: EMPTY_ARRAY,
  [HELD_INFANT]: EMPTY_ARRAY,
};

export const getMappedTravelers = (travelerDetails) => {
  const selectedTravelers = travelerDetails.reduce(
    (selectedTravelers, traveler) => {
      const { travelerType, id, givenName, familyName, dateOfBirth, title } =
        traveler;
      const profileDetails = {
        firstName: givenName,
        middleName: EMPTY_STRING,
        lastName: familyName,
        dateOfBirth: dateOfBirth,
        title,
      };
      selectedTravelers.push({
        travelerType: TRAVELER_TYPE[travelerType],
        profileDetails,
        travelerId: id,
      });
      return selectedTravelers;
    },
    []
  );
  return selectedTravelers;
};

export const travelersSortbyTravelerType = (
  dispatch,
  isReissuanceFlight,
  reissuanceFlightData,
  bookingTravelers,
  setSelectedTravelers
) => {
  const travelers = isReissuanceFlight
    ? getMappedTravelers(reissuanceFlightData)
    : bookingTravelers;

  const newUpdatedTravelers = travelers?.reduce((acc, traveler) => {
    const travelerType = traveler.travelerType[ZERO] + traveler.travelerType.slice(ONE).toLowerCase();
    if (!travelerType) return acc;
    acc[travelerType] = [...(acc[travelerType] || []), traveler];
    return acc;
  }, cloneDeep(INITIAL_TRAVELER_DETAILS));

  isReissuanceFlight && dispatch(setSelectedTravelers(travelers));
  return newUpdatedTravelers;
};

export const getPaxErrorMessage = (
  message,
  customMessage,
  name,
  allFlightPriceRes,
  t = () => {},
  isPaxValid = false
) => {
  const travelerPricingDetails = get(
    allFlightPriceRes,
    "0.flightPriceInfo.travelerPricings",
    EMPTY_ARRAY
  );
  const selectableSSRServicesCount = travelerPricingDetails.reduce(
    (totalCount, { travelersCount, travelerType }) =>
      totalCount +
      (travelerType !== HELD_INFANT ||
      [MEALS, PAYMENT, TRAVELERS].includes(name)
        ? travelersCount
        : ZERO),
    ZERO
  );
  const errorMsgKey =
    name === PAYMENT || name === TRAVELERS || name === SAVE
      ? "travelerInfo.continueButton.travelerError"
      : "travelerInfo.continueButton.error";

  const errorMsgName =
    name === PAYMENT || name === TRAVELERS || name === SAVE
      ? t("travelerInfo.travelers")
      : t(`travelerInfo.${name}`, { count: selectableSSRServicesCount });

  if (!isPaxValid) return t("travelerInfo.continueButton.paxValidation");
  return (
    message ||
    customMessage ||
    t(errorMsgKey, {
      count: selectableSSRServicesCount,
      name: errorMsgName,
    })
  );
};

export const generateTravelerValidationSchema = (
  travelerType,
  isInternationalAMFlight,
  isGoingToNepal,
  flightPriceReq,
  flightPriceRes = [],
  t = () => {}
) => {
  const priceRes = flightPriceRes[0] || EMPTY_OBJECT;
  const { flightPriceInfo = EMPTY_STRING } = priceRes;
  const {
    fareType = REGULAR,
    source = EMPTY_STRING,
    documentsRequired = EMPTY_OBJECT,
  } = flightPriceInfo;

  const isFareTypeDocumentRequired = fareType !== REGULAR;
  const nameValidation = Yup.string()
    .matches(NAME, t("profilePage.errors.nameFormat"))
    .min(THREE, t("profilePage.errors.minLength"))
    .max(25, t("profilePage.errors.maxLength"));

  const {
    isPanRequiredAtBook,
    isPanRequiredAtTicket,
    isPassportRequiredAtBook,
    isPassportRequiredAtTicket,
    isPassportFullDetailRequiredAtBook,
  } = documentsRequired;

  const {
    departure: { date: lastDepartureDate } = {}, // Todo: lastDepartureDate cannot read property of undefined date (Need to be fixed)
    arrival: { date: lastArrivalDate } = {}
  } =
    flightPriceReq
      .slice(LAST)
      [ZERO]?.price?.itineraries?.slice(LAST)
      [ZERO]?.segments?.slice(LAST)[ZERO] || {};
  const {
    departure: { date: firstFlightDepartureDate } = {},  // Todo: lastDepartureDate cannot read property of undefined date (Need to be fixed)
  } = get(flightPriceReq, `[0].price.itineraries[0].segments[0]`, EMPTY_OBJECT);

  let baseSchema = {
    travelerType: Yup.string().required(),
    profileDetails: Yup.object({
      firstName: nameValidation.required(
        getRequiredErrorMessage("profilePage.firstName", t)
      ),
      middleName: Yup.string()
        .matches(OPTIONAL_NAME, t("profilePage.errors.nameFormat"))
        .max(25, t("profilePage.errors.maxLength")),
      lastName: Yup.string()
        .matches(OPTIONAL_NAME, t("profilePage.errors.nameFormat"))
        .max(25, t("profilePage.errors.maxLength")),
      dateOfBirth: Yup.string()
        .required(getRequiredErrorMessage("profilePage.dob", t))
        .test(
          "minDateOfBirthBeforeDepartureDate",
          MIN_TRAVELER_AGES[travelerType] === 15
            ? `${travelerType} age must be above ${MIN_TRAVELER_AGES[travelerType]} days on the first departure date.`
            : `${travelerType} age must be above ${MIN_TRAVELER_AGES[travelerType]} years on the first departure date.`,
          (_, context) => {
            const { dateOfBirth } = context.parent;
            return (
              firstFlightDepartureDate &&
              isValidMinDateOfBirth(
                firstFlightDepartureDate,
                dateOfBirth,
                travelerType
              )
            );
          }
        )
        .test(
          "maxDateOfBirthBeforeDepartureDate",
          `${travelerType} age must be below ${MAX_TRAVELER_AGES[travelerType]} years on the last departure date.`,
          (_, context) => {
            const { dateOfBirth } = context.parent;
            return (
              firstFlightDepartureDate &&
              isValidMaxDateOfBirth(
                firstFlightDepartureDate,
                dateOfBirth,
                travelerType
              )
            );
          }
        )
        .test(
          "consistentTravelerCategory",
          "We would advise you to book separate flights due to change in category of passenger",
          (_, context) => {
            const { dateOfBirth } = context.parent;
            return (
              firstFlightDepartureDate && lastArrivalDate &&
              isConsistentTravelerCategory(
                firstFlightDepartureDate,
                lastArrivalDate,
                dateOfBirth
              )
            );
          }
        ),
    }),
  };

  if (
    isPassportRequiredAtBook ||
    isPassportRequiredAtTicket ||
    isInternationalAMFlight ||
    (isGoingToNepal && travelerType !== HELD_INFANT)
  ) {
    baseSchema = {
      ...baseSchema,
      passportDetails: Yup.object().shape({
        expireDate: Yup.string()
          .required(getRequiredErrorMessage("profilePage.expireDate", t))
          .test(
            "expiryDateAfterIssuanceDate",
            t("profilePage.errors.expireDate"),
            (_, context) => {
              const { expireDate } = context.parent;
              const isPassportActive =
                getMonthDifference(expireDate, lastDepartureDate) >=
                PASSPORT_EXPIRY_MONTH_COUNT;
               return isPassportActive;
            }
          ),
        passport: Yup.string()
          .matches(PASSPORT_NUMBER, t("profilePage.errors.passportFormat"))
          .matches(
            NON_ZERO_PASSPORT_NUMBER,
            t("profilePage.errors.nonZeroPassportNumber")
          )
          .required(getRequiredErrorMessage("profilePage.passport", t)),
      }),
    };

    if (isPassportFullDetailRequiredAtBook || source === AMADEUS) {
      baseSchema.passportDetails = baseSchema.passportDetails.shape({
        issuedDate: Yup.string()
          .required(getRequiredErrorMessage("profilePage.issueDate", t))
          .test(
            "issueDateAfterDateOfBirth",
            t("profilePage.errors.issueDate"),
            (_, context) => {
              const { issuedDate } = context.parent;
              const dateOfBirth =
                context.from[1]?.value?.profileDetails.dateOfBirth;
              const issuedDateObj = new Date(issuedDate);
              const dateOfBirthObj = new Date(dateOfBirth);
              return issuedDateObj > dateOfBirthObj;
            }
          ),
        issueCountry: Yup.object().shape({
          isoCode: Yup.string().required(
            getRequiredErrorMessage("profilePage.issuingCountry", t)
          ),
        }),
      });
    }
  }

  if (isPanRequiredAtBook || isPanRequiredAtTicket)
    baseSchema = {
      ...baseSchema,
      panCardDetails: Yup.object().when([], (values, schema) =>
        schema.shape({
          panCardNumber: Yup.string()
            .required(getRequiredErrorMessage("profilePage.panCardNumber", t))
            .matches(PANCARD_NUMBER, t("profilePage.errors.panCardFormat")),
        })
      ),
    };

  if (isFareTypeDocumentRequired)
    baseSchema = {
      ...baseSchema,
      document: Yup.object().shape({
        number: Yup.string().required("Document ID number is required."),
        type: Yup.object().shape({
          name:Yup.string().required("Document Type is required"),
        })
      }),
    };

  return Yup.object(baseSchema);
};

export const checkingPaxValidation = (validationSchema, travelers = []) => {
  const paxValidationStatus = travelers.reduce((acc, pax) => {
    const { travelerType = EMPTY_STRING, travelerId = EMPTY_STRING } = pax;
    if (validationSchema[travelerType])
      try {
        validationSchema[travelerType].validateSync(pax);
      } catch (err) {
        acc[travelerId] = err.message;
      }
    return acc;
  }, {});

  return paxValidationStatus;
};

export const getPrimaryTravelerIndex = (travelers) =>
  travelers.every((traveler) => !traveler.isPrimary)
    ? travelers.findIndex((traveler) => traveler.travelerType === ADULT)
    : travelers.findIndex((traveler) => traveler.isPrimary);

export const getPriceDifference = (grandTotal, priceReq = []) => {
  const prevTotalPrice = priceReq.reduce(
    (acc, priceObj) => acc + get(priceObj, "price.price.grandTotal", ZERO),
    ZERO
  );
  return grandTotal - prevTotalPrice;
};

export const generateContactValidationSchema = (t = () => {}) => {
  return Yup.object({
    email: Yup.string()
      .trim()
      .matches(EMAIL, t("profilePage.errors.emailFormat"))
      .required(),
    phoneNumber: Yup.string()
      .matches(PHONE_NUMBER, t("profilePage.errors.phone"))
      .required(),
    phoneCode: Yup.string().required(),
  });
};

export const generateAddressValidationSchema = (t = () => {}) => {
  return Yup.object({
    nationality: Yup.object({
      isoCode: Yup.string().required(),
      name: Yup.string().required(),
    }),
    address: Yup.string().required(),
    city: Yup.string().required(),
    state: Yup.string().required(),
  });
};

export const checkingContactAndAddress = (selectedAddressAndContactDetails) => {
  if (!selectedAddressAndContactDetails) return {};
  const validationResult = selectedAddressAndContactDetails.reduce(
    (acc, { data, validationSchema, section }) => {
      if (isEmpty(acc)) {
        try {
          validationSchema.validateSync(data);
        } catch (err) {
          acc[section] = true;
        }
      }
      return acc;
    },
    {}
  );
  return validationResult;
};
