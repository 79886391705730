import { Formik, Form, Field } from "formik";
import { useState,useEffect } from "react";
import * as Yup from "yup";
import Modal from "../../Modal";
import { InputField } from "../../../molecules";
import Spinner from "../../Spinner";
import { MODAL_SIZE, DEFAULT_VALUES, REGEX } from "../../../../constants";
import { RenderSVG, Cross } from "../../../../assets/icons";
import { ROLE_LABEL, ENVIRONMENTS } from "../../../../constants";
import classNames from "classnames";
import { isEqual } from "lodash";
import { selectUserInfo } from "../../../../screens/Profile";
import { useSelector } from "react-redux";
import Asterisk from "../../../atoms/Asterisk"

const { MD } = MODAL_SIZE;
const { DEFAULT_PRIMARY_TEXT, EMPTY_STRING } = DEFAULT_VALUES;
const shouldAllowWallet = process.env.REACT_APP_ENV === ENVIRONMENTS.PRODUCTION;
const {ADMIN, USER} = ROLE_LABEL
const ERROR_VISIBILITY_DURATION = 3000;

const ManageUserModal = ({
  title = EMPTY_STRING,
  primaryButtonText = DEFAULT_PRIMARY_TEXT,
  handleCloseModal,
  handleSubmit,
  savedInitialValues,
  availableRoles,
  availableStatuses,
  spinnerName,
}) => {
  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .trim("First Name Required")
      .required("First Name Required")
      .matches(REGEX.NAME, "Name must contain letters only")
      .min(1, "First name must be at least 1 letters long")
      .max(100, "Name cannot exceed 100 characters."),
    lastName: Yup.string()
      .trim()
      .required("Last Name Required")
      .matches(REGEX.NAME, "Name must contain letters only")
      .min(1, "Last name must be at least 1 letters long")
      .max(100, "Name cannot exceed 100 characters."),
    email: Yup.string()
      .required("Email Required")
      .max(100, "User email cannot exceed 100 characters."),
    roleId: Yup.string().required("Select Role for user"),
    isActive: Yup.bool().required("Status is required"),
  });

  const [blinkErrorHighlighter, setBlinkErrorHighlighter] = useState(false);
  const [isInActiveConfirm, setIsInActiveConfirm] = useState(false);
  const userInfo = useSelector(selectUserInfo);

  const getAdminOrUserId = (roleName) => availableRoles.find(({label}) => roleName === label.toLowerCase())?.value

  const [isCreateAndManageTripSpinnerActive, setIsCreateAndManageTripSpinnerActive] =
    useState(true);

  const isAdminId = (inputRoleId) => {
    const adminRoleID = availableRoles.find(({label}) => label.toLowerCase() === ADMIN).value
    return !!(adminRoleID === inputRoleId)
  }

  const handleFormSubmit = (values) => {
    if (userInfo.id === values.id && !values.isActive && !isInActiveConfirm) {
      setBlinkErrorHighlighter(true)
      setTimeout(() => {
        setBlinkErrorHighlighter(false);
      }, [ERROR_VISIBILITY_DURATION])
    }
    else {
      handleSubmit(values);
    }
  }
 
  return (
    <Modal
      size={MD}
      shouldShowModalFromProps
      handleClose={handleCloseModal}
      modalClassname='overflow-visible'
    >
      <div className='flex flex-col divide-y divide-contrast-300'>
        <div className='flex justify-between items-center p-6'>
          <div className='text-2xl leading-8 font-bold'>{title}</div>
          <div>
            <button onClick={handleCloseModal}>
              <RenderSVG Svg={Cross} className='text-contrast-900' />
            </button>
          </div>
        </div>
        <Formik
          initialValues={savedInitialValues}
          validationSchema={validationSchema}
          onSubmit={handleFormSubmit}
          enableReinitialize
          validateOnMount
        >
          {({ values, setFieldValue, isValid }) => (
            <Form>
              <div className='px-6 py-4 border-y border-contrast-300'>
                <div className="flex items-center gap-4 px-1">
                  <div className="w-1/2">
                    <InputField
                      type='text'
                      name='firstName'
                      label={
                        <>
                          First Name
                          <Asterisk />
                        </>
                      }
                      placeholder='First Name'
                      value={values.firstName.trim()}
                    />
                  </div>
                  <div className="w-1/2">
                    <InputField
                      type='text'
                      name='lastName'
                      label='Last Name'
                      placeholder='Enter Last Name'
                      showAsterisk={true}
                      value={values.lastName.trim()}
                    />
                  </div>
                </div>
                <InputField
                  type='email'
                  name='email'
                  label='Email'
                  placeholder='Enter Email'
                  value={values.email}
                  disabled={true}
                />
                <div
                  className={classNames(
                    "lg:flex md:flex text-sm font-medium text-contrast-900 justify-between mb-4",
                    {
                      "w-full": shouldAllowWallet,
                      "w-[70%]": !shouldAllowWallet,
                    }
                  )}
                >
                  <div className='flex items-center gap-2'>
                    <span className='text-contrast-900'>Active</span>
                    <label className='relative inline-flex items-center cursor-pointer'>
                      <input
                        type='checkbox'
                        name='isActive'
                        checked={values.isActive}
                        onChange={() =>{
                            setFieldValue("isActive", !values.isActive);
                            setIsInActiveConfirm(false);
                        }}
                        className='sr-only peer'
                      />
                      <div className="w-11 h-6 bg-secondary-200 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-secondary-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-secondary-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-secondary-600"></div>
                    </label>
                  </div>
                  <div className='flex items-center gap-2'>
                    <span className='text-contrast-900'>Admin</span>
                    <label className='relative inline-flex items-center cursor-pointer'>
                      <input
                        type='checkbox'
                        name='roleId'
                        checked={isAdminId(values.roleId)}
                        onChange={() =>
                          setFieldValue(
                            "roleId",
                            !isAdminId(values.roleId)
                              ? getAdminOrUserId(ADMIN)
                              : getAdminOrUserId(USER)
                          )
                        }
                        className='sr-only peer'
                      />
                      <div className="w-11 h-6 bg-secondary-200 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-secondary-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-secondary-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-secondary-600"></div>
                    </label>
                  </div>
                  {!!shouldAllowWallet && <div className='flex items-center gap-2'>
                    <span className='text-contrast-900'>
                      Wallet Enabled
                    </span>
                    <label className='relative inline-flex items-center cursor-pointer'>
                      <Field
                        type='checkbox'
                        name='isWalletEnabled'
                        checked={values.isWalletEnabled}
                        className='sr-only peer'
                      />
                      <div className="w-11 h-6 bg-secondary-200 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-secondary-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-secondary-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-secondary-600"></div>
                    </label>
                  </div>}
                </div>
                {!values.isActive && userInfo.id == values.id && 
                  <div className={classNames("flex flex-row items-center mb-2 ", {
                    "border-2 error-animate bg-red-100/20 p-3 mt-1":
                          blinkErrorHighlighter
                  })}>
                  <input
                    className="w-5 h-5 mr-3 cursor-pointer"
                    type='checkbox'
                    id="logoutConfirm"
                    checked={isInActiveConfirm}
                    onChange={() => {
                      setIsInActiveConfirm(!isInActiveConfirm)
                    }}
                  />
                  <label for="logoutConfirm" className="text-xs cursor-pointer">Deactivation will immediately log you out and block future access until reactivated.</label>
                </div>}
              </div>
              <div>
                <div className='flex gap-3 justify-end p-6'>
                  <button
                    type='button'
                    className='rounded-md shadow-sm px-4 py-2 border border-contrast-300 text-sm leading-5'
                    onClick={handleCloseModal}
                  >
                    Cancel
                  </button>
                  <button
                    type='submit'
                    disabled={isCreateAndManageTripSpinnerActive || isEqual(savedInitialValues, values)}
                    className={
                      "rounded-md w-20 shadow-sm px-4 py-2 disabled:cursor-not-allowed disabled:bg-primary-400 bg-primary-600 hover:bg-primary-800 text-white text-sm leading-5"
                    }
                  >
                    <Spinner
                      setIsSpinnerActive={setIsCreateAndManageTripSpinnerActive}
                      name={spinnerName}
                    >
                      {primaryButtonText}
                    </Spinner>
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default ManageUserModal;
