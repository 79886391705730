import React, { useState ,useMemo } from "react";
import { Field, Form, Formik}from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import Asterisk from "../../atoms/Asterisk";
import { CACHE_KEYS, ROLE_LABEL, DEFAULT_VALUES, REGEX ,MESSAGES} from "../../../constants";
import { getFromLocalStorage } from "../../../helper";
import { actions } from "../../organisms/Drawer/drawers.reducers";
import { addUser, getUsersList } from "../../../screens/Users/users.actions";
import { toast } from "react-toastify";
import { sendInvitation } from "../../../screens/Auth/auth.actions";
import { selectUserInfo } from "../../../screens/Profile";
import { useSelector, useDispatch } from "react-redux";
import { selectRolesPermissionsAssociationData } from "../../../screens/Permissions/permissions.selector";
import { selectCurrentUserInfo } from "../../../screens/Auth";
import { setSelectedTripDetail } from "../../../screens/Booking/Trips";
import { selectTripDetail } from "../../../screens/Booking/Trips/trips.selector";
import DateSearch from "../../atoms/DateSearch";
import { getTravelersAgeFromDOB } from "../../../helper";
import { RenderSVG, InfoIcon } from "../../../assets/icons";
import { Tooltip } from "react-tooltip";
import Spinner, { SPINNER_NAMES } from "../../organisms/Spinner";

const { MISSING_LAST_NAME_MSG } = MESSAGES;
const { AUTH } = CACHE_KEYS;
const { USER } = ROLE_LABEL;
const { EMPTY_STRING, SINGLE_SPACE_STRING } = DEFAULT_VALUES;
const { NAME , EMAIL } = REGEX;
const { ADD_TRAVELER } = SPINNER_NAMES;
const MALE = "MALE";
const invitedUserAlreadyExistsMessage = "Invited User already exist";

export default function AddTraveler({ handleClose }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { setSelectedDrawer } = actions;
  const [isInvitingUser, setIsInvitingUser] = useState(false);
  const [isSpinnerActive, setIsSpinnerActive] = useState(false);
  const selectedRoles = useSelector(selectRolesPermissionsAssociationData);
  const userInfo = useSelector(selectUserInfo);
  const currentUserInfo = useSelector(selectCurrentUserInfo);
  const newTripDetail = useSelector(selectTripDetail);
  const { tenantId } = userInfo;
  const userRole = selectedRoles.find(
    ({ name }) => name.toLowerCase() === USER
  );
  const roleId = userRole?.id;

  const userHasPermission = () =>
    currentUserInfo?.permissions?.includes("canInviteTeam");

  const validationSchema = Yup.object({
    profileDetails: Yup.object({
      firstName: Yup.string()
        .trim()
        .matches(NAME, "Name must contain letters only")
        .required("First name is required")
        .min(1, "First name must be at least 3 letters long")
        .max(25, "First name cannot be longer than 25 characters"),
      middleName: Yup.string()
        .trim()
        .matches(NAME, "Name must contain letters only")
        .max(25, "Middle name cannot be longer than 25 characters"),
      lastName: Yup.string()
        .trim()
        .matches(NAME, "Name must contain letters only")
        .required("Last name is required")
        .min(1, "Last name must be at least 1 letters long")
        .max(25, "Last name cannot be longer than 25 characters"),
      dateOfBirth: Yup.date().required("Date of Birth is required"),
    }),
    email: Yup.string()
        .trim()
        .matches(EMAIL, 'Invaild Email Address').email("Please enter a valid email")
        .required("Email address is required"),
  });

  const initialValues = {
    profileDetails: {
      firstName: EMPTY_STRING,
      middleName: EMPTY_STRING,
      lastName: EMPTY_STRING,
      dateOfBirth: EMPTY_STRING,
      gender: MALE,
    },
    email: EMPTY_STRING,
    address: EMPTY_STRING,
    city: EMPTY_STRING,
    isInvitingUser: false,
    userRole: USER,
  };

  const handleInviteUser = (values) => {
    const { id, email: senderEmail } = getFromLocalStorage(AUTH);
    const firstName = values?.profileDetails?.firstName;
    const lastName = values?.profileDetails?.lastName;
    const fullName = firstName + SINGLE_SPACE_STRING + lastName;
    const requestBody = [
      {
        name: fullName,
        email: values?.email?.toLowerCase(),
        invitedBy: id,
        senderEmail,
        roleId,
        isWalletEnabled: false,
        tenantId,
      },
    ];

    dispatch(sendInvitation(requestBody)).then((res) => {
      if (res.error) return;
      if(res.payload.Success === invitedUserAlreadyExistsMessage) return toast.error(res.payload.Success)
      toast.success("Invitation sent successfully!");
    });
  };

  const handleOnSubmit = (values) => {
    const requestBody = {
      firstName: values?.profileDetails?.firstName?.trim(),
      middleName: values?.profileDetails?.middleName?.trim(),
      lastName: values?.profileDetails?.lastName?.trim(),
      dateOfBirth:values?.profileDetails?.dateOfBirth.trim(),
      email: values?.email?.toLowerCase().trim(),
      address: values?.address,
      city: values?.city,
      role: values?.userRole,
      gender: values?.profileDetails?.gender
    };
     dispatch(addUser(requestBody)).then((res) => {
      dispatch(setSelectedDrawer(null));
      if (res.error) return;
      dispatch(setSelectedTripDetail({...newTripDetail, newTraveler : {...res.payload, type:'TRAVELER'}}));
      toast.success("Traveler added successfully!");
      if (isInvitingUser) handleInviteUser(values);
    });
  };
  const isAdultUser = (dateOfBirth) => {
    const age = getTravelersAgeFromDOB(dateOfBirth); 
    if (typeof age === 'object' || age < 18) {
      return false; 
    }
    return true; 
  };
  
  const isUserEligibleToInvite= (dateOfBirth) => useMemo(() => {
    return dateOfBirth ? isAdultUser(dateOfBirth) : false;
  }, [dateOfBirth]);

  return (
    <div className='p-6'>
      <div className='mb-4 font-semibold'>Personal Information</div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={handleOnSubmit}
      >
        {({ values, errors, touched, setFieldValue, setFieldTouched, isValid, dirty }) => (
          <Form>
            <div className='col-span-12 sm:col-span-6 md:col-span-4 flex flex-col gap-4'>
              <div className='form-group'>
                <label className='block text-sm font-medium mb-1 text-contrast-900'>
                  First Name
                  <Asterisk />
                </label>
                <div className='flex w-full border border-contrast-300 rounded-md focus:outline-none focus-within:ring-1 focus-within:ring-primary-500 focus-within:border-primary-500'>
                  <div className='border-[1px]' />
                  <Field
                    id='firstName'
                    name='profileDetails.firstName'
                    type='text'
                    className='form-control block w-full text-sm rounded-md py-2 px-3 border-0 placeholder:text-blue-contrast-500 shadow-none'
                    placeholder={t("profilePage.placeholders.firstName")}
                  />
                </div>
                {errors.profileDetails?.firstName &&
                  touched.profileDetails?.firstName && (
                    <div className='text-red-500 text-xs mt-1'>
                      {errors.profileDetails.firstName}
                    </div>
                  )}
              </div>

              <div className='form-group'>
                <label className='block text-sm font-medium mb-1 text-contrast-900'>
                  {t("profilePage.middleName")}
                </label>
                <Field
                  id='middleName'
                  name='profileDetails.middleName'
                  type='text'
                  className='form-control h-[38px] block w-full text-sm py-2 px-3 border-contrast-300 rounded-md placeholder:text-blue-contrast-500'
                  placeholder={t("profilePage.placeholders.middleName")}
                />
                {errors.profileDetails?.middleName &&
                  touched.profileDetails?.middleName && (
                    <div className='text-red-500 text-xs mt-1'>
                      {errors.profileDetails.middleName}
                    </div>
                  )}
              </div>
              <div className='form-group'>
                <label className='block text-sm font-medium mb-1 text-contrast-900'>
                  {t("profilePage.lastName")} 
                  <Asterisk /> 
                  <span
                    className='inline-block w-5 h-4 ml-2'
                    data-tooltip-id='lastname-tooltip'
                    data-tooltip-place='top'
                    data-tooltip-content={MISSING_LAST_NAME_MSG}
                  >
                    <RenderSVG
                      Svg={InfoIcon}
                      className='relative min-w-[20px] cursor-pointer'
                      alt='info-icon'
                    />
                    <Tooltip
                      id='lastname-tooltip'
                      className='!w-56 !sm:w-72 !bg-primary-900 !rounded-lg !z-50'
                    />
                  </span>
                </label>
                <Field
                  id='lastName'
                  name='profileDetails.lastName'
                  type='text'
                  className='w-full h-[38px] text-sm py-2 px-3 border-contrast-300 rounded-md placeholder:text-blue-contrast-500'
                  placeholder={t("profilePage.placeholders.lastName")}
                />
                {errors.profileDetails?.lastName &&
                  touched.profileDetails?.lastName && (
                    <div className='text-red-500 text-xs mt-1'>
                      {errors.profileDetails.lastName}
                    </div>
                  )}
              </div>
              <div className='form-group'>
                      <label
                        htmlFor='dateOfBirth'
                        className='block text-sm font-medium mb-1 text-contrast-900'
                      >
                        Date of Birth
                      <Asterisk />
                      </label>
                      <div>
                        <DateSearch
                          id='dateOfBirth'
                          name='profileDetails.dateOfBirth'
                          noOfMonth={1}
                          showCalendarIcon={true}
                          setFieldTouched={setFieldTouched}
                          setFieldValue={setFieldValue}
                          values={values.profileDetails}
                          placeholder="YYYY/MM/DD"
                          minDate={""}
                          maxDate={new Date()}
                          position='top-right'
                        />
                      </div>
                    {errors.profileDetails?.dateOfBirth &&
                  touched.profileDetails?.dateOfBirth && (
                    <div className='text-red-500 text-xs mt-1'>
                      {errors.profileDetails.dateOfBirth}
                    </div>
                  )}
              </div>
              <div className='form-group'>
                <label
                  htmlFor='gender'
                  className='block text-sm font-medium mb-1 text-contrast-900'
                >
                  Gender
                <Asterisk/>
                </label>
                <select
                  id='gender'
                  name='gender'
                  className='form-control block w-full h-[38px] text-sm py-2 px-3 border-contrast-300 rounded-md placeholder:text-blue-contrast-500'
                  value={values.profileDetails.gender}
                  onChange={(e) => setFieldValue("profileDetails.gender", e.target.value)}
                >
                  <option value='MALE'>Male</option>
                  <option value='FEMALE'>Female</option>
                </select>
              </div>
              <div className='border border-dashed h-0' />
              <div className='flex flex-col'>
                <span  className='text-contrast-900 text-md font-semibold bg-white justify-between'>{t("travelerInfo.contactInfo.title")}</span>
                <span className='text-contrast-900 text-xs bg-white justify-between rounded-t-lg'>{t("travelerInfo.contactInfo.subTitle")}</span>
              </div>
              <div className='form-group col-span-6 md:col-span-2 lg:col-span-2'>
                <label className='block text-sm font-medium mb-1 text-contrast-900'>
                  {t("profilePage.email")}
                  <Asterisk />
                </label>
                <Field
                  id='email'
                  name='email'
                  type='text'
                  className='form-control block w-full text-sm py-2 px-3 border-contrast-300 rounded-md placeholder:text-blue-contrast-500'
                  placeholder={t("profilePage.placeholders.email")}
                />
                {errors.email && touched.email && (
                  <div className='text-red-500 text-xs mt-1'>
                    {errors.email}
                  </div>
                )}
              </div>
              <div className='border border-dashed h-0' />
              <div className='flex flex-col'>
                <span className='contrast-900 text-md font-semibold bg-white justify-between'>{t("travelerInfo.addressInfo.title")}</span>
                <span className='text-contrast-900 text-xs bg-white justify-between rounded-t-lg'>{t("travelerInfo.addressInfo.subTitle")}</span>
              </div>
              <div className='form-group col-span-12 md:col-span-2 lg:col-span-2'>
                <label className='block text-sm font-medium mb-1 text-contrast-900'>
                  {t("profilePage.address")} (optional)
                </label>
                <Field
                  id='address'
                  name='address'
                  type='text'
                  className='form-control block w-full text-sm py-2 px-3 border-contrast-300 rounded-md placeholder:text-blue-contrast-500'
                  placeholder={t("profilePage.placeholders.address")}
                />
              </div>
              <div className='form-group col-span-12 md:col-span-2 lg:col-span-2'>
                <label className='block text-sm font-medium mb-1 text-contrast-900'>
                  {t("profilePage.city")} (optional)
                </label>
                <Field
                  id='city'
                  name='city'
                  type='text'
                  className='form-control block w-full text-sm py-2 px-3 border-contrast-300 rounded-md placeholder:text-blue-contrast-500'
                  placeholder={t("profilePage.placeholders.city")}
                />
              </div>
              {userHasPermission() && isUserEligibleToInvite(values.profileDetails.dateOfBirth) &&  (
                <>
                  <div className='form-group col-span-12'>
                    <label className='inline-flex items-center'>
                      <Field
                        type='checkbox'
                        name='isInvitingUser'
                        className='form-checkbox h-4 w-4 text-primary-600 transition duration-150 ease-in-out'
                        onChange={(e) => {
                          setIsInvitingUser(e.target.checked);
                          setFieldValue("isInvitingUser", e.target.checked);
                        }}
                        checked={isInvitingUser}
                      />
                      <div className='flex flex-col'>
                        <span className='ml-2 text-sm text-contrast-900 font-semibold'>
                          Invite the user as well
                        </span>
                        <span className='ml-2 text-sm text-contrast-900'>
                          (Invitation details will be sent via entered email)
                        </span>
                      </div>
                    </label>
                  </div>
                  {isInvitingUser && (
                    <div className='form-group'>
                      <label className='block text-sm font-medium mb-1 text-contrast-900'>
                        User Role
                      </label>
                      <div className='flex gap-4'>
                        <label className='inline-flex items-center'>
                          <Field
                            type='radio'
                            name='userRole'
                            value='user'
                            className='form-radio h-4 w-4 text-primary-600 transition duration-150 ease-in-out'
                          />
                          <span className='ml-2 text-sm text-contrast-900'>
                            User
                          </span>
                        </label>
                        <label className='inline-flex items-center'>
                          <Field
                            type='radio'
                            name='userRole'
                            value='admin'
                            className='form-radio h-4 w-4 text-primary-600 transition duration-150 ease-in-out'
                          />
                          <span className='ml-2 text-sm text-contrast-900'>
                            Admin
                          </span>
                        </label>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
            <div className='border border-dashed h-0 mt-4' />
            <div className='flex gap-4 justify-end p-4'>
              <button
                onClick={handleClose}
                type='button'
                className='bg-white rounded-md shadow border border-contrast-300 justify-center items-center flex hover:bg-zinc-300 h-10 px-4 py-2'
              >
                Cancel
              </button>
              <button
                type='submit'
                className='disabled:cursor-not-allowed disabled:bg-primary-400 bg-primary-600 text-white text-base px-4 py-2 rounded-md h-10'
                disabled={isSpinnerActive || !isValid || !dirty}
              >
                <Spinner
                  name={ADD_TRAVELER}
                  setIsSpinnerActive={setIsSpinnerActive}
                  persistSize={true}
                >
                  Add User
                </Spinner>
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
