import moment from "moment";
import { DEFAULT_VALUES, REGEX } from "../constants";

const { EMPTY_STRING, ZERO, TWO, THREE, FIVE, ONE } = DEFAULT_VALUES;
const { DURATION_REGEX } = REGEX;
const TIME_ZERO = "00:00";
const MINUTES_IN_HOUR = 60;
const HOURS_IN_DAY = 24;

export const getFormattedDuration = (duration) => {
  if (duration) {
    const formattedDuration = `${duration.slice(ZERO, TWO)}H ${duration.slice(
      THREE,
      FIVE
    )}M`;
    return formattedDuration;
  }
  return EMPTY_STRING;
};

export const getTotalDuration = (itineraries) => {
  const getSum = (t1, t2) => {
    const [t1Hrs, t1Mins] = t1.split(":");
    const [t2Hrs, t2Mins] = t2.split(":");
    const totalT1 = Number(t1Hrs) * MINUTES_IN_HOUR + Number(t1Mins);
    const totalT2 = Number(t2Hrs) * MINUTES_IN_HOUR + Number(t2Mins);
    const totalMins = totalT1 + totalT2;
    return (
      String(parseInt(totalMins / MINUTES_IN_HOUR)).padStart(TWO, "0") +
      ":" +
      String(totalMins % MINUTES_IN_HOUR).padStart(TWO, "0")
    );
  };

  return itineraries.reduce(
    (duration, itinerary) => getSum(duration, itinerary.duration),
    TIME_ZERO
  );
};

export const calculateTotalDuration = (layoverDurations, flightDuration) => {
  let totalMinutes = ZERO;

  layoverDurations.forEach((duration) => {
    const parts = duration.split(" ");
    parts.forEach((part) => {
      const value = parseInt(part.slice(ZERO, -1));
      if (part.endsWith("D")) {
        totalMinutes += value * HOURS_IN_DAY * MINUTES_IN_HOUR;
      } else if (part.endsWith("H")) {
        totalMinutes += value * MINUTES_IN_HOUR;
      } else if (part.endsWith("M")) {
        totalMinutes += value;
      }
    });
  });

  const flightDurationParts = flightDuration.split(":");
  const flightHours = parseInt(flightDurationParts[ZERO]);
  const flightMinutes = parseInt(flightDurationParts[ONE]);

  totalMinutes += flightHours * MINUTES_IN_HOUR + flightMinutes;

  const durationMoment = moment.duration(totalMinutes, "minutes");

  const totalDays = durationMoment.days();
  const remainingHours = (totalDays * HOURS_IN_DAY + durationMoment.hours())
    .toString()
    .padStart(2, "0");
  const remainingMinutes = durationMoment.minutes().toString().padStart(2, "0");

  let result = EMPTY_STRING;

  if (remainingHours > ZERO) result += `${remainingHours}H `;
  result += `${remainingMinutes}M`;

  return result.trim();
};

export const convertMinutesToHours = (minutes) => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = remainingMinutes.toString().padStart(2, '0');
  return `${formattedHours}H ${formattedMinutes}M`;
}

export const formatDurationToTime = (totalDuration) => {
  let [hours, minutes] = totalDuration.match(DURATION_REGEX).slice(ONE, THREE);
  hours = hours.padStart(TWO, ZERO.toString());
  minutes = minutes.padStart(TWO, ZERO.toString());

  const duration = `${hours}:${minutes}`;
  return duration;
}
