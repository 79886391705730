import { useState, useEffect } from "react";
import { get, isEmpty } from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useCollapse } from "react-collapsed";
import { useTranslation } from "react-i18next";
import {
  selectFlightPriceInfo,
  selectFlightPriceReq,
} from "../../../screens/FlightResults";
import FlightJourneyDetails from "../../../screens/FlightResults/FlightJourneyDetails";
import {
  selectTotalSSRPrice,
  selectSelectedPromoCode,
  setPromoCode,
  selectSelectedLCCMeals,
  selectSelectedLCCSeats,
  selectSelectedLCCBaggages,
  selectTripStatus,
} from "../../../screens/Booking/FlightBookings";
import Tabs from "../../organisms/Tabs/Tabs";
import { selectSearchFilters } from "../../../components/organisms/Search";
import { PriceBreakdownSkeleton } from "../../organisms/AppSkeletons";
import { selectSelectedTripId } from "../../../screens/Booking/Trips";
import FareRulesModal from "../../organisms/FareRuleDetails";
import { selectCountryInfo } from "../../../screens/Profile";
import Spinner, { SPINNER_NAMES } from "../../organisms/Spinner";
import {
  getFormattedLongDate,
  getQueryParams,
  getStopLabel,
  priceFormatter,
  timeFormatter,
  getPriceAfterDiscount,
  convertedPricesFromStrings,
  getSSRPrice,
  formatPriceWithCommas,
} from "../../../helper";
import {
  ChevronDown,
  ChevronUp,
  RenderSVG,
  DiscountIcon,
  Cross,
} from "../../../assets/icons";
import {
  CURRENCY_SYMBOLS,
  DEFAULT_VALUES,
} from "../../../constants";
import classNames from "classnames";
import { selectCurrentUserInfo } from "../../../screens/Auth";

const { EMPTY_ARRAY, ZERO, ONE, EMPTY_OBJECT } = DEFAULT_VALUES;
const { PRICE } = SPINNER_NAMES;
const { INR } = CURRENCY_SYMBOLS;

const INITIAL_FARE_CHARGES = {
  totalBase: ZERO,
  totalTax: ZERO,
  totalGrand: ZERO,
  publishedFare: ZERO,
  reissuancePrice: ZERO,
  reissueDifference: ZERO,
  flightDiscount: ZERO,
  offeredFare: ZERO,
};

const reissuanceFlightOptions = [
  { label: "differenceAmount", key: "reissueDifference", primary: true },
  { label: "reissueChargesBySupplier", key: "reissuancePrice", primary: true },
  { label: "seat", key: "selectedSeats", primary: false },
  { label: "meal", key: "selectedMeals", primary: false },
  { label: "baggage", key: "selectedBaggages", primary: false },
];

const flightFareOptions = [
  { label: "baseFare", key: "totalBase", primary: true },
  { label: "taxAndFees", key: "totalTax", primary: false },
  { label: "seat", key: "selectedSeats", primary: false },
  { label: "meal", key: "selectedMeals", primary: false },
  { label: "baggage", key: "selectedBaggages", primary: false },
  { label: "discount", key: "flightDiscount", primary: false },
];

const getFlightTotalPrice = (data) =>
  data.reduce((total, { price }) => {
    total += price.price.grandTotal;
    return total;
  }, ZERO);

const getUpdatedFlightCharges = (allFlightPriceRes = []) =>
  allFlightPriceRes.reduce((totals, { flightPriceInfo }) => {
    const { price, fareDetails } = flightPriceInfo || EMPTY_OBJECT;
    const discount = Number(
      (price.grandTotal - (price.offeredFare + price.tavaMarkup)).toFixed(2)
    );
    return {
      totalBase: totals.totalBase + Number(price.baseFare),
      totalTax:
        totals.totalTax +
        Number(fareDetails.Tax) +
        Number(fareDetails.OtherCharges),
      flightDiscount: totals.flightDiscount + Number(discount),
      offeredFare:
        totals.offeredFare + Number(price.offeredFare + price.tavaMarkup),
      totalGrand: totals.totalGrand + Number(price.grandTotal),
      reissuancePrice:
        totals.reissuancePrice + price.reissuancePrice
          ? Number(price.reissuancePrice)
          : ZERO,
      reissueDifference:
        totals.reissueDifference + price.reissueDifference
          ? Number(price.reissueDifference)
          : ZERO,
    };
  }, INITIAL_FARE_CHARGES);

const formatPrice = (price, currencySymbol) =>
  `${price < ZERO ? "-" : ""} ${currencySymbol} ${priceFormatter(
    parseFloat(Math.abs(price).toFixed(2))
  )}`;

const getTotalPaxCount = (passengers = {}) =>
  Object.values(passengers).reduce((total, pax) => {
    total = total + +pax;
    return total;
  }, ZERO);

const FlightPricingSegmentsCard = ({ segments = [] }) => {
  const { t } = useTranslation();
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
    duration: 500,
    defaultExpanded: false,
  });
  const firstSegment = segments[ZERO];
  const lastSegment = segments[segments.length - ONE];
  const totalStops = segments.length - ONE;

  const { departure = EMPTY_OBJECT } = firstSegment || EMPTY_OBJECT;
  const { arrival = EMPTY_OBJECT } = lastSegment || EMPTY_OBJECT;

  return (
    <div>
      <div>
        <div className='p-4 flex justify-between items-center bg-white rounded-lg shadow-md border border-gray-200'>
          <div className='flex flex-col space-y-3'>
            <div className='text-xl font-bold text-gray-800'>
              {departure.airportName} {departure?.iataCode} - {arrival.airportName} {arrival.iataCode}
            </div>
            <div className='flex items-center justify-between text-gray-600 text-base'>
              <div className='flex items-center space-x-2'>
                <div>{firstSegment.carrierName}</div>
                <div>•</div>
                <div>{getFormattedLongDate(departure.date, { day: "numeric", month: "short" })}</div>
              </div>
              <div className='flex items-center space-x-2'>
                <div className='font-medium'>{timeFormatter(departure.time)}</div>
                <div className='bg-gray-300 rounded-full w-6 h-1'></div>
                <div className='font-medium'>{timeFormatter(arrival.time)}</div>
              </div>
              <div>{getStopLabel(totalStops, t)}</div>
            </div>
          </div>
          <div {...getToggleProps()}>
            <RenderSVG
              Svg={isExpanded ? ChevronUp : ChevronDown}
              alt='chevron-up-down'
              style={{ color: "black" }}
              height={20}
              width={20}
            />
          </div>
        </div>
      </div>
      <div {...getCollapseProps()}>
        <div className='px-4 py-3'>
          <FlightJourneyDetails segments={segments} />
        </div>
      </div>
    </div>
  );
};

export const PricingDetailsSummaryCard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const appliedPromoCode = useSelector(selectSelectedPromoCode) || EMPTY_OBJECT;
  const selectedCountryInfo = useSelector(selectCountryInfo);
  const selectedTotalSSRPrice = useSelector(selectTotalSSRPrice);
  const selectedMeals = useSelector(selectSelectedLCCMeals);
  const selectedSeats = useSelector(selectSelectedLCCSeats);
  const selectedBaggages = useSelector(selectSelectedLCCBaggages);
  const flightPriceInfo = useSelector(selectFlightPriceInfo);
  const flightPriceReq = useSelector(selectFlightPriceReq) || EMPTY_ARRAY;
  const [previousFareCharges, setPreviousFareCharges] =
    useState(INITIAL_FARE_CHARGES);
  const [updatedFareCharges, setUpdatedFareCharges] = useState(EMPTY_OBJECT);
  const [priceMapping, setPriceMapping] = useState(EMPTY_ARRAY);

  const selectedFlightTotalPrice = getFlightTotalPrice(flightPriceReq);
  const defaultFinalCharges = {
    discountAmount: ZERO,
    updatedPrice: selectedFlightTotalPrice,
  };
  const [finalCharges, setFinalCharges] = useState(defaultFinalCharges);
  const currencySymbol = get(selectedCountryInfo, "currency.symbol", INR);

  const { isReissuanceFlight } = flightPriceReq[ZERO] || EMPTY_OBJECT;
  const { tava_code } = appliedPromoCode;
  const requiredOptions = isReissuanceFlight
    ? reissuanceFlightOptions
    : flightFareOptions;

  useEffect(() => {
    if (isEmpty(flightPriceReq)) return;
    const previousFareCharges1 = {
      ...INITIAL_FARE_CHARGES,
      totalBase: selectedFlightTotalPrice,
      totalGrand: selectedFlightTotalPrice,
    };
    setPreviousFareCharges(previousFareCharges1);
  }, [flightPriceReq]);

  useEffect(() => {
    if (isEmpty(flightPriceInfo)) return;
    const latestPrices =
      !isEmpty(flightPriceInfo) && getUpdatedFlightCharges(flightPriceInfo);
    setUpdatedFareCharges(latestPrices);
  }, [previousFareCharges, flightPriceInfo]);

  useEffect(() => {
    if (isEmpty(updatedFareCharges)) return;
    const { totalGrand, offeredFare } = updatedFareCharges || EMPTY_OBJECT;
    const requiredPrice = isReissuanceFlight ? totalGrand : offeredFare;
    const finalPrices = getPriceAfterDiscount(appliedPromoCode, requiredPrice);
    setFinalCharges({
      ...finalPrices,
      updatedPrice: finalPrices.updatedPrice,
    });
  }, [appliedPromoCode, updatedFareCharges, selectedTotalSSRPrice]);

  useEffect(() => {
    if (isEmpty(finalCharges) || isEmpty(appliedPromoCode)) return;
    const { discountAmount, updatedPrice } = finalCharges || EMPTY_OBJECT;
    if (updatedPrice !== appliedPromoCode.offeredPrice) {
      const promoCode = {
        ...appliedPromoCode,
        offeredPrice: updatedPrice,
        ...(discountAmount && { discountAmount }),
      };
      dispatch(setPromoCode(promoCode));
    }
  }, [dispatch, appliedPromoCode]);

  const getFareValue = (key, fareChargesObject) => {
    switch (key) {
      case "selectedMeals":
        return getSSRPrice(selectedMeals);
      case "selectedSeats":
        return getSSRPrice(selectedSeats);
      case "selectedBaggages":
        return getSSRPrice(selectedBaggages);
      default:
        return fareChargesObject[key];
    }
  };

  const getTotalAmount = () => {
    const formattedPrice = priceFormatter(
      (finalCharges.updatedPrice + selectedTotalSSRPrice).toFixed(2)
    );
    const numericPrice = parseFloat(formattedPrice.replace(/,/g, ""));
    return formatPriceWithCommas(numericPrice);
  };

  useEffect(() => {
    const formattedOptions = requiredOptions.map(({ label, key, primary }) => {
      const fareCharges = isEmpty(updatedFareCharges)
        ? previousFareCharges
        : updatedFareCharges;
      const requiredfare = getFareValue(key, fareCharges);
      const card = {
        label: t(`travelerInfo.fareCard.${label}`),
        value: formatPrice(requiredfare, currencySymbol),
        hidePrice: !primary && !requiredfare,
      };
      return card;
    });
    setPriceMapping(formattedOptions);
  }, [updatedFareCharges, previousFareCharges, finalCharges]);

  return (
    <div>
      <Spinner
        name={PRICE}
        showSkeleton={true}
        loaderComponent={<PriceBreakdownSkeleton />}
      >
        {priceMapping.map(
          ({ label, hidePrice, value }) =>
            !hidePrice && (
              <div
                key={label}
                className={classNames(
                  "flex items-center font-normal text-contrast-900 justify-between py-1",
                  {
                    "text-green-400 font-semibold": label == "Discount",
                  }
                )}
              >
                <div className='text-base'>{label}</div>
                <div>
                  {value}
                </div>
              </div>
            )
        )}
        {tava_code && (
          <div className='flex items-center justify-between py-1 rounded text-primary-600'>
            <div className='flex items-center justify-between text-base font-normal'>
              <RenderSVG
                Svg={DiscountIcon}
                alt='discount'
                className='w-8 h-6 pr-2 relative'
              />
              {t("travelerInfo.fareCard.appliedPromocode", { code: tava_code })}
            </div>
            <div className='flex items-center justify-between font-medium text-green-600'>
              - {currencySymbol}&nbsp;
              {priceFormatter(appliedPromoCode.discountAmount)}
              <button
                type='button'
                className='flex items-center justify-end pl-2'
                onClick={() => dispatch(setPromoCode(EMPTY_OBJECT))}
              >
                <RenderSVG
                  Svg={Cross}
                  alt='Check Icon'
                  className='h-4 relative rounded-full'
                  color='#FF0000'
                />
              </button>
            </div>
          </div>
        )}
        <div className='flex items-center justify-between pt-1'>
          <div className='text-primary-600 text-xl font-bold'>
            {t("travelerInfo.fareCard.totalDue")}
          </div>
          <div className='text-primary-600 text-xl font-bold'>
            {currencySymbol}&nbsp;
            {getTotalAmount()}
          </div>
        </div>
      </Spinner>
    </div>
  );
};

const TripDetailsTab = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const selectedFilters = useSelector(selectSearchFilters);
  const selectedTripId = useSelector(selectSelectedTripId);
  const currentUserInfo = useSelector(selectCurrentUserInfo);
  const priceRequest = useSelector(selectFlightPriceReq) || EMPTY_ARRAY;
  const tripStatus = useSelector(selectTripStatus);
  const [shouldShowFareRules, setShouldShowFareRules] = useState(false);
  const { isReissuanceFlight } = priceRequest[ZERO] || EMPTY_OBJECT;
  const { tripType, passengersCount } = selectedFilters || EMPTY_OBJECT;

  const queryParams = getQueryParams(selectedFilters);
  const flightSearchRoute = `/bookings/${selectedTripId}/flights?${queryParams}`;
  const myTripFlightSearchRoute = `/users/${currentUserInfo?.id}/bookings/${selectedTripId}/flights?${queryParams}`
  const totalCount = getTotalPaxCount(passengersCount);
  const { isAISearchInitiated } = tripStatus;

  const handleOpenFareRuleModel = () => setShouldShowFareRules(true);

  return (
    <div className='py-6'>
      <div className='px-6'>
        <div className='flex items-center justify-between pb-2'>
          {tripType ? (
            <div className='text-xl font-bold text-contrast-900'>
              {t(`searchSection.tripTypes.${tripType}`)} • {totalCount}{" "}
              {totalCount > ONE
                ? t("travelerInfo.travelers")
                : t("travelerInfo.traveler")}
            </div>
          ) : (
            <div className='text-xl font-bold text-contrast-900'>
              {t("travelerInfo.traveler")}
            </div>
          )}
          {!isReissuanceFlight && !isAISearchInitiated &&(
            <div>
              <button
                className='py-2 px-4 flex items-center gap-2 rounded-md bg-white hover:bg-contrast-50 active:bg-white border border-contrast-300 shadow-sm text-sm text-contrast-700 font-medium'
                onClick={() => {
                  if (window.location.pathname.includes(`/users/${currentUserInfo?.id}/bookings`)){
                    navigate(myTripFlightSearchRoute)
                  }
                  else 
                  navigate(flightSearchRoute)
                }}
              >
                {t("travelerInfo.editTrip")}
              </button>
            </div>
          )}
        </div>
        <div className='grid py-2 grid-cols-2'>
          <div className='mt-3'>
            <hr />
          </div>
          <div className='flex justify-end'>
            <Spinner name={PRICE} loaderComponent={<></>}>
              <button
                className='flex text-xs sm:text-sm text-end text-primary-600 justify-end'
                onClick={handleOpenFareRuleModel}
              >
                {t("fareRules.viewFareRule")}
              </button>
            </Spinner>
          </div>
        </div>
        <div>
          {priceRequest?.map(({ price }) =>
            price.itineraries.map(({ segments }, index) => (
              <FlightPricingSegmentsCard segments={segments} key={index} />
            ))
          )}
        </div>
        <hr className='py-3' />
        <PricingDetailsSummaryCard />
      </div>
      {shouldShowFareRules && (
        <FareRulesModal
          shouldShowFareRules={shouldShowFareRules}
          setShouldShowFareRules={setShouldShowFareRules}
        />
      )}
    </div>
  );
};

const TripOverviewCard = () => {
  const { t } = useTranslation();

  const tabs = [
    {
      id: 1,
      title: t("travelerInfo.trip"),
      element: <TripDetailsTab />,
      default: true,
    },
  ];

  return (
    <div className='bg-white rounded-lg border border-contrast-200 overflow-hidden'>
      <Tabs tabs={tabs} />
    </div>
  );
};

export const OtherInfo = ({ header, data }) => {
  const { tableHeader, penalties, penaltyInfo } = data;

  return (
    <div className='bg-white rounded'>
      <div className='font-semibold border-b-[1px] p-2'>{header}</div>
      <div className='m-2 border'>
        <table className='w-full'>
          <thead className='text-sm md:text-xs'>
            <tr>
              {tableHeader.map(({ title, subtitle }) => (
                <th key={title} className='py-2 border-b text-start'>
                  <div className='text-base font-semibold'>{title}</div>
                  <div className='text-sm text-contrast-500'>{subtitle}</div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className='text-xs text-start'>
            {penalties?.map(({ timeRange, charges }, index) => (
              <tr
                key={timeRange}
                className={
                  index % 2 === 0
                    ? "bg-contrast-50"
                    : "bg-white hover:bg-contrast-100"
                }
              >
                <td className='py-1.5 border-t border-b'>{timeRange}</td>
                <td className='py-1.5 border-t border-b font-bold'>
                  {convertedPricesFromStrings(charges)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className='mt-2 text-xs font-normal text-black'>{penaltyInfo}</div>
      </div>
    </div>
  );
};

export default TripOverviewCard;
