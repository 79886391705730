import {
  getSSRPrice,
  getFormattedItinerary,
  mapTravelersInfo,
  priceFormatter,
} from "./index";
import { DEFAULT_EXCHANGE_RATE, DEFAULT_VALUES, TBO_FARE_TYPES } from "../constants";
import { get, isEmpty } from "lodash";

const { ZERO, EMPTY_ARRAY, EMPTY_STRING, EMPTY_OBJECT } = DEFAULT_VALUES;
const { REGULAR } = TBO_FARE_TYPES;
const INSTANT = "INSTANT";
const AGENT_REFERENCE_NO = "innostax";
const INR = "INR";
const FLIGHT = "flight";

const convertPriceToReqCurrency = (price) => {
  const convertedPrice = priceFormatter(price).toString();
  return parseFloat(convertedPrice.replace(/,/g, ""));
};

const getSSRPricePerFlight = (travelerDetails, initialSSRPrice) => {
  return travelerDetails.reduce((ssrPrice, traveler) => {
    const { mealDetails, seatDetails, baggageDetails } = traveler;
    const addPrices = (details, key) => {
      if (details?.value && Array.isArray(details.value)) {
        details.value.forEach((detail) => {
          if (typeof detail.price === "number") {
            ssrPrice[key] += detail.price;
          }
        });
      }
    };
    addPrices(mealDetails, "mealAmount");
    addPrices(seatDetails, "seatAmount");
    addPrices(baggageDetails, "baggageAmount");

    return ssrPrice;
  }, initialSSRPrice);
};

const getAirlineTaxes = (fareBreakdown) =>
  fareBreakdown.reduce((totalTax, paxDetails) => {
    totalTax += paxDetails.Tax;
    return totalTax;
  }, ZERO);

const getCombineFlightFare = (flightsPriceRes) =>
  flightsPriceRes.reduce((grandTotal, { flightPriceInfo }) => {
    const { price } = flightPriceInfo;
    const { offeredFare, tavaMarkup } = price;

    return grandTotal + offeredFare + tavaMarkup;
  }, ZERO);

const getGrossFareAfterDiscountApplied = (
  selectedPromoCode,
  SSRIncludedFare
) => {
  const { discountAmount = ZERO } = selectedPromoCode;
  const fareAfterDiscountAmountApplied = SSRIncludedFare - discountAmount;
  const absoluteFare = fareAfterDiscountAmountApplied;
  return absoluteFare;
};

const getUpdatedPaymentDetails = (flightsPriceRes, booking, countryInfo, exchangeRates) => {
  const {
    selectedPromoCode = {},
    selectedLCCSeats,
    selectedLCCBaggages,
    selectedLCCMeals,
  } = booking;
  const {
    discountAmount = ZERO,
    discountedPrice = ZERO,
    promocode = EMPTY_OBJECT,
  } = selectedPromoCode;
  const { flightPriceInfo = EMPTY_OBJECT } =
    flightsPriceRes[ZERO] || EMPTY_OBJECT;

  const {
    price: { currency },
  } = flightPriceInfo;

  const currencyDetails = countryInfo?.currency;
  const currencyCode = currencyDetails?.code;

  const currencyInfo = {
    ...currencyDetails,
    exchangeRate: exchangeRates[currencyCode] || DEFAULT_EXCHANGE_RATE
  }

  const baggageAmount = getSSRPrice(selectedLCCBaggages);
  const mealAmount = getSSRPrice(selectedLCCMeals);
  const seatAmount = getSSRPrice(selectedLCCSeats);

  // calculating the combine fare for all selected flights.
  const grandTotal = getCombineFlightFare(flightsPriceRes);
  const formattedGrandTotal = parseFloat(grandTotal.toFixed(2));

  // calculating the fare including with SSR charges.
  const totalSSRPrice = baggageAmount + mealAmount + seatAmount;
  const SSRIncludedFare = formattedGrandTotal + totalSSRPrice;

  // calculating the gross fare after deducted discounted fare.
  const grossAmount = getGrossFareAfterDiscountApplied(
    selectedPromoCode,
    SSRIncludedFare
  );

  const fareDetails = {
    currency: currencyCode,
    discountAmount: convertPriceToReqCurrency(discountAmount),
    discountedPrice: convertPriceToReqCurrency(discountedPrice),
    grandTotalCurrency: currency,
    grandTotal: grossAmount,
    paidAmount: convertPriceToReqCurrency(grossAmount),
    otherServicesAmount: convertPriceToReqCurrency(totalSSRPrice),
    otherServicesInfo: {
      mealAmount: convertPriceToReqCurrency(mealAmount),
      seatAmount: convertPriceToReqCurrency(seatAmount),
      baggageAmount: convertPriceToReqCurrency(baggageAmount),
    },
    promocode,
    currencyInfo: currencyInfo,
  };
  return fareDetails;
};

const getFormattedFareRules = (fareRules) =>
  fareRules.map((fareRule) => {
    return Object.keys(fareRule).reduce((newObj, key) => {
      const newKey = key.charAt(0).toLowerCase() + key.slice(1);
      newObj[newKey] = fareRule[key];
      return newObj;
    }, {});
  });

const getJourneyDetails = (
  flightPriceReq,
  flightPriceRes,
  booking,
  countryInfo,
  exchangeRates
) => {
  const journeyDetails = [];

  const { selectedPromoCode = {}, selectedTravelers } = booking;

  const {
    discountAmount = ZERO,
    discountedPrice = ZERO,
    promocode = EMPTY_OBJECT,
  } = selectedPromoCode;

  const currencyDetails = countryInfo?.currency;
  const currencyCode = currencyDetails?.code;

  const currencyInfo = {
    ...currencyDetails,
    exchangeRate: exchangeRates[currencyCode] || DEFAULT_EXCHANGE_RATE
  }

  const profileDetails = get(selectedTravelers, "[0].profileDetails", {});
  const { state = "", nationality = {} } = profileDetails;

  flightPriceReq.forEach((flightDetails) => {
    const { isLCC, price, source, journeyId, resultIndex } = flightDetails;
    const currentFlightPriceRes = flightPriceRes.find(
      (flightInfo) => flightInfo.journeyId === journeyId
    );
    const { flightPriceInfo = EMPTY_OBJECT } =
      currentFlightPriceRes || EMPTY_OBJECT;
    const {
      price: flightResPrice,
      fareBreakdown,
      fareType = REGULAR,
      fareRules = EMPTY_ARRAY,
      airlineSource,
    } = flightPriceInfo;
    const { currency, baseFare, publishedFare, offeredFare, tavaMarkup } =
      flightResPrice || EMPTY_OBJECT;
    const { itineraries } = price;

    // convert the fareRules keys as camel casing
    const formattedFareRules = getFormattedFareRules(fareRules);

    //mapped traveler details
    const travelerDetails = mapTravelersInfo(
      flightDetails,
      booking,
      currentFlightPriceRes
    );

    const taxFare = getAirlineTaxes(fareBreakdown);

    const initialSSRPrice = {
      mealAmount: ZERO,
      seatAmount: ZERO,
      baggageAmount: ZERO,
    };

    const SSRPricePerFlight = getSSRPricePerFlight(
      travelerDetails,
      initialSSRPrice
    );
    const totalSSRPrice =
      SSRPricePerFlight.mealAmount +
      SSRPricePerFlight.seatAmount +
      SSRPricePerFlight.baggageAmount;

    const formattedGrandTotal = parseFloat(
      (offeredFare + tavaMarkup + totalSSRPrice).toFixed(2)
    );

    //getting flight segment itinerary details
    const formattedItinerary = getFormattedItinerary(itineraries);

    const paymentsDetails = {
      currency: currencyCode,
      discountAmount: convertPriceToReqCurrency(discountAmount),
      discountedPrice: convertPriceToReqCurrency(discountedPrice),
      grandTotal: formattedGrandTotal,
      grandTotalCurrency: currency,
      paidAmount: convertPriceToReqCurrency(formattedGrandTotal),
      otherServicesAmount: convertPriceToReqCurrency(totalSSRPrice),
      otherServicesInfo: {
        mealAmount: convertPriceToReqCurrency(initialSSRPrice.mealAmount),
        seatAmount: convertPriceToReqCurrency(initialSSRPrice.seatAmount),
        baggageAmount: convertPriceToReqCurrency(initialSSRPrice.baggageAmount),
      },
      promocode,
      state,
      country: nationality.name,
      countryCode: nationality.isoCode,
      currencyInfo: currencyInfo
    };

    const journeyDetail = {
      index: resultIndex,
      journeyId,
      provider: source,
      isLCC,
      fareType,
      fareRules: formattedFareRules,
      itineraries: formattedItinerary,
      travelerDetails,
      price: {
        currency,
        baseFare,
        taxFare,
        publishedFare,
        offeredFare,
        grandTotal: formattedGrandTotal,
        otherServicesAmount: totalSSRPrice,
        otherServicesInfo: initialSSRPrice,
        tavaMarkup,
        paymentsDetails,
      },
      airlineSource,
    };
    if (resultIndex.includes("OB")) journeyDetails.unshift(journeyDetail);
    else journeyDetails.push(journeyDetail);
  });
  return journeyDetails;
};

export const mapBookingAPIRequest = (store, flightSearchFiltersRequest) => {
  const { pricing, search, profile, booking, trips } = store;
  const { flightPriceInfo, flightPriceReq } = pricing;
  const { userInfo, countryInfo, exchangeRates } = profile;
  const { email = EMPTY_STRING } = userInfo || EMPTY_OBJECT;
  const { traceId, endUserIp, source , flightSelectedElement } = flightPriceReq[ZERO];
  const { searchFilters, reIssuanceFlightData , flightsReqBody} = search;
  const { selectedTripId } = trips;
  const { tripType } = searchFilters;
  const { flightId, isReissuance } = reIssuanceFlightData;

  //getting the flight paymentsDetails
  const paymentsDetails = getUpdatedPaymentDetails(
    flightPriceInfo,
    booking,
    countryInfo,
    exchangeRates
  );

  //mapped the flight journey details
  const journeyDetails = getJourneyDetails(
    flightPriceReq,
    flightPriceInfo,
    booking,
    countryInfo,
    exchangeRates
  );
  const fareDetails = {
    orderType: INSTANT,
    tavaBookingId: selectedTripId,
    agentReferenceNo: AGENT_REFERENCE_NO,
    prefferedCurrency: INR,
    traceId,
    endUserIp,
    provider: source,
    accountEmail: email,
    tripType : isEmpty(tripType) ? flightSearchFiltersRequest?.tripType : tripType,
    paymentsDetails,
    journeyDetails,
    ...(isReissuance && { parentBookingId: flightId }),
  };
  const flightSearchRequest = isEmpty(flightsReqBody) ? flightSearchFiltersRequest : flightsReqBody;
  const flightRebookTimestamp = isEmpty(trips.flightRebookTimestamp) ? flightSearchRequest.timestamp : trips.flightRebookTimestamp
  const rebookMetaData = {
    flightSearchRequest,
    flightSelectedElement: flightSelectedElement,
    flightRebookTimestamp
  }
  const bookingRequest = {
    flightBookingRequest: fareDetails,
    bookingType: FLIGHT,
    rebookMetaData : rebookMetaData,
  };
  return bookingRequest;
};
