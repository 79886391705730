import { useSelector } from "react-redux";
import { get, unescape } from "lodash";
import classNames from "classnames";
import {
  BookingImportantInfo,
  BookingLinks,
  BookingPricing,
  HotelPassengersInfo
} from "../../../components/organisms/HotelBookingInfoSections";
import {
  renderStars,
  getFormattedAddress,
  getFormattedDateObject,
  getFutureFormattedDateObject,
  getLeadGuestInfo,
  getDayDifference,
  getFormattedLongDate,
  formatDateWithLeadingZeros,
  getDateDetails
} from "../../../helper";
import { DEFAULT_VALUES, BOOKING_STATUS_CODES } from "../../../constants";
import { selectSelectedTrip } from "../Trips";
import { hasCheckInDatePassed } from "../../../helper/hasCheckInDatePassed";

const { ZERO, ONE, EMPTY_OBJECT, EMPTY_ARRAY, EMPTY_STRING } = DEFAULT_VALUES;
const { FAILED } = BOOKING_STATUS_CODES;

const calculateAdultsAndChildren = (hotelRooms) => {
  let totalPassengers = {
    adults: 0,
    children: 0,
  };

  hotelRooms.forEach((room) => {
    const roomPassengers = room.hotelPassenger.reduce(
      (total, passenger) => {
        if (passenger?.paxType === 1) total.adults = total.adults + 1;
        else total.children = total.children + 1;
        return total;
      },
      { adults: 0, children: 0 }
    );

    totalPassengers = {
      adults: totalPassengers.adults + roomPassengers.adults,
      children: totalPassengers.children + roomPassengers.children,
    };
  });

  return totalPassengers;
};
const DATE_FORMATS = {
  NUMERIC: "numeric",
  TWO_DIGIT: "2-digit",
  SHORT: "short",
};
const { NUMERIC, TWO_DIGIT, SHORT } = DATE_FORMATS;

const Hotel = () => {
  const selectedTrip = useSelector(selectSelectedTrip);

  const {
    hotels = EMPTY_ARRAY,
    id = EMPTY_STRING,
    createdAt = EMPTY_STRING,
    tripStartDate = EMPTY_STRING
  } = selectedTrip || EMPTY_OBJECT;
  const passengersData = get(
    hotels,
    "0.bookingReqJson.hotelRoomsDetails",
    EMPTY_ARRAY
  );

  const {
    blockRoomResJson = EMPTY_OBJECT,
    totalPrice = ZERO,
    bookingReqJson = EMPTY_OBJECT,
    tenantBookingStatus,
    providerBookingId,
    blockRoomReqJson = EMPTY_OBJECT,
  } = hotels[ZERO] || EMPTY_OBJECT;
  const { blockRoomResult = EMPTY_OBJECT } = blockRoomResJson;
  const {
    hotelPolicyDetail = EMPTY_STRING,
    starRating = EMPTY_STRING,
    addressLine1 = EMPTY_STRING,
    hotelName = EMPTY_STRING,
  } = blockRoomResult;
  const { hotelRoomsDetails = EMPTY_ARRAY } = bookingReqJson;
  const { hotelPassenger = EMPTY_ARRAY } = hotelRoomsDetails[0] || EMPTY_OBJECT;

  const { adults, children } = calculateAdultsAndChildren(hotelRoomsDetails);
  const { dayRates = EMPTY_ARRAY, cancellationPolicy = EMPTY_STRING } = get(
    blockRoomResult,
    "hotelRoomsDetails.0",
    EMPTY_OBJECT
  );
  const { profileDetails } = hotelPassenger.length
    ? getLeadGuestInfo(hotelPassenger)
    : EMPTY_OBJECT;
  const decodedHotelInfo = unescape(hotelPolicyDetail);

  const checkInDateObject = dayRates[ZERO];
  const checkOutDateObject = dayRates.slice(-1)[ZERO];
  let checkIn;
  let checkOut;
  let totalDays;
  if ((checkInDateObject, checkOutDateObject)) {
    checkIn = getFormattedDateObject(checkInDateObject.date);
    checkOut = getFutureFormattedDateObject(checkOutDateObject.date);
    totalDays = getDayDifference(
      checkInDateObject.Date,
      checkOutDateObject.Date
    );
  }

  if (!checkInDateObject)
     checkIn = getDateDetails(blockRoomReqJson?.checkInDate);
  if (!checkOutDateObject)
     checkOut = getDateDetails(blockRoomReqJson?.checkOutDate);

  const isPastCheckInDate = hasCheckInDatePassed(checkIn);
  const { title, firstName, lastName } = profileDetails || EMPTY_OBJECT;
  const noOfRooms = hotelRoomsDetails.length;
  const hotelBookingDuration = totalDays + ONE || ONE;
  const adultsText = `${adults} Adult${adults > 1 ? "s" : ""}`;
  const childsCountText = children > 1 ? "'s" : "";
  const childsText =
    children > 0 ? `| ${children} Child${childsCountText}` : "";
  const roomsText = `${noOfRooms} Room${noOfRooms > 1 ? "s" : ""}`;

  const cancellationMsgArr = cancellationPolicy
    ?.split("#^#")[1]
    ?.replaceAll("#!#", "")
    ?.split("|")
    ?.filter((item) => item && item !== "undefined")
    ?.map((msg) => (
      <li className='list-item' key={msg}>
        {msg}
      </li>
    ));
    const options = { hour: NUMERIC, minute: TWO_DIGIT, hour12: true };
    const bookingTime = createdAt
      ? getFormattedLongDate(
          `${formatDateWithLeadingZeros(createdAt)}`,
          options
        ).split(",")[ONE]
      : "";

  return (
    <div className='py-16 relative'>
      <div className='container px-8 mx-auto -mt-12'>
        <div className='grid grid-cols-12 gap-8 '>
          <div className='col-span-12 md:col-span-8 flex flex-col gap-4'>
            <div className='flex flex-col gap-8'>
              <div className='bg-white rounded-lg border border-dashed border-contrast-300 shadow-sm'>
                <div>
                  <div
                    className={classNames(
                      "flex gap-6 px-4 py-3 border-b border-dashed border-contrast-300 justify-between ",
                      {
                        "bg-red-50 border-b border-dashed border-black-300 rounded-t-md":
                          tenantBookingStatus === FAILED,
                      }
                    )}
                  >
                    <h4
                      className={classNames("font-bold text-sm", {
                        "text-contrast-900 ": tenantBookingStatus !== FAILED,
                        "text-red-700": tenantBookingStatus === FAILED,
                      })}
                    >
                      {tenantBookingStatus === FAILED
                        ? "Hotel Booking Failed"
                        : "Hotel Booked"}
                    </h4>
                    <div className='flex flex-col gap-1'>
                      {id && (
                        <p className='text-contrast-800 font-semibold text-xs'>
                          Booking ID:{" "}
                          <span className='text-[#4338ca] bg-[#e0e7ff] px-2 py-0.5 rounded-2xl'>
                            {providerBookingId ? providerBookingId : id}
                          </span>
                        </p>
                      )}
                      {createdAt && (
                        <p className='font-semibold text-xs flex gap-2 items-center text-contrast-800'>
                          Booked On
                          <span className='text-primary-700 px-2 py-0.5 rounded-2xl bg-primary-100'>
                            {getFormattedLongDate(createdAt, {
                              day: NUMERIC,
                              month: SHORT,
                              year: NUMERIC,
                            }) +
                              " " +
                              bookingTime || EMPTY_STRING}
                          </span>
                        </p>
                      )}
                    </div>
                  </div>
                  <div
                    className={classNames(
                      "flex gap-6 px-4 py-3 border-b border-dashed border-contrast-300 justify-between",
                      {
                        "bg-red-50": tenantBookingStatus === FAILED,
                      }
                    )}
                  >
                    <div className='flex-1'>
                      <h4 className='text-contrast-900 font-bold text-base mb-2'>
                        {hotelName}
                      </h4>
                      <p className='text-contrast-700 text-xs mb-2'>
                        {getFormattedAddress(addressLine1)}
                      </p>
                      <div className='flex gap-2 items-center mb-2'>
                        <div className='flex items-center gap-0.5'>
                          {renderStars(starRating)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='p-4 bg-primary-50/30'>
                    <div className='grid grid-cols-12 gap-4'>
                      <div className='col-span-12 md:col-span-12 lg:col-span-6'>
                        <div className='flex gap-3 justify-between items-center'>
                          <div>
                            <span className='text-contrast-600 text-xs font-medium'>
                              Check In
                            </span>
                            <h5 className='text-xs  text-contrast-900 mb-1 font-bold'>
                              {checkIn ? (
                                <>
                                  {checkIn.day}{" "}
                                  <strong className='text-sm'>
                                    {`${checkIn.date} ${checkIn.month} `}
                                  </strong>
                                  {checkIn.year}
                                </>
                              ) : (
                                "..."
                              )}
                            </h5>
                          </div>
                          <div className='icon shrink-0'>
                            <div className='bg-contrast-200 font-medium text-contrast-800 rounded-2xl px-3 py-0.5 text-[10px]'>
                              {`${hotelBookingDuration} Night${
                                hotelBookingDuration > 1 ? "s" : ""
                              }`}
                            </div>
                          </div>
                          <div>
                            <span className='text-contrast-600 text-xs font-medium'>
                              Check Out
                            </span>
                            <h5 className='text-xs text-contrast-900 mb-1 font-bold'>
                              {checkOut ? (
                                <>
                                  {checkOut.day}{" "}
                                  <strong className='text-sm'>
                                    {`${checkOut.date} ${checkOut.month} `}
                                  </strong>
                                  {checkOut.year}
                                </>
                              ) : (
                                "..."
                              )}
                            </h5>
                          </div>
                        </div>
                      </div>
                      <div className='col-span-12 md:col-span-12 lg:col-span-6 flex justify-between md:justify-around gap-4'>
                        <div className='text-end'>
                          <span className='text-contrast-600 text-xs font-medium'>
                            Guest & Rooms
                          </span>
                          <h5 className='text-xs text-contrast-800 font-bold mb-1'>
                            {adultsText} {childsText} | {roomsText}
                          </h5>
                        </div>
                        <div>
                          <span className='text-contrast-600 text-xs font-medium'>
                            Primary Guest
                          </span>
                          <h5 className='text-xs  text-contrast-900 mb-1 font-bold'>
                            {title} {firstName} {lastName}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  {tenantBookingStatus !== FAILED && !isPastCheckInDate && (
                    <div className='p-4 bg-amber-50'>
                      <div className='flex items-center gap-2 pl-2'>
                        <ul className='text-xs text-green-700 flex flex-col !list-disc'>
                          {cancellationMsgArr}
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <BookingPricing
                amount={totalPrice}
                hotelRoomsInfo={hotelRoomsDetails}
                numberOfAdults={adults}
              />
               <HotelPassengersInfo passengersData = {passengersData} />
              {tenantBookingStatus !== FAILED && (
                <BookingImportantInfo decodedHotelInfo={decodedHotelInfo} />
              )}
            </div>
          </div>
          <div className='flex flex col col-span-12 md:col-span-4'>
            <BookingLinks
              bookingId={id}
              tenantBookingStatus={tenantBookingStatus}
              checkInDate={checkIn}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hotel;
