import { ReactComponent as Aeroplane } from "./aeroplane.svg";
import { ReactComponent as Apple } from "./apple.svg";
import { ReactComponent as ArrowLeft } from "./arrow-left.svg";
import { ReactComponent as Calendar } from "./calendar.svg";
import { ReactComponent as Information } from "./information.svg";
import { ReactComponent as Check } from "./check.svg";
import { ReactComponent as ChevronDownPrimary } from "./chevron-down-primary.svg";
import { ReactComponent as ChevronDown } from "./chevron-down.svg";
import { ReactComponent as Circle } from "./circle.svg";
import { ReactComponent as Close } from "./close.svg";
import { ReactComponent as Cross } from "./cross.svg";
import { ReactComponent as CurrencyDollar } from "./currency-dollar.svg";
import { ReactComponent as Facebook } from "./facebook.svg";
import { ReactComponent as GlobeAlt } from "./globe-alt.svg";
import { ReactComponent as Globe } from "./globe.svg";
import { ReactComponent as Google } from "./google.svg";
import { ReactComponent as Home } from "./home.svg";
import { ReactComponent as LocationMarkerLight } from "./location-marker-light.svg";
import { ReactComponent as LocationMarker } from "./location-marker.svg";
import { ReactComponent as Logout } from "./logout.svg";
import { ReactComponent as TripTavaWithBrand } from "./tripTavaWithBrand.svg";
import { ReactComponent as Mail } from "./mail.svg";
import { ReactComponent as MailOutline } from "./mail-outline.svg";
import { ReactComponent as Minus } from "./minus.svg";
import { ReactComponent as Menu } from "./menu.svg";
import { ReactComponent as Plus } from "./plus.svg";
import { ReactComponent as SearchIcon } from "./searchIcon.svg";
import { ReactComponent as Ticket } from "./ticket.svg";
import { ReactComponent as Users } from "./users.svg";
import { ReactComponent as ToggleDirection } from "./toggle-direction.svg";
import { ReactComponent as UserCircle } from "./user-circle.svg";
import { ReactComponent as Cloud } from "./cloud.svg";
import { ReactComponent as Lightning } from "./lightning.svg";
import { ReactComponent as CurrencyDollarDark } from "./currency-dollar-dark.svg";
import { ReactComponent as XCircle } from "./x-circle.svg";
import { ReactComponent as CheckGreen } from "./check-green.svg";
import { ReactComponent as CheckDark } from "./check-dark.svg";
import { ReactComponent as TripTavaLogo } from "./tripTavaLogo.svg";
import { ReactComponent as ChevronDownDark } from "./chevronDownDark.svg";
import { ReactComponent as LeftArrow } from "./leftArrow.svg";
import { ReactComponent as RightArrow } from "./rightArrow.svg";
import { ReactComponent as LineSeparator } from "./lineSeparator.svg";
import { ReactComponent as DotPattern } from "./dot-pattern.svg";
import { ReactComponent as Checkbox } from "./checkbox.svg";
import { ReactComponent as Filter } from "./filter.svg";
import { ReactComponent as ExclamationTriangle } from "./exclamationTriangle.svg";
import { ReactComponent as FlightIcon } from "./flight.svg";
import { ReactComponent as FlightDarkIcon } from "./flightDark.svg";
import { ReactComponent as HotelsIcon } from "./hotels.svg";
import { ReactComponent as HotelsDarkIcon } from "./hotelsDark.svg";
import { ReactComponent as CopyToClipboard } from "./copy-to-clipboard.svg";
import { ReactComponent as VacationsIcon } from "./vacations.svg";
import { ReactComponent as VacationDarkIcon } from "./vacationsDark.svg";
import { ReactComponent as TripTavaLogoMin } from "./tripTavaLogoMin.svg";
import { ReactComponent as Phone } from "./phone.svg";
import { ReactComponent as PhoneIcon } from "./phone-icon.svg";
import { ReactComponent as SpinnerIcon } from "./spinner.svg";
import { ReactComponent as Trash } from "./trash.svg";
import { ReactComponent as MenuVerticalIcon } from "./menuVertical.svg";
import { ReactComponent as CloseDark } from "./close-dark.svg";
import { ReactComponent as ChevronUp } from "./chevron-up.svg";
import { ReactComponent as FilterResetIcon } from "./filterReset.svg";
import { ReactComponent as TriangleExclaimation } from "./triangle-exclaimation.svg";
import { ReactComponent as Gender } from "./gender.svg";
import { ReactComponent as LeftIcon } from "./chevron-left.svg";
import { ReactComponent as RightIcon } from "./chevron-right.svg";
import { ReactComponent as Stripe } from "./stripe.svg";
import { ReactComponent as Razorpay } from "./razorpay.svg";
import { ReactComponent as PlusWhite } from "./plusWhite.svg";
import { ReactComponent as CheckWhite } from "./checkWhite.svg";
import { ReactComponent as Barcode } from "./barcode.svg";
import { ReactComponent as RewardsPoint } from "./rewardsPoint.svg";
import { ReactComponent as ExclamationCircleIcon } from "./exclamationCircle.svg";
import { ReactComponent as QuestionIcon } from "./questionmark.svg";
import { ReactComponent as CheckCircleIcon } from "./check-circle.svg";
import { ReactComponent as ArrowRoundTrip } from "./arrow-roundtrip.svg";
import { ReactComponent as ShowPasswordIcon } from "./showPasswordIcon.svg";
import { ReactComponent as HidePasswordIcon } from "./hidePasswordIcon.svg";
import { ReactComponent as Bed } from "./bed.svg";
import { ReactComponent as WhiteChevronDown } from "./white-chevron-down.svg";
import { ReactComponent as WhiteChevronUp } from "./white-chevron-up.svg";
import { ReactComponent as StarLight } from "./star-light.svg";
import { ReactComponent as Star } from "./star.svg";
import { ReactComponent as EditIcon } from "./edit.svg";
import { ReactComponent as SearchDarkIcon } from "./searchDarkIcon.svg";
import { ReactComponent as Booking } from "./booking.svg";
import { ReactComponent as Compass } from "./compass.svg";
import { ReactComponent as History } from "./history.svg";
import { ReactComponent as ApiLogs } from "./apiLogs.svg";
import { ReactComponent as PendingIcon } from "./pending.svg";
import { ReactComponent as ServiceIcon } from "./service.svg";
import { ReactComponent as EarningIcon } from "./earning.svg";
import { ReactComponent as DownloadIcon } from "./download.svg";
import { ReactComponent as LogoutIcon } from "./logoutIcon.svg";
import { ReactComponent as DiscountIcon } from "./discount.svg";
import { ReactComponent as InflationIcon } from "./inflationIcon.svg";
import { ReactComponent as Baggage } from "./baggage.svg";
import { ReactComponent as FlightCancel } from "./flightCancel.svg";
import { ReactComponent as Morning } from "./morning.svg";
import { ReactComponent as Noon } from "./noon.svg";
import { ReactComponent as Evening } from "./evening.svg";
import { ReactComponent as BaggageIcon } from "./baggageIcon.svg";
import { ReactComponent as DeparturePlaneIcon } from "./departurePlane.svg";
import { ReactComponent as ArrivalPlaneIcon } from "./arrivalPlane.svg";
import { ReactComponent as IndianCoinIcon } from "./indianRupeeCoin.svg";
import { ReactComponent as TicketSlash } from "./ticketSlash.svg";
import { ReactComponent as ThreeDotsLoading } from "./threeDotsLoading.svg";
import indianFlag from "./indianFlag.svg";
import Wave from "./wave.svg";
import { ReactComponent as bookingIcon } from "./bookingIcon.svg";
import { ReactComponent as SortDescIcon } from "./sortArrowDown.svg";
import { ReactComponent as SortAscIcon } from "./sortArrowUp.svg";
import { ReactComponent as EmailIcon } from "./email.svg";
import { ReactComponent as KebabMenu } from "./kebabMenu.svg";
import { ReactComponent as InvoiceIcon } from "./invoice.svg";
import { ReactComponent as FreeLabel } from "./freeLabel.svg";
import { ReactComponent as CalendarOutline } from "./calendar-outline.svg";
import { ReactComponent as GrayChevronRight } from "./chevron-right-gray.svg";
import { ReactComponent as FlightBlue } from "./flight_blue.svg";
import { ReactComponent as DirectionCar } from "./directions_car.svg";
import { ReactComponent as AISearchIcon } from "./ai-search.svg";
import { ReactComponent as Hotel } from "./hotel.svg";
import { ReactComponent as Send } from "./send.svg";
import { ReactComponent as TicketOutline } from "./ticket-outline.svg";
import { ReactComponent as Dashboard } from "./dashboard.svg";
import { ReactComponent as HotelTicket } from "./ticket-hotel.svg";
import { ReactComponent as CrossRed } from "./cross-red.svg";
import { ReactComponent as StartLocationMarker } from "./start-location-marker.svg";
import { ReactComponent as InformationRules } from "./information-rules.svg";
import { ReactComponent as UserPlus } from "./user-plus.svg";
import { ReactComponent as SlashTicket } from "./ticket-slash.svg";
import { ReactComponent as noUserAdded } from "./no-user-added.svg";
import { ReactComponent as Meal } from "./meal.svg";
import { ReactComponent as Refund } from "./refund.svg";
import { ReactComponent as Lock } from "./lock.svg";
import { ReactComponent as Confetti } from "./confetti.svg";
import { ReactComponent as CircledExclaimation } from "./circled-exclaimation.svg";
import { ReactComponent as Seat } from "./seat.svg";
import { ReactComponent as Refresh } from "./refresh.svg";
import { ReactComponent as ChevronDownGray } from "./chevron-down-gray.svg";
import { ReactComponent as EditFill } from "./edit-fill.svg";
import { ReactComponent as Approvals } from "./approvals.svg";
import { ReactComponent as Bookings } from "./bookings.svg";
import { ReactComponent as DashboardIcon } from "./dashboardIcon.svg";
import { ReactComponent as Policies } from "./policies.svg";
import { ReactComponent as Billing } from "./billing.svg";
import { ReactComponent as CostCodes } from "./cost-codes.svg";
import { ReactComponent as SignOutIcon } from "./signout.svg";
import { ReactComponent as Notifications } from "./notifications.svg";
import { ReactComponent as Profile } from "./profile.svg";
import { ReactComponent as UserIcon } from "./userIcon.svg";
import { ReactComponent as SearchGreyIcon } from "./search-gray.svg";
import { ReactComponent as HotelBedIcon } from "./hotelBed.svg";
import { ReactComponent as CarIcon } from "./car.svg";
import { ReactComponent as ChevronRightGray } from "./chevronRightGray.svg";
import { ReactComponent as FilterIcon } from "./filterIcon.svg";
import { ReactComponent as SearchIconGray } from "./searchIconGray.svg";
import { ReactComponent as CalenderIconDark } from "./calenderDark.svg";
import { ReactComponent as AddPhoto } from "./add-photo.svg";
import { ReactComponent as CurrencyDollarLine } from "./currency-dollar-line.svg";
import { ReactComponent as DotsVertical } from "./dots-vertical.svg";
import { ReactComponent as IllustrationUpload } from "./illustration-upload.svg";
import { ReactComponent as Pencil } from "./pencil.svg";
import { ReactComponent as PlusGray } from "./plus-gray.svg";
import LogoIcon from "./logo-icon.svg";
import connectBankAccount from "./connectBankAccount.png";
import { ReactComponent as Dollar } from "./dollar.svg";
import { ReactComponent as TicketBasic } from "./ticket-basic.svg";
import { ReactComponent as BaggageOutlined } from "./baggage-outlined.svg";
import { ReactComponent as GreenArrowUp } from "./green-arrow-up.svg";
import { ReactComponent as RedArrowDown } from "./red-arrow-down.svg";
import { ReactComponent as Luggage } from "./luggage.svg";
import { ReactComponent as BedOutlined } from "./bed-outlined.svg";
import { ReactComponent as FlightBlueIcon } from "./flight-blue.svg";
import { ReactComponent as Car } from "./car.svg";
import { ReactComponent as ApprovalRating } from "./approval-rating.svg";
import { ReactComponent as AddImageIcon } from "./add-image.svg";
import { ReactComponent as CheckTeal } from "./check-teal.svg";
import { ReactComponent as Microsoft } from "./microsoft.svg";
import { ReactComponent as HotelBed } from "./hotelBed.svg";
import { ReactComponent as FlightsGray } from "./flightsGray.svg";
import { ReactComponent as PlusIcon } from "./plusIcon.svg";
import { ReactComponent as InfoIcon } from "./infoIcon.svg";
import { ReactComponent as Location } from "./location.svg";
import { ReactComponent as Plane } from "./plane.svg";
import { ReactComponent as SuitCase } from "./suit-case.svg";
import { ReactComponent as HotelImage } from "./hotelImage.svg";
import { ReactComponent as Transactions } from "./transactions.svg";
import { ReactComponent as EmptyLayoutIcon } from "./emptyLayout.svg";
import { ReactComponent as Expense } from "./expense.svg";
import { ReactComponent as Wallet } from "./wallet.svg";
import { ReactComponent as LuggageLarge } from "./luggage-large.svg";
import { ReactComponent as BookingImg } from "./booking-img.svg";
import { ReactComponent as PlaneImg } from "./plane-img.svg";
import { ReactComponent as CarImg } from "./car-img.svg";
import { ReactComponent as ThumbImg } from "./thumb-img.svg";
import { ReactComponent as HotelImg } from "./hotel-img.svg";
import { ReactComponent as InviteTeam } from "./invite-team.svg";
import { ReactComponent as SetUpYourCompany } from "./set-up-your-company.svg";
import { ReactComponent as AccountSettings } from "./accountSettings.svg";
import { ReactComponent as BadgeFlight } from "./badge-flight.svg";
import { ReactComponent as BadgeHotel } from "./badge-hotel.svg";
import { ReactComponent as BadgeCar } from "./badge-car.svg";
import { ReactComponent as LeftChevron } from "./left-chevron.svg";
import { ReactComponent as RightChevron } from "./right-chevron.svg";
import { ReactComponent as BurgerMenuRight } from "./burger-menu-right.svg";
import { ReactComponent as Hamburger } from "./hamburger.svg";
import { ReactComponent as AddFolderIcon } from "./addFolderIcon.svg";
import { ReactComponent as CsvIcon } from "./csvIcon.svg";
import {ReactComponent as Map} from "./map.svg";
import {ReactComponent as WalletIcon} from "./walletIcon.svg";
import {ReactComponent as Payment} from "./payment.svg";
import {ReactComponent as AddressIcon} from "./address-icon.svg";
import {ReactComponent as CurrencyIcon} from './currency-icon.svg';
import {ReactComponent as OrganisationIcon} from "./organisation-icon.svg"
import { ReactComponent as DomainIcon } from "./domain-icon.svg";
import {ReactComponent as FillEmail} from './fillEmail.svg';
import {ReactComponent as CountryIcon} from './country-icon.svg';
import {ReactComponent as ActiveIcon} from './active-icon.svg';
import {ReactComponent as LanguageIcon} from './language-icon.svg';
import {ReactComponent as LicenseIcon} from './license-icon.svg';
import {ReactComponent as WebsiteRedirect} from "./websiteRedirect.svg"
import {ReactComponent as PaidSuccessfully} from "./paidSuccessfully.svg"
import {ReactComponent as ReceiptSolid} from "./receipt-solid.svg"
import {ReactComponent as Flight} from "./flight.svg"
import {ReactComponent as ClockExclamation} from "./clock-exclamation.svg";
import {ReactComponent as ResendInvitation} from "./resendInvite.svg";
import {ReactComponent as bulletPoint} from "../icons/Amenities/bullet_point.svg";
import config from "../../config.json";


const RenderSVG = ({
  Svg,
  alt = "",
  color = "",
  width = "20",
  height = "20",
  className = "",
  fill,
  ...restProps
}) => (
  <Svg
    fill={fill || "currentColor"}
    color={color || config.primaryColor}
    aria-label={alt}
    width={width}
    height={height}
    className={className}
    {...restProps}
  />
);

export {
  Aeroplane,
  Apple,
  ArrowLeft,
  Calendar,
  Check,
  ChevronDownPrimary,
  ChevronDown,
  Circle,
  Close,
  Cross,
  CurrencyDollar,
  ChevronDownDark,
  DirectionCar,
  Facebook,
  GlobeAlt,
  Globe,
  Google,
  GrayChevronRight,
  Home,
  Hotel,
  LocationMarkerLight,
  LocationMarker,
  Minus,
  Mail,
  MailOutline,
  Logout,
  Plus,
  SearchIcon,
  Ticket,
  Users,
  ToggleDirection,
  TripTavaLogo,
  UserCircle,
  Menu,
  TripTavaWithBrand,
  Cloud,
  Lightning,
  CurrencyDollarDark,
  XCircle,
  CheckGreen,
  CheckDark,
  LeftArrow,
  RightArrow,
  LineSeparator,
  DotPattern,
  Checkbox,
  Filter,
  ExclamationTriangle,
  FlightIcon,
  FlightDarkIcon,
  FlightBlue,
  HotelsIcon,
  HotelsDarkIcon,
  CopyToClipboard,
  VacationsIcon,
  VacationDarkIcon,
  TripTavaLogoMin,
  Phone,
  PhoneIcon,
  Information,
  SpinnerIcon,
  Trash,
  MenuVerticalIcon,
  CloseDark,
  ChevronUp,
  FilterResetIcon,
  TriangleExclaimation,
  Gender,
  LeftIcon,
  RightIcon,
  Stripe,
  Razorpay,
  PlusWhite,
  CheckWhite,
  Barcode,
  RewardsPoint,
  ExclamationCircleIcon,
  QuestionIcon,
  CheckCircleIcon,
  ShowPasswordIcon,
  HidePasswordIcon,
  ArrowRoundTrip,
  Bed,
  WhiteChevronDown,
  WhiteChevronUp,
  StarLight,
  Star,
  AISearchIcon,
  Send,
  EditIcon,
  DiscountIcon,
  Baggage,
  FlightCancel,
  RenderSVG,
  SearchDarkIcon,
  Booking,
  Compass,
  History,
  ApiLogs,
  PendingIcon,
  ServiceIcon,
  EarningIcon,
  DownloadIcon,
  LogoutIcon,
  InflationIcon,
  Morning,
  Noon,
  Evening,
  BaggageIcon,
  Wave,
  IndianCoinIcon,
  ArrivalPlaneIcon,
  DeparturePlaneIcon,
  indianFlag,
  TicketSlash,
  bookingIcon,
  SortAscIcon,
  SortDescIcon,
  EmailIcon,
  KebabMenu,
  FreeLabel,
  ThreeDotsLoading,
  InvoiceIcon,
  CalendarOutline,
  TicketOutline,
  Dashboard,
  HotelTicket,
  CrossRed,
  StartLocationMarker,
  InformationRules,
  UserPlus,
  SlashTicket,
  noUserAdded,
  Lock,
  Confetti,
  CircledExclaimation,
  Meal,
  Refund,
  Seat,
  Refresh,
  ChevronDownGray,
  EditFill,
  Approvals,
  Bookings,
  DashboardIcon,
  Policies,
  Billing,
  CostCodes,
  SignOutIcon,
  Notifications,
  Profile,
  UserIcon,
  SearchGreyIcon,
  HotelBedIcon,
  CarIcon,
  HotelBed,
  FlightsGray,
  ChevronRightGray,
  FilterIcon,
  SearchIconGray,
  CalenderIconDark,
  AddPhoto,
  CurrencyDollarLine,
  DotsVertical,
  IllustrationUpload,
  LogoIcon,
  Pencil,
  PlusGray,
  connectBankAccount,
  Dollar,
  TicketBasic,
  BaggageOutlined,
  GreenArrowUp,
  RedArrowDown,
  Luggage,
  BedOutlined,
  FlightBlueIcon,
  Car,
  ApprovalRating,
  AddImageIcon,
  CheckTeal,
  Microsoft,
  PlusIcon,
  InfoIcon,
  Plane,
  Location,
  SuitCase,
  HotelImage,
  Transactions,
  EmptyLayoutIcon,
  Expense,
  Wallet,
  LuggageLarge,
  BookingImg,
  PlaneImg,
  CarImg,
  HotelImg,
  ThumbImg,
  InviteTeam,
  SetUpYourCompany,
  AccountSettings,
  BadgeFlight,
  BadgeHotel,
  BadgeCar,
  LeftChevron,
  RightChevron,
  Hamburger,
  BurgerMenuRight,
  AddFolderIcon,
  CsvIcon,
  Map,
  WalletIcon,
  Payment,
  FillEmail,
  DomainIcon,
  OrganisationIcon,
  AddressIcon,
  LicenseIcon,
  ActiveIcon,
  CurrencyIcon,
  LanguageIcon,
  CountryIcon,
  WebsiteRedirect,
  PaidSuccessfully,
  ReceiptSolid,
  Flight,
  ClockExclamation,
  ResendInvitation,
  bulletPoint,
};
