import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Tooltip } from "react-tooltip";
import Confetti from "react-confetti";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { get, isEmpty } from "lodash";
import classNames from "classnames";
import {
  selectBookCallbackResponse,
  selectSelectedTrip,
  setBookCallbackResponse
} from "../Trips";
import { selectHotelInfo } from "../../HotelInfo";
import PropTypes from "prop-types";
import FlightConfirmation from "./FlightConfirmation";
import HotelConfirmation from "./HotelConfirmation";
import Spinner, { SPINNER_NAMES } from "../../../components/organisms/Spinner";
import {
  FailedBookingHeader,
  PartialBookingHeader,
  SuccessBookingHeader,
} from "../../../components/organisms/FlightBookingInfoSections";
import config from "../../../config.json";
import {
  DEFAULT_VALUES,
  BOOKING_STATUS_CODES,
  BOOK_NEW_TRIP_TABS,
  ROUTES,
  WINDOWS_EVENTS,
} from "../../../constants";
import { useNavigate } from "react-router-dom";
import { selectUserInfo } from "../../Profile";
import TripPriceInfo from "./TripPriceInfo";

const { ZERO, EMPTY_STRING, EMPTY_ARRAY, ONE } = DEFAULT_VALUES;
const { FLIGHT, HOTEL } = BOOK_NEW_TRIP_TABS;
const { BOOK_CALLBACK } = SPINNER_NAMES;
const { CONFIRMED, FAILED, PARTIALLY_CONFIRMED } = BOOKING_STATUS_CODES;
const { brandName } = config;
const { HOME } = ROUTES;
const { POPSTATE } = WINDOWS_EVENTS;

const BookingStatusLoader = ({ t }) => (
  <SkeletonTheme baseColor='lightgray' highlightColor='#14b8a5'>
    <div className='relative w-full z-0 bottom-1'>
      <Skeleton width={"100%"} height={185} duration={2} borderRadius={0} />
      <div className='absolute top-14 left-12 z-10 text-xl text-contrast-900 font-bold'>
        {t("bookingResult.loadingMsg")}
      </div>
    </div>
  </SkeletonTheme>
);

const TripConfirmation = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const bookCallbackResponse = useSelector(selectBookCallbackResponse);
  const selectedTrip = useSelector(selectSelectedTrip);
  const selectedHotelInfo = useSelector(selectHotelInfo);
  const userInfo = useSelector(selectUserInfo);

  const [isSpinnerActive, setIsSpinnerActive] = useState(true);
  const [pnrList, setPnrList] = useState(EMPTY_ARRAY);
  const [tripBookingCategories, setTripBookingCategories] = useState(EMPTY_ARRAY);
  const [hotelFailReason, sethotelFailReason] = useState(EMPTY_STRING);
  const [flightFailReason, setFlightFailReason] = useState(EMPTY_STRING);

  const flightResult = get(bookCallbackResponse, "flightBookingResponse", EMPTY_ARRAY);
  const hotelResult = get(bookCallbackResponse, "hotelBookingResponse", EMPTY_ARRAY);
  const bookingId = get(selectedTrip, "id", EMPTY_STRING);
  const reissuedBookings = get(selectedTrip, "flights", EMPTY_ARRAY);
  const flightsInfo = get(selectedTrip, "flights");
  const hotelsInfo = get(selectedTrip, "hotels");
  const hotelImages = get(selectedHotelInfo, "priceResult.hotelDetails.images");

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener(POPSTATE, onBackButtonEvent);
    return () => {
      window.removeEventListener(POPSTATE, onBackButtonEvent);
      dispatch(setBookCallbackResponse(null));
    }
  }, [])

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    navigate(HOME);
  }

  const getFlightBookingStatus = () => {
    if (isEmpty(flightsInfo)) return "";

    let statusSet = new Set();

    flightsInfo.forEach((flight) => {
      const tenantStatus = get(flight, "reissuedBookings[0].tenantStatus", flight.tenantStatus);
      statusSet.add(tenantStatus);
    });

    if (statusSet.size === ONE) {
      if (statusSet.has(CONFIRMED)) {
        return CONFIRMED;
      } else {
        return FAILED;
      }
    } else if (statusSet.has(CONFIRMED) && statusSet.has(FAILED)) {
      return PARTIALLY_CONFIRMED;
    } else if(statusSet.has(CONFIRMED)) {
      return CONFIRMED;
    } else {
      return FAILED
    }
  };

  const getHotelBookingStatus = () => {
    if (isEmpty(hotelsInfo)) return "";

    const bookingStatus = hotelsInfo[ZERO].tenantBookingStatus || "";

    if (bookingStatus === CONFIRMED) {
      return CONFIRMED;
    } else {
      return FAILED
    }
  };

  const getCollectiveTripStatus = () => {
    if (!selectedTrip) return "";

    if (isEmpty(flightBookingStatus))
      return isEmpty(hotelBookingStatus) ? FAILED : hotelBookingStatus;

    if (isEmpty(hotelBookingStatus))
      return isEmpty(flightBookingStatus) ? FAILED : flightBookingStatus;

    if (flightBookingStatus === CONFIRMED && hotelBookingStatus === CONFIRMED) {
      return CONFIRMED;
    } else if (
      flightBookingStatus === FAILED &&
      hotelBookingStatus === FAILED
    ) {
      return FAILED;
    } else if (
      (flightBookingStatus === CONFIRMED) ||
      (flightBookingStatus === PARTIALLY_CONFIRMED) ||
      (hotelBookingStatus === CONFIRMED)
    ) {
      return PARTIALLY_CONFIRMED;
    }
    return FAILED
  };
  const flightBookingStatus =
    get(bookCallbackResponse, "flightBookingResponse[0].bookingStatus") ||
    getFlightBookingStatus();

  const hotelBookingStatus =
    get(bookCallbackResponse, "hotelBookingResponse[0].bookingStatus") ||
    getHotelBookingStatus();

  const collectiveTripStatus =
    bookCallbackResponse?.tripBookingStatus || getCollectiveTripStatus();

  const barcodeDetails = get(
    bookCallbackResponse,
    "flightBookingResponse[0].barcodeDetails",
    EMPTY_ARRAY
  );

  const bookingFailureReasons = [
    hotelFailReason && { type: 'hotel', reason: hotelFailReason },
    flightFailReason && { type: 'flight', reason: flightFailReason },
  ].filter(Boolean);  

  useEffect(() => {
    isEmpty(pnrList) &&
      bookCallbackResponse &&
      setPnrList(flightResult?.map((each) => each.pnr));
      
    if (!isEmpty(bookCallbackResponse)) {
      hotelResult &&
        sethotelFailReason(
          get(hotelResult[0], "response.error.errorMessage", EMPTY_STRING)
        );

      flightResult &&
        setFlightFailReason(
          get(
            flightResult[0],
            "response.Response.Error.ErrorMessage",
            EMPTY_STRING
          )
        );
    }
  }, [bookCallbackResponse, flightResult, hotelResult]);

  useEffect(() => {
  if (!isEmpty(flightsInfo) && !tripBookingCategories.includes(FLIGHT))
    setTripBookingCategories((prev) => [...new Set([...prev, FLIGHT])])

  if(!isEmpty(hotelsInfo) && !tripBookingCategories.includes(HOTEL))
    setTripBookingCategories((prev) => [...new Set([...prev, HOTEL])]);

    if (isEmpty(bookCallbackResponse)) {
      hotelsInfo &&
        sethotelFailReason(
          get(hotelsInfo[0], "bookingResJson.error.errorMessage", EMPTY_STRING)
        );
      flightsInfo &&
        setFlightFailReason(
          get(
            flightsInfo[0],
            "ticketingJson.ticketingRes.response.error.errorMessage",
            EMPTY_STRING
          )
        );
    }
  }, [flightsInfo, hotelsInfo]);

  return (
    <div>
      <Helmet>
        <title>{brandName} | Trip Confirmation</title>
      </Helmet>
      {isSpinnerActive && (
        <div className='bg-red-700 text-white text-sm w-full px-8 py-2 text-center sticky -top-[0.5px] z-10 rounded-t-lg'>
          {t("bookingResult.warningMsg")}
        </div>
      )}
      <Tooltip
        id='comingSoon-tooltip'
        className='!w-56 !sm:w-72 !z-50 !bg-primary-600 !rounded-lg text-center'
      />
      <Spinner
        name={BOOK_CALLBACK}
        loaderComponent={<BookingStatusLoader t={t} />}
        showSkeleton={true}
        setIsSpinnerActive={setIsSpinnerActive}
      >
        {bookCallbackResponse &&
          (collectiveTripStatus === CONFIRMED ||
            collectiveTripStatus === PARTIALLY_CONFIRMED) && (
            <Confetti
              numberOfPieces={2000}
              width={window.innerWidth * 0.95}
              initialVelocityX={10}
              gravity={0.1}
              recycle={false}
              tweenDuration={4000}
            />
          )}
        {collectiveTripStatus === CONFIRMED && (
          <SuccessBookingHeader
            bookingId={bookingId}
            bookingStatus={collectiveTripStatus}
            t={t}
          />
        )}
        {collectiveTripStatus === PARTIALLY_CONFIRMED && (
          <PartialBookingHeader
            bookingId={bookingId}
            bookingStatus={collectiveTripStatus}
            bookingFailureReasons={bookingFailureReasons}
            t={t}
          />
        )}
        {collectiveTripStatus === FAILED && (
          <FailedBookingHeader
            bookingId={bookingId}
            bookingStatus={collectiveTripStatus}
            bookingFailureReasons={bookingFailureReasons}
            t={t}
          />
        )}
      </Spinner>
      <div className='relative top-[-60px]'>
        <div
          className={classNames("container px-4 mx-auto", {
            "-mt-12": bookCallbackResponse || isSpinnerActive,
          })}
        >
          <div className='p-4'>
            {tripBookingCategories.map((type) => (
              <main className='main flex-1 flex flex-col mb-4'>
                <div className='bg-white border border-contrast-200 rounded-xl'>
                  <div
                    className={classNames(
                      "flex justify-center px-6 py-4 gap-3 items-center cursor-default border-b-2 w-full text-sm border-contrast-300 text-contrast-500-custom"
                    )}
                  >
                    <span className='font-semibold text-current text-sm capitalize'>
                      {type}
                    </span>
                  </div>
                  {type === FLIGHT ? (
                    <FlightConfirmation
                      flightsInfo={flightsInfo}
                      bookingId={bookingId}
                      pnrList={pnrList}
                      flightBookingStatus={flightBookingStatus}
                      isSpinnerActive={isSpinnerActive}
                      barcodeDetails={barcodeDetails}
                    />
                  ) : (
                    <HotelConfirmation
                      hotelsInfo={hotelsInfo}
                      bookingId={bookingId}
                      isSpinnerActive={isSpinnerActive}
                      hotelBookingStatus={hotelBookingStatus}
                      hotelImages={hotelImages}
                    />
                  )}
                </div>
              </main>
            ))}

            <TripPriceInfo
              bookCallbackResponse={bookCallbackResponse}
              selectedTrip={selectedTrip}
              userInfo={userInfo}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

BookingStatusLoader.propTypes = {
  t: PropTypes.func,
};

export default TripConfirmation;
